import React, { useEffect, useState } from "react";
import { DefaultLayout } from "../../layouts/defaultLayout";
import Slider from "react-slick";
import { useDispatch, useSelector } from "react-redux"
import { createCartData, getProducts } from "../../../features/product/action";
import { useLocation, useNavigate, useParams } from "react-router";
import $ from "jquery";
import { useCart } from "react-use-cart";
import { notify } from "../../../features/notification/notify";
import { Link } from "react-router-dom";
import { updateBadge } from "../../../features/product/slice";
import { ImageSlider } from "./imageSlider";
import { updateWishList } from "../../../features/user/action";


export const ProductDetails = () => {
  const [nav1, setNav1] = useState();
  const [nav2, setNav2] = useState();
  const { cat, name } = useParams();
  

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { state } = useLocation();


  const [similarProducts, setSimilarProducts] = useState([]);
  const [recentProducts, setRecentProducts] = useState([]);
  const [productData, setProductData] = useState([]);
  const [addCartButton, setAddCartButton] = useState(true);
  const [updateCartApi, setUpdateCartApi] = useState(false);
  const [colors, setColors] = useState([]);
  const {
    items,
    addItem,
    updateItemQuantity,
    removeItem,
    emptyCart,
  } = useCart();
  

  const [totalLocalItem, setTotalLocalItem] = useState(items);
  const [finalCount, setFinalCount] = useState();

  const productResponse = useSelector((state) => state.product);

  let userId = localStorage.getItem("userId")

  useEffect(() => {
    dispatch(
      getProducts({ page: 1, limit: 4, category: cat })
    );
    state == null && navigate("/designs");
    state && setProductData(state.product);
    if (localStorage.getItem("userId") == null) {
      dispatch(updateBadge(items.length));
    }
    if (localStorage.getItem("recentview") !== null) {
      setRecentProducts(JSON.parse(localStorage.getItem("recentview")));
    }
    // window.scrollTo(0, 0);
  }, [state]);

  useEffect(() => {
    productResponse.products && setSimilarProducts(productResponse.products);
    console.log( "productResponse",productResponse.products);
  }, [productResponse]);

  useEffect(() => {
    console.log("productData", productData);
    if (productData.ProductColours) setColors(productData.ProductColours);
  }, [productData]);

  const handleGoToCart = () => {
    setAddCartButton(true);
    navigate("/bag");
  };

  const handleAddtoCart = () => {
    if(userId == null) {
      notify("Please login first", "warning");
    } else {
      setAddCartButton(false);
      totalLocalItem.map((item) => {
  
        console.log(item, 'items')
        addItem(item, item.quantity);
        // delete item["quantity"];
      });
  
      setUpdateCartApi(true);
      notify("Item added to cart", "success");
    }
    
  };

  useEffect(() => {
    console.log("items", items);
    //if logged in add to cart api call
    if (
      localStorage.getItem("userId") != null &&
      localStorage.getItem("userId") != undefined
    ) {
      updateCartApi &&
        items.map((item) => {
          let cartPayload = {
            productid: item.productid,
            userid: localStorage.getItem("userId"),
            qantity: item.quantity,
            price: item.price,
            size: item.size,
            color: item.color,
            totalPrice: item.price * item.quantity,
          };

          console.log("itemscartdata", cartPayload);
          dispatch(createCartData(cartPayload));
          dispatch(updateBadge(parseInt(productResponse.badge) + parseInt(items.length)));
          emptyCart();
          setUpdateCartApi(false);
        });
    } else {
      dispatch(updateBadge(items.length));
    }
  }, [items]);

  const updateCartData = (inputTarget, size, quantity) => {
    // setAddCartButton(false);
    let availableItem = totalLocalItem.length > 0 ? totalLocalItem.filter(
      (obj) => obj.productid == productData.id && obj.size == size.sizeName
    ) : []
    let randomId = Math.random().toString(36).substr(2, 7);
    let availbleQty = 0;
    if (availableItem.length > 0) {
      randomId = availableItem[0].id;
      availbleQty = availableItem[0].quantity;
    }

   

    let finalItemCount = totalLocalItem.reduce(function(_this, val) {
      return _this + val.quantity 
    }, 0);

    finalItemCount = (finalItemCount + quantity) - availbleQty;

     // item-total-price

     let targetRowprice = parseFloat(quantity * parseFloat(getPrice(finalItemCount)))

     $(inputTarget).parents('.size-row').find(".item-total-price").text('₹' + targetRowprice)

      setFinalCount(finalItemCount)


    console.log("calculative price",finalItemCount, getPrice(finalItemCount))

    let selecteProductData = {
      id: randomId,
      productid: productData.id,
      userid: "",
      size: size.sizeName,
      quantity: quantity,
      price: parseInt(getPrice(quantity)),
    };


    if (quantity == 0) {
      removeItem(randomId);
      removeLocalCartById(randomId);
    } else {
      availableItem.length > 0
        ? updateLocalCartItemQty(randomId, quantity, finalItemCount)
        : addLocalCartItem(selecteProductData);
    }
    // setUpdateCartApi(true);
  };

  useEffect(() => {
  console.log('final-ccc', finalCount)
  updateRowPrice()

  }, [finalCount])
  

  const getPrice = (qty) => {
    if (productData?.ProductPricings.find(x => (x.MinQuantity <= qty &&
      x.MaxQuantity >= qty)) != undefined) {
      return (parseInt(productData?.ProductPricings
        .find(x => (x.MinQuantity <= qty &&
          x.MaxQuantity >= qty)).Price)).toFixed(2)
    } else {
      return (parseInt(productData?.ProductPricings.find(x => x.MaxQuantity === Math.max(...productData.ProductPricings.map(x => x.MaxQuantity))).Price)).toFixed(2)
    }
  }
  useEffect(() => {
    console.log("totalLocalItem", totalLocalItem);

    // console.log("products", products);
  }, [totalLocalItem]);

  const addLocalCartItem = (item) => {
    setTotalLocalItem([...totalLocalItem, item]);
  };

  const updateLocalCartItemQty = (randomId, quantity, finalCount) => {
    let newTotalItem = totalLocalItem;

    Object.entries(newTotalItem).forEach(([key, value]) => {
      if (value.id === randomId) {
        newTotalItem[key].quantity = quantity;
      }
      newTotalItem[key].price = parseInt(getPrice(finalCount));
    });
    setTotalLocalItem([...totalLocalItem]);
  };

  const removeLocalCartById = (id) => {
    let newItem = totalLocalItem.filter((item) => item.id !== id);

    setTotalLocalItem(newItem);
  };

  const handleMinusCount = (e, size) => {
    let prevCount = $(e.target)
      .parent().parent()
      .find(".product-count")
      .attr("data-count");
    let newCount;

    if (prevCount > 0) {
      newCount = parseInt(prevCount) - 1;
      $(e.target).parent().parent().find(".product-count").attr("data-count", newCount);
      $(e.target).parent().parent().find(".product-count").val(newCount);

      updateCartData(e.target, size, newCount);
 
    }
    newCount == 0 && $(e.target).parent().removeClass("plus").addClass("minus");
  };

  const handlePlusCount = (e, size) => {

    console.log($(e.target).parent().parent().find('input').val())
    let prevCount = $(e.target)
      .parent().parent()
      .find(".product-count")
      .attr("data-count");
    let newCount = parseInt(prevCount) + 1;

    $(e.target)
      .parent().parent()
      .find(".minus-count")
      .removeClass("minus")
      .addClass("plus");
    $(e.target).parent().parent().find("input").attr("data-count", newCount);
    $(e.target).parent().parent().find("input").val(newCount);

    updateCartData(e.target, size, newCount);
    console.log(size, newCount);
  };

  const quantityOnChange = (e, size) => {
    let newCount = parseInt($(e.target).val())
    $(e.target).attr('data-count', newCount)

    if (e.target.value == 0) {

      $(e.target)
        .parent()
        .find(".minus-count").removeClass("plus").addClass("minus");



    } else {
      $(e.target)
        .parent()
        .find(".minus-count")
        .removeClass("minus")
        .addClass("plus");

      updateCartData(e.target, size, newCount);
    }

    console.log(newCount)
  }


  const handleProductClick = (product) => {
    navigate('/product-details/' + product.Category.name.toLowerCase() + '/' + product.name.toLowerCase().replaceAll(' ', '_'),
      {
        state: { product: product }
      });
    hanldeRecentLocalStorage(product);
  }

  const hanldeRecentLocalStorage = (productArr) => {
    let updatedRecentProduct = [];
    if (localStorage.getItem('recentview') !== null) {
      let recentProductData = JSON.parse(localStorage.getItem('recentview'))
      recentProductData.length > 3 && recentProductData.splice(-1, 1)
      updatedRecentProduct = recentProductData
    }

    let newData = [...[productArr], ...updatedRecentProduct];
    localStorage.setItem('recentview', JSON.stringify(newData));
  }

  const updateRowPrice = () => {

    const elements = document.querySelectorAll('.item-total-price');
    Array.from(elements).forEach((element, index) => {

        let slectedRowCount = $(element).parent().parent().find(".product-count").val()
        let sizeGroupPrice = parseInt(getPrice(finalCount))
        let rowPrice = ( slectedRowCount *  sizeGroupPrice ).toFixed(2);

        console.log(slectedRowCount,sizeGroupPrice, rowPrice )

        $(element).text('₹' + rowPrice)
    });
  }

  // const calculatePrice = (size) => {

  //   var currentSize = totalLocalItem.find(x => x.size.toLowerCase() == size.sizeName.toLowerCase())
  //   if (currentSize) {
  //     if (productData?.ProductPricings.find(x => (x.MinQuantity <= currentSize.quantity &&
  //       x.MaxQuantity >= currentSize.quantity)) != undefined) {
  //       return (parseInt(productData?.ProductPricings
  //         .find(x => (x.MinQuantity <= currentSize.quantity &&
  //           x.MaxQuantity >= currentSize.quantity)).Price) * currentSize.quantity).toFixed(2)
  //     } else {
  //       return (parseInt(productData?.ProductPricings.find(x => x.MaxQuantity === Math.max(...productData.ProductPricings.map(x => x.MaxQuantity))).Price) * currentSize.quantity).toFixed(2)
  //     }
  //   }
  //   else
  //     return 0
  // }


  const handleUpdateWishlist = (e,  productId) => {

    let addWishlist = $(e.target).hasClass('active') ? false : true

    if($(e.target).hasClass('active')) {
      addWishlist = false;
      $(e.target).removeClass('active')

    }else{

      addWishlist = true;
      $(e.target).addClass('active')
    }


    let payload = {
      "userId": userId,
      "productId" : productId,
      "add" : addWishlist
    }
    dispatch(updateWishList(payload))
  }


  const preventInputHandle = (e) => {
    if (e.code === 'Minus' || e.target.value > 999) {
        e.preventDefault();
    } 
  };
  return (
    <DefaultLayout>
      {productData && (
        <section className="product-detail-wrap pb-5">
          <div className="container">
            <div className="row row-gap-4 align-items-start">
              <div className="col-md-4 d-lg-block d-none">
                <div className="product-dec-wrap">
                  <div className="accordion" id="product-desc">
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingOrigin">
                        <button
                          className="accordion-button"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseOrigin"
                          aria-expanded="true"
                          aria-controls="collapseOrigin"
                        >
                          {productData.leftHeading1}
                        </button>
                      </h2>
                      <div
                        id="collapseOrigin"
                        className="accordion-collapse collapse show"
                        aria-labelledby="headingOrigin"
                        data-bs-parent="#product-desc"
                      >
                        <div className="accordion-body">
                          {productData.leftHeading1Content}
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingShipping">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseShipping"
                          aria-expanded="false"
                          aria-controls="collapseShipping"
                        >
                          {productData.leftHeading2}
                        </button>
                      </h2>
                      <div
                        id="collapseShipping"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingShipping"
                        data-bs-parent="#product-desc"
                      >
                        <div className="accordion-body">
                          {productData.leftHeading2Content}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                {productData.ProductImages != null ? (
                  <div className="row align-items-end">
                    <div className="col-10">
                      <Slider
                        asNavFor={nav2}
                        ref={(slider1) => setNav1(slider1)}
                        slidesToShow={1}
                        slidesToScroll={1}
                        arrows={false}
                        vertical={true}
                        className="product-img"
                      >
                        {productData.ProductImages.map(
                          (img, index) =>
                            img.side != null && (
                              <div key={index}>
                                <img
                                  src={
                                    process.env.REACT_APP_API_URL + '/' + img.filePath
                                  }
                                />
                              </div>
                            )
                        )}
                      </Slider>
                    </div>
                    <div className="col-2">
                      <Slider
                        asNavFor={nav1}
                        ref={(slider2) => setNav2(slider2)}
                        swipeToSlide={true}
                        slidesToShow={productData.ProductImages.length}
                        slidesToScroll={1}
                        vertical={true}
                        dots={false}
                        focusOnSelect={true}
                        centerPadding="0px"
                        className="product-img-nav"
                      >
                        {productData.ProductImages.map(
                          (img, index) =>
                            img.side != null && (
                              <div key={index}>
                                <img
                                  src={
                                    process.env.REACT_APP_API_URL + '/' + img.filePath
                                  }
                                />
                              </div>
                            )
                        )}
                      </Slider>
                    </div>
                  </div>
                ) : null}
              </div>
              <div className="col-md-4">
                <div className="product-detail-info-wrap">
                  <div className="product-detail-info">
                    <div className="product-detail-info-header position-relative">
                      <h1 className="product-detail-info-header-name">
                        {productData.name}
                      </h1>
                      <button className="btn wishlist-btn" onClick={(e) => handleUpdateWishlist(e, productData.id)}>
                        <i className={(productData.WishLists?.length > 0 && productData.WishLists[0].userId == userId) ? 'fa-solid fa-heart active' : 'fa-solid fa-heart'} ></i>
                      </button>
                    </div>
                    <div className="product-detail-info-price">
                      <div className="amount price">
                        ₹
                        {productData.productSizes &&
                          parseInt(
                            productData.ProductPricings[0]
                              ?.Price
                          ).toFixed(2)}
                      </div>
                      <div className="price-messages">
                        MRP incl. of all taxes
                      </div>
                    </div>
                    <p className="product-detail-description">
                      {productData.description}
                    </p>
                    <div className="product-detail-actions">
                      <ul className="product-detail-actions-actions">
                        {/* <li className="product-detail-actions-action">
                          <button className="btn">
                            Check in-store availability
                          </button>
                        </li>
                        <li className="product-detail-actions-action">
                          <button className="btn">
                            SHIPPING, EXCHANGES AND RETURNS
                          </button>
                        </li> */}
                        <li>
                          <h1 className="product-detail-info-header-name mt-3">
                            Select variations and quantity
                          </h1>
                          <div className="row">
                            {productData.ProductPricings &&
                              productData.ProductPricings.map(
                                (price, index) => (
                                  <div className="col-sm-5 mt-2 text-center" key={index}>
                                    <div className="Quantity">
                                      {price.MinQuantity} - {price.MaxQuantity}{" "}
                                      pieces
                                    </div>
                                    <div className="Price" style={{ fontWeight: "bold" }}>
                                      ₹{parseInt(price.Price).toFixed(2)}/piece
                                    </div>
                                  </div>
                                )
                              )}
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="product-data">
                    <h2>Select Size</h2>
                    {productData.productSizes &&
                      productData.productSizes.map((size, index) => (
                        <div className="row pb-1 size-row" key={index}>
                          <div className="col-md-3" key={index}>
                            <span>{size.sizeName}{size.sizeName.indexOf("-") > -1 ? " Y" : ""}</span>
                          </div>
                          <div className="col-md-5">
                            <div className="counter">
                              <a
                                className="minus minus-count"
                                onClick={(e) => handleMinusCount(e, size)}
                              >
                                <i className="fa-solid fa-circle-minus"></i>
                              </a>

                              <input
                                type="number"
                                defaultValue={0}
                                // onChange={handleTitleChange}
                                className="form-control product-count"
                                data-count="0"
                                style={{ padding: '0px 5px' }}
                                onChange={(e) => quantityOnChange(e, size)}
                                min={0}
                                max={9999}
                                onKeyPress={preventInputHandle}
                              />
                              <a
                                className="plus plus-count"
                                onClick={(e) => handlePlusCount(e, size)}
                              >
                                <i className="fa-solid fa-circle-plus"></i>
                              </a>
                            </div>
                          </div>
                          <div className="col-md-2">

                            <span className="item-total-price">
                              ₹0.00
                              {/* { totalLocalItem.length > 0 && productData?.ProductPricings?.length > 0 ? calculatePrice(size) : 0.00} */}
                            </span>
                          </div>
                        </div>
                      ))}
                  </div>
                  <div className="product-detail-buttons">
                    {addCartButton ? (
                      <button className="btn" onClick={handleAddtoCart} disabled={totalLocalItem.length > 0 ? false : true}>
                        Add
                      </button>
                    ) : (
                      <button className="btn" onClick={handleGoToCart}>
                        Go to Cart
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}

      <section className="you-may-like">
        <div className="container-fluid">
          <h2>You May Like</h2>
          <div className="wishlist-row row row-gap-4">
            {similarProducts &&
              similarProducts.slice(0, 4).map((product, index) => (
                <div className="col-sm-6 col-md-4 col-xl-3" key={index}>
                  <div className="product-col">
                    <div className="product-col-inner">
                      <a onClick={() => handleProductClick(product)}>
                        {product.ProductImages.length > 0 && <ImageSlider images={product.ProductImages} />}
                      </a>
                      <div id="dv-overlay" style={{ pointerEvents: 'none' }} className="product-overlay dv-ovrly">
                        <div className="add-wishlist">
                          <a href="#">
                          <i className={(product.WishLists?.length > 0 && product.WishLists[0].userId == userId) ? 'fa-solid fa-heart active' : 'fa-solid fa-heart'}></i>
                          </a>
                        </div>
                        <a onClick={() => handleProductClick(product)}>
                          View
                        </a>
                      </div>
                    </div>
                    <a onClick={() => handleProductClick(product)}>
                      <h3>{product.name}</h3>
                    </a>
                    <span className="price">
                      ₹{parseInt(product.price).toFixed(2)}
                    </span>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </section>
      {/* similar searched  */}
      {recentProducts && (
        <section className="you-may-like">
          <div className="container-fluid">
            <h2>Recently Viewed</h2>
            <div className="wishlist-row row row-gap-4">
              {recentProducts.slice(0, 4).map((product, index) => (
                <div className="col-sm-6 col-md-4 col-xl-3" key={index}>
                  <div className="product-col">
                    <div className="product-col-inner">
                      <a onClick={() => handleProductClick(product)}>
                        {product.ProductImages.length > 0 && <ImageSlider images={product.ProductImages} />}
                      </a>
                      <div id="dv-overlay" style={{ pointerEvents: 'none' }} className="product-overlay dv-ovrly">
                        <div className="add-wishlist">
                          <a href="#">
                            <i className="fa-solid fa-heart"></i>
                          </a>
                        </div>
                        <a onClick={() => handleProductClick(product)}>
                          View
                        </a>
                      </div>
                    </div>
                    <a onClick={() => handleProductClick(product)}>
                      <h3>{product.name}</h3>
                    </a>
                    <span className="price">
                      ₹{parseInt(product.price).toFixed(2)}
                    </span>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
      )}
    </DefaultLayout>
  );
};
