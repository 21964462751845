import React from "react";
import Slider from "react-slick";
import moment from 'moment'
import { Link } from "react-router-dom";


export const Blogslider = (props) => {

  const { blogs } = props

  const settings = {
    infinite: true,
    slidesToShow: blogs && blogs?.length >= 3 ? 3 : blogs?.length,
    centerMode: true,
    centerPadding: "80px",
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          centerMode: false,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          centerMode: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          centerMode: false,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1.5,
          infinite: false,
          centerMode: false,
        },
      },
    ],
  };

 

  return (

        <Slider {...settings} className="latest-slider">
      {blogs &&
        blogs.map((blog, index) => (
          <div className="latest-slider-inner" key={index}>
            <Link to={"/blog/" + blog.id}>
              <img src={process.env.REACT_APP_API_URL + blog.imagePath} alt="Blog" className="w-100" />
            </Link>
            <div className="content">
              <div className="date-author">{moment(blog.createdDate).format('MMMM DD, YYYY')}</div>
              <h3>{blog.title}</h3>
            </div>
          </div>
        ))}
    </Slider>
      
    
  );
};
