import React, { useEffect, useState } from "react";
import { Link, useNavigate, useLocation, useParams, useSearchParams  } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getBrandByName, getProfileData } from "../../features/user/action";
import { resetUser } from "../../features/user/slice";
import $ from "jquery";
import { getCartBadge } from "../../features/product/action";
import { useCart } from "react-use-cart";
import { BASE_URLS } from "../../utils/api.urls";
import { getCategories} from "../../features/product/action";

export const Header = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { hash } = useLocation();
  const [userProfileData, setUserProfileData] = useState([]);
  const [cartBadge, setCartBadge] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const [brandDetails, setBrandDetails] = useState();
  const [categories, setCategories] = useState([]);
  const { name, stype } = useParams();
  const {
    addItem,
    items
  } = useCart();
  let category = name !== null ? name : 'men';
  let season = stype == undefined ? '' : stype
  let page = searchParams.get('page') !== null ? searchParams.get('page') : 1;
  const [filter, setFilter] = useState({ page: page, limit: 10, category: category, sort: 'new', season: season });
  const profileResponse = useSelector((state) => state.user);
  const productResponse = useSelector((state) => state.product);

 

  useEffect(() => {
    setFilter({
      ...filter,
      category: category,
      season : season
    })
    dispatch(getCategories())
  }, [name, stype]);

  useEffect(() => {
    dispatch(getBrandByName
      
      ('groyyo'))
    if (localStorage.getItem("userId") != null && localStorage.getItem("userId") != undefined) {
      dispatch(
        getProfileData({
          userId: localStorage.getItem("userId"),
        })
      );
      dispatch(
        getCartBadge(localStorage.getItem("userId"))
      );
    }
    $(window).scroll(function () {
      var sticky = $('header'),
          scroll = $(window).scrollTop();

      if (scroll >= 50) sticky.addClass('scroll-fixed');
      else sticky.removeClass('scroll-fixed');
  });

    if(hash) {
      console.log(hash)
      setTimeout(() => {
        if(hash == '#contact-us') $('html,body').animate({scrollTop: $(hash).offset().top  },'smooth');
    }, 800)

    
    }

  }, []);

  const handleSectionClick = (e) => {
    e.preventDefault();
    var aid = e.target.getAttribute('href');
    $('html,body').animate({scrollTop: $(aid).offset().top - 55},'smooth');
  }

  const handleLogOut = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("userId");
    localStorage.removeItem("userRoles");
    localStorage.removeItem("userData");
    setTimeout(() => {
      window.location.reload(false);
    }, 500);
    console.log("page to reload");
  };

  useEffect(() => {
    dispatch(getCategories())
    dispatch(resetUser());
    profileResponse.profile && setUserProfileData(profileResponse.profile);

    if(profileResponse.actionName == 'getBrandByName'){
      if(profileResponse.success)
      setBrandDetails(profileResponse.brandDetails)
    };
    
  }, [profileResponse]);

  useEffect(() => {
    productResponse.badge && setCartBadge(productResponse.badge);
    if (productResponse.actionName == 'getCategories')
    productResponse.categories && setCategories(productResponse.categories);
  }, [productResponse]);

  // useEffect(() => {
  //   console.log("userProfileData", userProfileData.userId);
  // }, [userProfileData]);

 


  return (
    <>
      <header>
        <div className="container-fluid">
          <nav className="navbar navbar-expand-md p-0">
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarTogglerDemo01"
              aria-controls="navbarTogglerDemo01"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <Link className="navbar-brand" to="/">
              <img src={BASE_URLS.BASE_URL + '/upload/brand/' + brandDetails?.companyLogo} alt="Logo" />
            </Link>
            <div className="collapse navbar-collapse" id="navbarTogglerDemo01">
              <ul className="navbar-nav">
                <li className="nav-item dropdown">
                <a
                    className="nav-link dropdown-toggle"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Categories
                  </a>
                  <ul className="dropdown-menu">
                  {categories.filter(x => { return x.type == "Category" }) &&
                          categories.filter(x => { return x.type == "Category" }).map((cat, index) => (
                            <li key={index}>
                               <Link className="dropdown-item" to={"/designs/" + cat.name.toLowerCase()}>
                               {cat.name}
                      </Link>
                            </li>))}
                  </ul>
                </li>
                <li className="nav-item dropdown">
                  <a
                    className="nav-link dropdown-toggle"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Blogs
                  </a>
                  <ul className="dropdown-menu">
                    <li>
                      <Link className="dropdown-item" to="/blogs">
                        Blogs
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/events">
                        Events
                      </Link>
                    </li>
                  </ul>                  
                </li>
                {location.pathname === "/" && (
                  <>
                    <li className="nav-item">
                      <a className="nav-link" href="#about-us" id="about" onClick={handleSectionClick}>
                        About
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" href="#contact-us" id="contact" onClick={handleSectionClick}>
                        Contact Us
                      </a>
                    </li>
                  </>
                )}
              </ul>
            </div>
            <div className="right-btn">
              {/* <div className="nav-item">
                <a className="nav-link" href="#">
                  <i className="search"></i>
                </a>
              </div> */}
               {localStorage.getItem("userId") != null && (
              <div className="nav-item">
                <Link className="nav-link" to="/wishlist">
                <i className="fa-solid fa-heart"></i>
                </Link>
              </div>)}
              <div className="nav-item">
                <Link className="nav-link" to="/bag">
                  <i className="bag position-relative"> <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger" style={{fontSize: "9px"}}>{cartBadge}</span></i>
                </Link>
              </div>
              
              <div className="nav-item">
                {localStorage.getItem("userId") != null ? (
                  <div className="nav-item dropdown user-dropdown">
                  <a className="nav-link dropdown-toggle"  role="button" data-bs-toggle="dropdown" aria-expanded="false"><i className="user"></i></a>
                      <ul className="dropdown-menu ">
                        <li><Link className="dropdown-item" to="/account/profile">Account Setting</Link></li>
                        <li><Link className="dropdown-item" to="/account/address">Addresses</Link></li>
                        <li><Link className="dropdown-item" to="/account/orders">Orders and Refund</Link></li>
                        <li><a className="dropdown-item" href="#">Payment Management</a></li>
                        <li><Link className="dropdown-item"  to={{ pathname: "/" }} onClick={handleLogOut}>Sign out</Link></li>
                      </ul>
                  </div>
                ) : (
                  <Link className="nav-link" to="/login">
                    <i className="user"></i>
                  </Link>
                )}
              </div>
            </div>
          </nav>
        </div>
      </header>
    </>
  );
};
