import React from 'react'

export const Loader = () => {

   
  return (
    <div className='full-page-loader'>
        <img src="/images/loader.gif" alt="loader" />
    </div>
  )
}
