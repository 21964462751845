import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import {
  userFacebookLogin,
  userGoogleLogin,
  userLoginValidate,
} from "../../../features/login/action";
import { LoginSocialGoogle, LoginSocialFacebook } from "reactjs-social-login";
import {
  GoogleLoginButton,
  FacebookLoginButton,
} from "react-social-login-buttons";
import { notify } from "../../../features/notification/notify";
import { resetLogin } from "../../../features/login/slice";
import ReactGA from "react-ga4";
export const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [user, setUser] = useState({
    email: "",
    password: "",
    error: "",
  });

  const loginResponse = useSelector((state) => state.userLogin);

  const handleUserLogin = (data) => {
    let newData = {
      email: data.email,
      password: data.password,
    };
    console.log("loginparam", newData);
    dispatch(userLoginValidate(newData));
  };

  const handleChange = (e) => {
    setUser({
      ...user,
      [e.target.name]: e.target.value,
    });
  };

  const UserSchema = Yup.object().shape({
    email: Yup.string()
      .email("Email must be valid")
      .min(2, "Too Short!")
      .required("Required"),
    password: Yup.string().min(2, "Too Short!").required("Required"),
  });
  useEffect(()=>{
    ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search, title: "Signin Page" });
  })
  useEffect(() => {
    console.log("loginResponse", loginResponse);

    if (loginResponse.success === true) {
      dispatch(resetLogin());
      ReactGA.event({
        category: "login",
        action: "login success",
        lable:"login"
      });
      navigate("/", { replace: true });
    }
    if (loginResponse.error) {
      notify(loginResponse.error, "error");
    }
  }, [loginResponse]);

  //social login

  const REDIRECT_URI = "/login";

  const handleGoogleLoginResponse = (provider, data) => {
    let newData = {
      email: data.email,
      given_name: data.given_name,
      family_name: data.family_name,
      provider: provider,
    };
    console.log(data);
    console.log(newData);
    dispatch(userGoogleLogin(newData));
  };

  const handleFacebookLoginResponse = (provider, data) => {
    let newData = {
      email: data.email,
      first_name: data.first_name,
      last_name: data.last_name,
      loginType: provider,
    };
    console.log(data);
    console.log(newData);
    // dispatch(userFacebookLogin(newData));
  };

  return (
    <>
      <main>
        <div className="sign-details">
          <div className="sign-wrap">
            <img src="images/logo.png" alt="Logo" className="logo" />
            <h2>Welcome</h2>
            <Formik
              initialValues={user}
              validationSchema={UserSchema}
              enableReinitialize={true}
              onSubmit={(values) => {
                console.log("viewvalues", values);
                handleUserLogin(values);
              }}
            >
              {({ errors, touched, isValid, handleBlur }) => (
                <>
                  <Form>
                    <div className="mb-3 position-relative">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Email"
                        value={user.email}
                        name="email"
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {errors.email && touched.email ? (
                        <div className="invalid-feedback d-block">
                          {errors.email}
                        </div>
                      ) : null}
                    </div>
                    <div className="mb-3 position-relative">
                      <input
                        type="password"
                        className="form-control"
                        value={user.password}
                        name="password"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder="Password"
                      />
                      {errors.password && touched.password ? (
                        <div className="invalid-feedback d-block">
                          {errors.password}
                        </div>
                      ) : null}
                    </div>
                    {loginResponse.error ? (
                      <div className="invalid-feedback d-block">
                        {loginResponse.error}
                      </div>
                    ) : null}
                    {user.error ? (
                      <div className="invalid-feedback d-block">
                        {user.error}
                      </div>
                    ) : null}
                    <div className="member-links">
                      <Link to="/forgot-password">Forgot Password ?</Link>
                      <span href="#">
                        Don’t have account? {" "}
                          <Link to="/signup"><b>Signup</b> </Link>
                      </span>
                    </div>
                    <button type="submit" disabled={!isValid} className="btn">
                      Login
                    </button>
                  </Form>
                </>
              )}
            </Formik>
          </div>
        </div>
      </main>
    </>
  );
};
