import { createSlice } from "@reduxjs/toolkit";
import { updateUser, getProfileData, getProfileAddress , addUpdateAddress, getAddressById, setDefaultAddress, deleteAddress, contactUs , getWishList, updateWishList, getUserOrders, getBrandByName, updateOrderStatus} from "./action";


export const user = createSlice({
  name: "user",
  initialState: {
    loading: false,
    error: null,
    success: false,
    actionName: null,
    profile: null,
    addressData : null,
    address : null,
    wishlist : null,
    brandDetails: null
  },

  reducers: {
    resetUser: (state, action) => {
      state.success = false
      state.error = null
      state.actionName = null
    },
  },

  extraReducers: {
    [updateUser.pending]: (state) => {
      state.loading = true;
      state.success = false;
    },
    [updateUser.fulfilled]: (state, action) => {
      state.loading = false;
      state.actionName = "updateUser"
      state.success = true;
    },
    [updateUser.rejected]: (state, action) => {
      console.log("error", action)
      state.loading = false;
      state.error = "Internal server error";
    },
    [updateWishList.pending]: (state) => {
      state.loading = true;
      state.success = false;
    },
    [updateWishList.fulfilled]: (state, action) => {
      state.loading = false;
      state.actionName = "updateWishList"
      state.success = true;
    },
    [updateWishList.rejected]: (state, action) => {
      console.log("error", action)
      state.loading = false;
      state.error = "Internal server error";
    },
    [getProfileData.pending]: (state) => {
      state.loading = true;
      state.success = false;
    },
    [getProfileData.fulfilled]: (state, action) => {
      state.loading = false;
      state.actionName = "getProfileData"
      state.profile = action.payload.userData;
      state.success = true;
    },
    [getProfileData.rejected]: (state, action) => {
      console.log("error", action)
      state.loading = false;
      state.error = "Internal server error";
    },
    [getProfileAddress.pending]: (state) => {
      state.loading = true;
      state.success = false;
    },
    [getProfileAddress.fulfilled]: (state, action) => {
      state.loading = false;
      state.actionName = "getProfileAddress"
      state.address = action.payload.address;
      state.success = true;
    },
    [getProfileAddress.rejected]: (state, action) => {
      console.log("error", action)
      state.loading = false;
      state.error = "Internal server error";
    },
    [addUpdateAddress.pending]: (state) => {
      state.loading = true;
      state.success = false;
    },
    [addUpdateAddress.fulfilled]: (state, action) => {
      state.loading = false;
      state.actionName = "addUpdateAddress"
      state.success = true;
    },
    [addUpdateAddress.rejected]: (state, action) => {
      console.log("error", action)
      state.loading = false;
      state.error = "Internal server error";
      state.actionName = "addUpdateAddress"
      state.success = false
    },
    [setDefaultAddress.pending]: (state) => {
      state.loading = true;
      state.success = false;
    },
    [setDefaultAddress.fulfilled]: (state, action) => {
      state.loading = false;
      state.actionName = "setDefaultAddress"
      state.success = true;
    },
    [setDefaultAddress.rejected]: (state, action) => {
      console.log("error", action)
      state.loading = false;
      state.error = "Internal server error";
    },
    [getAddressById.pending]: (state) => {
      state.loading = true;
      state.success = false;
    },
    [getAddressById.fulfilled]: (state, action) => {
      state.loading = false;
      state.actionName = "getAddressById"
      state.addressData = action.payload.address;
      state.success = true;
    },
    [getAddressById.rejected]: (state, action) => {
      console.log("error", action)
      state.loading = false;
      state.error = "Internal server error";
    },
    [getWishList.pending]: (state) => {
      state.loading = true;
      state.success = false;
    },
    [getWishList.fulfilled]: (state, action) => {
      state.loading = false;
      state.actionName = "getWishList"
      state.wishlist = action.payload.wishlist;
      state.success = true;
    },
    [getWishList.rejected]: (state, action) => {
      console.log("error", action)
      state.loading = false;
      state.error = "Internal server error";
    },
    [getUserOrders.pending]: (state) => {
      state.loading = true;
      state.success = false;
    },
    [getUserOrders.fulfilled]: (state, action) => {
      state.loading = false;
      state.actionName = "getUserOrders"
      state.orders = action.payload.orders;
      state.success = true;
    },
    [getUserOrders.rejected]: (state, action) => {
      console.log("error", action)
      state.loading = false;
      state.error = "Internal server error";
    },
    [deleteAddress.pending]: (state) => {
      state.loading = true;
      state.success = false;
    },
    [deleteAddress.fulfilled]: (state, action) => {
      state.loading = false;
      state.actionName = "deleteAddress"
      state.success = true;
    },
    [deleteAddress.rejected]: (state, action) => {
      console.log("error", action)
      state.loading = false;
      state.error = "Internal server error";
    },
    [contactUs.pending]: (state) => {
      state.loading = true;
      state.success = false;
    },
    [contactUs.fulfilled]: (state, action) => {
      state.loading = false;
      state.actionName = "contactUs"
      state.success = true;
    },
    [contactUs.rejected]: (state, action) => {
      console.log("error", action)
      state.loading = false;
      state.error = "Internal server error";
    },
    [getBrandByName.pending]: (state) => {
      state.loading = true;
      state.success = false;
    },
    [getBrandByName.fulfilled]: (state, action) => {
      state.loading = false;
      state.actionName = "getBrandByName"
      state.brandDetails = action.payload.brand
      state.success = true;
    },
    [getBrandByName.rejected]: (state, action) => {
      console.log("error", action)
      state.loading = false;
      state.error = "Internal server error";
    },
    [updateOrderStatus.pending]: (state) => {
      state.loading = true;
      state.success = false;
    },
    [updateOrderStatus.fulfilled]: (state, action) => {
      state.loading = false;
      state.actionName = "updateOrderStatus"
      state.success = true;
    },
    [updateOrderStatus.rejected]: (state, action) => {
      console.log("error", action)
      state.loading = false;
      state.error = "Internal server error";
    },
  },
});

export default user.reducer;
export const { resetUser } = user.actions;


