import React, { useCallback, useEffect, useState } from "react";
import { AccountLayout } from "../accountLayout";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getProfileData, updateUser } from "../../../../features/user/action";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { notify } from "../../../../features/notification/notify";

export const AddEditProfile = () => {
  const dispatch = useDispatch();
  const { state } = useLocation();
  const profileResponse = useSelector((state) => state.user);
  const [file, setFile] = useState();
  const navigate = useNavigate();
  const [profile, setProfile] = useState({
    userId: "",
    firstName: "",
    lastName: "",
    emailId: "",
    mobileNo: "",
    fileName: "",
    file: null,
    userId: null
  });
  const userId = localStorage.getItem("userId");
  console.log(userId);
  useEffect(() => {
    dispatch(getProfileData({ userId: userId }));
    setProfile(profileResponse.profile);
  }, []);

  useEffect(() => {
    if (profileResponse.actionName == 'updateUser') {
      if (profileResponse.success){
        notify('Info updated!', 'success')
        navigate('/account/profile')
      }
      else
        notify('An error occured', 'danger')
    }
  }, [profileResponse]);

  const handleChange = (e) => {
    setProfile({
      ...profile,
      [e.target.name]: e.target.value,
    });
  };

  const handleFormSubmit = (props) => {
    const userId = localStorage.getItem("userId");
    
    dispatch(updateUser({
      data : props,
      file: (file != undefined && file != null) ? file : null,
      userId: userId
    }));
  };

  const handleImageChange = (e) => {
    if (e.target.files.length) {
      const inputFile = e.target.files[0];
      setFile(inputFile);
      setProfile({
        ...profile,
        fileName: inputFile.name,
      });
    }
  };

  const profileSchema = Yup.object().shape({
    firstName: Yup.string().required("Required").max(100),
    lastName: Yup.string().required("Required").max(100),
    mobileNo: Yup.string().required("Required").max(10).min(10),
    emailId: Yup.string()
      .email("Email must be valid")
      .min(2, "Too Short!")
      .required("Required"),
  });
  console.log("profile=", profile);
  return (
    <AccountLayout>
      {profile && (
        <div className="right-column">
          <div className="edit-outer p-4">
            <div className="edit-inner-left">
              <h3>Edit Details</h3>
              <Formik
                initialValues={profile}
                validationSchema={profileSchema}
                enableReinitialize={true}
                onSubmit={(values) => {
                  console.log("viewvalues", values);
                  handleFormSubmit(values);
                }}
              >
                {({ errors, touched, handleBlur }) => (
                  <>
                    <Form>
                      <div className="row">
                        <div className="row row-gap-3">
                          <div className="col-md-6 position-relative">
                            <label className="form-label">First Name</label>
                            <input
                              type="text"
                              name="firstName"
                              value={profile.firstName ?? ""}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              className="form-control"
                            />
                            {errors.firstName && touched.firstName ? (
                              <div className="invalid-feedback d-block">
                                {errors.firstName}
                              </div>
                            ) : null}
                          </div>
                          <div className="col-md-6 position-relative">
                            <label className="form-label">Last Name</label>
                            <input
                              type="text"
                              name="lastName"
                              value={profile.lastName ?? ""}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              className="form-control"
                            />
                            {errors.lastName && touched.lastName ? (
                              <div className="invalid-feedback d-block">
                                {errors.lastName}
                              </div>
                            ) : null}
                          </div>
                          <div className="col-md-6 position-relative">
                            <label className="form-label">Email</label>
                            <input
                              type="email"
                              name="emailId"
                              value={profile.emailId ?? ""}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              className="form-control"
                            />
                            {errors.emailId && touched.emailId ? (
                              <div className="invalid-feedback d-block">
                                {errors.emailId}
                              </div>
                            ) : null}
                          </div>
                          <div className="col-md-6 position-relative">
                            <label className="form-label">Contact Number</label>
                            <input
                              type="text"
                              name="mobileNo"
                              value={profile.mobileNo ?? ""}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              className="form-control"
                            />
                            {errors.mobileNo && touched.mobileNo ? (
                              <div className="invalid-feedback d-block">
                                {errors.mobileNo}
                              </div>
                            ) : null}
                          </div>
                          <div className="col-md-6 position-relative">
                            <label className="form-label">Gender</label>
                            <select
                              name="gender"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              className="form-control"
                            >
                              <option value="" label="Gender">
                                Select a gender{" "}
                              </option>
                              <option value="Male" label="Male">
                                Male
                              </option>
                              <option value="Female" label="Female">
                                Female
                              </option>
                              <option value="Other" label="Other">
                                Other
                              </option>
                            </select>
                            {errors.gender && touched.gender ? (
                              <div className="invalid-feedback d-block">
                                {errors.gender}
                              </div>
                            ) : null}
                          </div>
                          <div className="col-md-6 position-relative">
                            <label className="form-label">Profile Photo</label>
                            <div>
                              <input
                                type="file"
                                name="fileName"
                                // value={profile.fileName ?? ""}
                                onChange={handleImageChange}
                                onBlur={handleBlur}
                                className="form-control"
                              />
                              {errors.fileName && touched.fileName ? (
                                <div className="invalid-feedback d-block">
                                  {errors.fileName}
                                </div>
                              ) : null}
                            </div>
                          </div>
                        </div>
                        <div className="text-start py-5">
                          <button type="submit" className="btn orange-btn">
                            Save
                          </button>
                        </div>
                      </div>
                    </Form>
                  </>
                )}
              </Formik>
            </div>
          </div>
        </div>
      )}
    </AccountLayout>
  );
};
