import { createSlice } from "@reduxjs/toolkit";
import { addUpdateEvent , getEvents, getEventById,getAllEvents, updateEventStatus, deleteEvent} from "./action";

export const eventAdmin = createSlice({
    name: "eventadmin",
    initialState: {
        loading: false,
        error: null,
        success: false,
        actionName: null,
        message: null,
        events : null,
        event : null,
        addCategory:null,
        editCategory:null,
        allEvents: null
    },

    reducers: {
        resetEventAdmin: (state, action) => {
            state.success = false
            state.error = null
        },
        resetCategoryAdmin: (state, action) => {
            state.success = false
            state.error = null
        },
    },

    extraReducers: {
        [addUpdateEvent.pending]: (state) => {
            state.loading = true;
            state.success = false;
        },
        [addUpdateEvent.fulfilled]: (state, action) => {
            state.loading = false;
            state.message = action.payload.message;
            state.actionName = "addUpdateEvent"
            state.success = true;
        },
        [addUpdateEvent.rejected]: (state, action) => {
            console.log("error", action)
            state.loading = false;
            state.success = false;
            state.error = "Internal server error";
        },
        [getEvents.pending]: (state) => {
            state.loading = true;
            state.success = false;
        },
        [getEvents.fulfilled]: (state, action) => {
            state.loading = false;
            state.events = action.payload.events;
            state.actionName = "getBlogs"
            state.success = true;
        },
        [getEvents.rejected]: (state, action) => {
            console.log("error", action)
            state.loading = false;
            state.success = false;
            state.error = "Internal server error";
        },
        [getAllEvents.pending]: (state) => {
            state.loading = true;
            state.success = false;
        },
        [getAllEvents.fulfilled]: (state, action) => {
            state.loading = false;
            state.allEvents = action.payload.events;
            state.actionName = "getAllBlogs"
            state.success = true;
        },
        [getAllEvents.rejected]: (state, action) => {
            console.log("error", action)
            state.loading = false;
            state.success = false;
            state.error = "Internal server error";
        },
        [getEventById.pending]: (state) => {
            state.loading = true;
            state.success = false;
        },
        [getEventById.fulfilled]: (state, action) => {
            state.loading = false;
            state.event = action.payload.event;
            state.actionName = "getEventById"
            state.success = true;
        },
        [getEventById.rejected]: (state, action) => {
            console.log("error", action)
            state.loading = false;
            state.success = false;
            state.error = "Internal server error";
        },
        [deleteEvent.pending]: (state) => {
            state.loading = true;
            state.success = false;
        },
        [deleteEvent.fulfilled]: (state, action) => {
            state.loading = false;
            state.actionName = "deleteEvent"
            state.success = true;
        },
        [deleteEvent.rejected]: (state, action) => {
            console.log("error", action)
            state.loading = false;
            state.success = false;
            state.error = "Internal server error";
        },
        [updateEventStatus.pending]: (state) => {
            state.loading = true;
            state.success = false;
        },
        [updateEventStatus.fulfilled]: (state, action) => {
            state.loading = false;
            state.actionName = "updateEventStatus"
            state.success = true;
        },
        [updateEventStatus.rejected]: (state, action) => {
            console.log("error", action)
            state.loading = false;
            state.success = false;
            state.error = "Internal server error";
        }
    },
});

export default eventAdmin.reducer;
export const { resetEventAdmin,resetCategoryAdmin } = eventAdmin.actions;


