import { createSlice } from "@reduxjs/toolkit";
import { createForgotPassword, checkResetPasswordToken, updatePassword, changePassword } from "./action";


export const forgotPassword = createSlice({
  name: "forgotpassword",
  initialState: {
    loading: false,
    error: null,
    success: false,
    actionName: null,
    message: null,
  },

  reducers: {
    resetForgotPassword: (state, action) => {
      state.success = false
      state.error = null
      state.message = ''
    },
  },

  extraReducers: {
    [createForgotPassword.pending]: (state) => {
      state.loading = true;
      state.success = false;
    },
    [createForgotPassword.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action.payload.message;
      state.actionName = "forgotPassword"
      state.success = true;
    },
    [createForgotPassword.rejected]: (state, action) => {
      console.log("error", action)
      state.loading = false;
      state.success = false;
      state.error = action.payload.message;
    },
    [checkResetPasswordToken.pending]: (state) => {
      state.loading = true;
      state.success = false;
    },
    [checkResetPasswordToken.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action.payload.status;
      state.actionName = "checkResetPasswordToken"
      state.success = true;
    },
    [checkResetPasswordToken.rejected]: (state, action) => {
      console.log("error", action)
      state.loading = false;
      state.success = false;
      state.error = action.payload.message;
    },
    [updatePassword.pending]: (state) => {
      state.loading = true;
      state.success = false;
    },
    [updatePassword.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action.payload.message;
      state.actionName = "updatePassword"
      state.success = true;
    },
    [updatePassword.rejected]: (state, action) => {
      console.log("error", action)
      state.loading = false;
      state.success = false;
      state.error = action.payload.message;
      
    },
    [changePassword.pending]: (state) => {
      state.loading = true;
      state.success = false;
    },
    [changePassword.fulfilled]: (state, action) => {
      state.loading = false;
      state.actionName = "changePassword"
      state.success = true;
      state.message = action.payload.message;
    },
    [changePassword.rejected]: (state, action) => {
      console.log("error", action)
      state.loading = false;
      state.success = false;
      state.error = "Internal server error";
    },

  },
});

export default forgotPassword.reducer;
export const { resetForgotPassword } = forgotPassword.actions;


