import React, { useEffect, useState } from "react";
import { DefaultLayout } from "../../layouts/defaultLayout";
import { useCart } from "react-use-cart";
import { useNavigate } from "react-router";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "react-bootstrap";
import {
  getCartBadge,
  removeProductFromCart,
  saveForLater,
  updateCartQuantity,
  processOrder,
  processPayment,
} from "../../../features/product/action";
import {
  getAddressById,
  addUpdateAddress,
} from "../../../features/user/action";
import { resetUser } from "../../../features/user/slice";
import {
  getProfileAddress,
  setDefaultAddress,
} from "../../../features/user/action";
import $ from "jquery";
import { confirmAlert } from 'react-confirm-alert';
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { City, Country, State } from "country-state-city";
import { notify } from "../../../features/notification/notify";
import ReactGA from "react-ga4";
export const Bag = () => {
  const { items } = useCart();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { state } = useLocation();
  const [cartData, setCartData] = useState([]);
  const [savedCartData, setSavedCartData] = useState([]);
  const [displaySavedCartData , setDisplaySavedCartData] = useState(0);
  const [toggleSavedCartData , setToggleSavedCartData] = useState(0);
  const [cartFinalPrice, setCartFinalPrice] = useState();
  const userId = localStorage.getItem("userId");
  const userData = localStorage.getItem("userData") !== undefined ? JSON.parse(localStorage.getItem("userData")) : {};
  const countries = Country.getAllCountries();
  const [states, setStates] = useState();
  const [addresses, setAddresses] = useState([]);
  const [checkoutAddress, setCheckoutAddress] = useState();
  const [addressButtonName, setAddressButtonName] = useState("ADD ADDRESS")
  const addressResponse = useSelector((state) => state.user);
  const cartResponse = useSelector((state) => state.product);
  const [addressForPayment, setAddressForPayment] = useState(false);
  const [showAddressModal, setShowAddressModal] = useState(false)
  const [addressList, setAddressList] = useState(false)
  const [address, setAddress] = useState({
    addressId: "",
    addressName: "",
    streetAddress1: "",
    streetAddress2: "",
    phone: "",
    city: "",
    state: "",
    country: "India",
    zip: "",
    landmark: "",
    addressType: "",
    userId: "",
  });

  useEffect(() => {
    if (
      localStorage.getItem("userId") != null &&
      localStorage.getItem("userId") != undefined
    ) {
      dispatch(getCartBadge(userId));
      dispatch(getProfileAddress({ userId: userId }));
      getStates('IN');
    }
  }, []);
  useEffect(() => {
    if (addressForPayment) {
      handlePlaceOrder();
      setAddressForPayment(false);
    }
  }, [checkoutAddress]);

  useEffect(() => {
    dispatch(resetUser());

    if (addressResponse.address && addressResponse.address.length > 0) {
      addressResponse.address && setAddresses(addressResponse.address);
      addressResponse.address && setCheckoutAddress(addressResponse.address[0]);
      setAddressButtonName("CHANGE ADDRESS");      
    }
    if (addressResponse.actionName == "addUpdateAddress") {
      if (addressResponse.success) {
        notify("Address Added successfully!", "success");
        setShowAddressModal(false);
        setAddress({
          addressId: "",
          addressName: "",
          streetAddress1: "",
          streetAddress2: "",
          city: "",
          state: "",
          phone: "",
          country: "India",
          zip: "",
          landmark: "",
          addressType: "",
          userId: "",
        })
        dispatch(getProfileAddress({ userId: userId }));
      } else notify(addressResponse.error, "error");
      dispatch(resetUser());
    }
    if (addressResponse.actionName == "setDefaultAddress") {
      dispatch(getProfileAddress({ userId: userId }));
    }
  }, [addressResponse]);


  useEffect(() => {
    cartResponse.cart && setCartFinalPrice(cartResponse.cart.totalPrice);
    if (cartResponse.cart?.data && cartResponse?.cart?.data?.length > 0) {
      setCartData(cartResponse.cart?.data);
    } 
    else {
      setCartData([]);
    }
  }, [cartResponse.cart]);

  useEffect(()=>{
    if (cartResponse.cart?.savedItems && cartResponse?.cart?.savedItems.length > 0) {
      setSavedCartData(cartResponse.cart?.savedItems);
    } 
    else {
      setSavedCartData([]);
    }
  }, [cartResponse.cart?.savedItems]);

  const handleMinusCount = (e, size, productId) => {
    let prevCount = $(e.target).parent().parent().find(".product-count").val();
    let newCount;

    if (prevCount > 0) {
      newCount = parseInt(prevCount) - 1;
      $(e.target).parent().parent().find(".product-count").val(newCount);

      updateCheckoutCart(newCount, size, productId);
    }
    newCount == 0 && $(e.target).removeClass("plus").addClass("minus");
  };

  const handlePlusCount = (e, size, productId) => {
    let prevCount = $(e.target).parent().parent().find(".product-count").val();
    let newCount = parseInt(prevCount) + 1;

    $(e.target)
      .parent()
      .parent()
      .find(".minus-count")
      .removeClass("minus")
      .addClass("plus");
    $(e.target).parent().parent().find(".product-count").val(newCount);
    updateCheckoutCart(newCount, size, productId);
    console.log(newCount, productId);
  };

  const quantityOnChange = (e, size, productId) => {
    let newCount = parseInt($(e.target).val());
    $(e.target).attr("data-count", newCount);

    if (e.target.value == 0) {
      $(e.target)
        .parent()
        .find(".minus-count")
        .removeClass("plus")
        .addClass("minus");
    } else {
      $(e.target)
        .parent()
        .find(".minus-count")
        .removeClass("minus")
        .addClass("plus");

      updateCheckoutCart(newCount, size, productId);
    }

    console.log(newCount);
  };

  const updateCheckoutCart = (quantity, size, productId) => {
    let cartPayload = {
      quantity: quantity,
      size: size,
      productId: productId,
      userId: userId,
    };

    dispatch(updateCartQuantity(cartPayload));
  };


  const handlePlaceOrder = () => {
    if (cartData.length > 0) {
      if (checkoutAddress != undefined && checkoutAddress?.id != null) {
        let processPayload = {
          userId: parseInt(userId),
          paymentRefId: "",
          coupon: "",
          subTotal: parseInt(cartFinalPrice),
          tax: 0,
          discount: 0,
          shipmentCost: 0,
          totalAmount: parseInt(cartFinalPrice),
          addressId: checkoutAddress.id
        };
        dispatch(processOrder(processPayload));
      }
      else {
        setAddressForPayment(true)
        setShowAddressModal(true)
      }
    }
  };

  const handleRemoveProductFromCart = (productId) => {
    confirmAlert({
      title: 'Confirm to remove',
      message: 'Are you sure to do remove this item from cart?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            let payload = {
              userId: userId,
              productId: productId,
            };
            dispatch(removeProductFromCart(payload));
          }
        },
        {
          label: 'No'
        }
      ]
    })
  };

  const handleAddressDefault = (addr) => {
    let payload = {
      userId: userId,
      addressId: addr.id,
    };
    setCheckoutAddress(addr)
    dispatch(setDefaultAddress(payload));
    dispatch(getProfileAddress({ userId: userId }));
  };

  const handleSaveForLater = (productId, status) => {
    let payload = {
      userId: userId,
      productId: productId,
      status: status
    };

    dispatch(saveForLater(payload));
  };

  useEffect(() => {
    if (
      cartResponse.success === true &&
      cartResponse.actionName == "updateCartQuantity"
    ) {
      dispatch(getCartBadge(userId));
    }
    if (
      cartResponse.success === true &&
      cartResponse.actionName == "removeProductFromCart"
    ) {
      dispatch(getCartBadge(userId));
    }
    if (
      cartResponse.success === true &&
      cartResponse.actionName == "saveForLater"
    ) {
      dispatch(getCartBadge(userId));
    }
    if (
      cartResponse.success === true &&
      cartResponse.actionName == "processOrder"
    ) {
      //start payment

      let paymentPayload = {
        orderId: cartResponse.orderNumber,
        totalAmount: parseInt(cartFinalPrice),
        billingName: checkoutAddress.addressName,
        billingAddress: checkoutAddress.streetAddress1 + ' ' + checkoutAddress.streetAddress2,
        billingCity: checkoutAddress.city == '' ? 'Dehradun' : checkoutAddress.city,
        billingState: checkoutAddress.state == '' ? 'UK' : checkoutAddress.state,
        billingZip: checkoutAddress.zip,
        billingCountry: 'India',
        billingMobileNo: userData.mobileNo == '' ? '985698547' : userData.mobileNo,
        billingEmail: userData.emailId == '' ? 'usergroyyo@gmail.com' : userData.emailId
      }
      ReactGA.event({
        category: "order",
        action: "order process",
        lable:"order"
      });
      dispatch(processPayment(paymentPayload))


    }
    if (

      cartResponse.success === true &&
      cartResponse.actionName == "processPayment"
    ) {
      cartResponse.payment && navigate("/payment", { state: { payment: cartResponse.payment } })

    }
  }, [cartResponse]);

  const handleProductClick = (product) => {
    navigate('/product-details/' + product.category + '/' + product.name.toLowerCase().replaceAll(' ', '_'),
      {
        state: { product: product }
      });
  }


  /*add adress functioning */

  const handleChange = (e) => {
    setAddress({
      ...address,
      [e.target.name]: e.target.value,
    });
  };

  const handleFormSubmit = (data) => {
    const userId = localStorage.getItem("userId");
    data.userId = userId;
    if (state) {
      const addId = state.address.id;
      data.addressId = addId;
    }
    dispatch(addUpdateAddress({ data }));
  };
  const addressSchema = Yup.object().shape({
    addressName: Yup.string().required("Required").max(100),
    streetAddress1: Yup.string().required("Required").max(100),
    streetAddress2: Yup.string().required("Required").max(100),
    city: Yup.string().required("Required"),
    state: Yup.string().required("Required"),
    zip: Yup.string().required("Required").min(6).max(6),
    landmark: Yup.string().required("Required").max(100),
  });

  const handleCountryChange = (e) => {
    let code = $(e.target).find(":selected").attr("data-code");
    getStates(code);
    // setCountryCode(code)
    setAddress({
      ...address,
      country: e.target.value,
    });
  };

  const handleStateChange = (e) => {
    let stateCode = $(e.target).find(":selected").attr("data-code");
    // getCities(countryCode, stateCode);
    setAddress({
      ...address,
      state: e.target.value,
    });
  };
  const getStates = (countryCode) => {
    setStates(State.getStatesOfCountry(countryCode));
  };
  const displaySaveLaterData=()=>{
   if(toggleSavedCartData == 0){
     setDisplaySavedCartData(1);
     setToggleSavedCartData(1);
   }
   else {
     setDisplaySavedCartData(0);
     setToggleSavedCartData(0);
   } 
   dispatch(getCartBadge(userId));
  }

  const handleAddToCart = ()=>{
    //dispatch(addToCart(cartData));
  }

  return (
    <DefaultLayout>
      <section className="cart-section">
        <div className="container">
          <div className="cart-row">
            <div className="cart-left-col">
              <div className="address-strip">
                {addresses.length > 0 && (
                  <div className="address-strip-title">
                    <div className="address-strip-addressName">
                      Deliver to:{" "}
                      <span className="address-strip-highlight">
                        {addresses[0].addressName}, {addresses[0].zip}
                      </span>
                    </div>
                    <div className="address-strip-subText">
                      {" "}
                      {addresses[0].streetAddress1},{" "}
                      {addresses[0].streetAddress2}
                    </div>
                    <div className="address-strip-subText">
                      {" "}
                      {addresses[0].city}, {addresses[0].state}
                    </div>
                  </div>
                )}
                {addressButtonName == "CHANGE ADDRESS" ?
                  <button
                    className="btn change-add"
                    href="#select-address"
                    data-bs-toggle="modal"
                  >
                    {addressButtonName}
                  </button> :
                  <button id="btnAddAddress"
                    className="btn change-add"
                    onClick={(e) => setShowAddressModal(true)}
                  >
                    {addressButtonName}
                  </button>}
              </div>

              {cartData ? (
                <>
                  <div>
                    {cartData.map((itemCart, index) => (
                      <div className="cart-items-list" key={index}>
                        <div className="wishlist-column">
                          <div className="item-image-div">
                            <img
                              src={
                                process.env.REACT_APP_API_URL +
                                "/" +
                                (itemCart.product.ProductImages.filter(
                                  (image) => image.side === "Front"
                                ).length > 0
                                  ? itemCart.product.ProductImages.filter(
                                    (image) => image.side === "Front"
                                  )[0].filePath
                                  : itemCart.product.ProductImages[0].filePath)
                              }
                              className="w-100"
                              alt="wishlist"
                              onClick={() =>
                                handleProductClick(itemCart.product)
                              }
                            />
                          </div>
                          <div className="item-actions">
                            <div className="item-details">
                              <p
                                className="item-details-label"
                                onClick={() =>
                                  handleProductClick(itemCart.product)
                                }
                              >
                                {itemCart.product.name}
                              </p>
                              {itemCart.product.description &&
                                <p
                                  className="item-detail-sub-label"
                                  title={itemCart.product.description}
                                >
                                  {itemCart.product.description.substring(0, 50)}
                                  ...
                                </p>
                              }
                              <p className="item-detail-sold">
                                <b>Style:</b> {itemCart.product.StyleName}
                              </p>

                              <div className="bulk-action">
                                <table className="table">
                                  <thead>
                                    <tr>
                                      <th>Size</th>
                                      <th>Quantity</th>
                                      <th>Price</th>
                                      <th>Total</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {itemCart.sizes.map((itemSize, index) => (
                                      <tr key={index}>
                                        <td>
                                          <label>{itemSize.size}</label>
                                        </td>
                                        <td>
                                          <div className="counter">
                                            <a
                                              className="minus-count pt-1"
                                              onClick={(e) =>
                                                handleMinusCount(
                                                  e,
                                                  itemSize.size,
                                                  itemCart.productId
                                                )
                                              }
                                            >
                                              <i className="fa-solid fa-circle-minus"></i>
                                            </a>


                                            <input
                                              type="number"
                                              defaultValue={itemSize.quantity}
                                              className="form-control product-count"
                                              data-count={itemSize.quantity}
                                              style={{
                                                padding: "0px 5px",
                                                width: "60px",
                                              }}
                                              onChange={(e) =>
                                                quantityOnChange(
                                                  e,
                                                  itemSize.size,
                                                  itemCart.productId
                                                )
                                              }
                                              min={0}
                                            />
                                            <a
                                              className="plus plus-count pt-1"
                                              onClick={(e) =>
                                                handlePlusCount(
                                                  e,
                                                  itemSize.size,
                                                  itemCart.productId
                                                )
                                              }
                                            >
                                              <i className="fa-solid fa-circle-plus"></i>
                                            </a>
                                          </div>
                                        </td>
                                        <td>
                                          <span className="price">
                                            ₹{" "}
                                            {parseInt(itemSize.price).toFixed(
                                              2
                                            )}
                                          </span>
                                        </td>
                                        <td>
                                          <span className="total">
                                            ₹{" "}
                                            {parseInt(
                                              itemSize.price * itemSize.quantity
                                            ).toFixed(2)}
                                          </span>
                                        </td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="bulk-action-button">
                          <button
                            className="btn ps-0"
                            onClick={() =>
                              handleRemoveProductFromCart(itemCart.productId)
                            }
                          >
                            REMOVE
                          </button>
                          <button
                            className="btn pe-0"
                            onClick={() =>
                              handleSaveForLater(itemCart.productId, "Saved")
                            }
                          >
                            SAVE FOR LATER
                          </button>
                        </div>
                      </div>
                    ))}

                    <a style={{cursor: "pointer"}} onClick={displaySaveLaterData}>
                      <div className="add-from-wishlist">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="12"
                          height="16"
                          viewBox="0 0 12 16"
                        >
                          <path
                            fill="#000"
                            fillRule="evenodd"
                            d="M10.993 14.62a.067.067 0 0 1-.103.058l-4.571-2.77a.638.638 0 0 0-.64 0l-4.57 2.77a.067.067 0 0 1-.102-.058V1.133A.13.13 0 0 1 1.139 1H3.5V3.5c0 .298.18.543.486.543s.515-.245.515-.543V1h6.36a.13.13 0 0 1 .133.133V14.62zM11.307 0H.693A.687.687 0 0 0 0 .68v14.719A.61.61 0 0 0 .617 16a.63.63 0 0 0 .315-.086l4.996-3.026a.14.14 0 0 1 .144 0l4.996 3.026a.628.628 0 0 0 .315.086.61.61 0 0 0 .617-.602V.679C12 .306 11.69 0 11.307 0z"
                          ></path>
                        </svg>
                        Saved For Later
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="7"
                          height="12"
                          viewBox="0 0 7 12"
                          className="addToWishlist-base-wishlistChevron"
                        >
                          <path
                            fillRule="evenodd"
                            d="M6.797 5.529a.824.824 0 0 0-.042-.036L1.19.193a.724.724 0 0 0-.986 0 .643.643 0 0 0 0 .94L5.316 6 .203 10.868a.643.643 0 0 0 0 .938.724.724 0 0 0 .986 0l5.566-5.299a.644.644 0 0 0 .041-.978"
                          ></path>
                        </svg>
                      </div>
                    </a>
                  </div>

                </>
              ) : (
                <p>Cart Empty</p>
              )}
            {savedCartData ? 
               (
                 <>
                 { displaySavedCartData !=0 ?

                   ( <>
                    <div>
                   {savedCartData.map((itemCart, index) => (
                      <div className="cart-items-list" key={index}>
                        <div className="wishlist-column">
                          <div className="item-image-div">
                            <img
                              src={
                                process.env.REACT_APP_API_URL +
                                "/" +
                                (itemCart.product.ProductImages.filter(
                                  (image) => image.side === "Front"
                                ).length > 0
                                  ? itemCart.product.ProductImages.filter(
                                    (image) => image.side === "Front"
                                  )[0].filePath
                                  : itemCart.product.ProductImages[0].filePath)
                              }
                              className="w-100"
                              alt="wishlist"
                              onClick={() =>
                                handleProductClick(itemCart.product)
                              }
                            />
                          </div>
                          <div className="item-actions">
                            <div className="item-details">
                              <p
                                className="item-details-label"
                                onClick={() =>
                                  handleProductClick(itemCart.product)
                                }
                              >
                                {itemCart.product.name}
                              </p>
                              {itemCart.product.description &&
                                <p
                                  className="item-detail-sub-label"
                                  title={itemCart.product.description}
                                >
                                  {itemCart.product.description.substring(0, 50)}
                                  ...
                                </p>
                              }
                              <p className="item-detail-sold">
                                <b>Style:</b> {itemCart.product.StyleName}
                              </p>

                              <div className="bulk-action">
                                <table className="table">
                                  <thead>
                                    <tr>
                                      <th>Size</th>
                                      <th>Quantity</th>
                                      <th>Price</th>
                                      <th>Total</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {itemCart.sizes.map((itemSize, index) => (
                                      <tr key={index}>
                                        <td>
                                          <label>{itemSize.size}</label>
                                        </td>
                                        <td>
                                          <div className="counter">
                                            <span>{itemSize.quantity}</span>                                            
                                          </div>
                                        </td>
                                        <td>
                                          <span className="price">
                                            ₹{" "}
                                            {parseInt(itemSize.price).toFixed(
                                              2
                                            )}
                                          </span>
                                        </td>
                                        <td>
                                          <span className="total">
                                            ₹{" "}
                                            {parseInt(
                                              itemSize.price * itemSize.quantity
                                            ).toFixed(2)}
                                          </span>
                                        </td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="bulk-action-button">
                          <button
                            className="btn ps-0"
                            onClick={() =>
                              handleRemoveProductFromCart(itemCart.productId)
                            }
                          >
                            REMOVE
                          </button>
                          <button
                            className="btn pe-0"
                            onClick={() =>
                              handleSaveForLater(itemCart.productId, "Cart")
                            }
                          >
                            Add to cart
                          </button>
                        </div>
                      </div>
                    ))}
                     </div></> ) : (<p></p>) } </> )
                 :
               
               (<p>No Saved items</p>)
            } 
            </div>
            <div className="cart-right-col">
              <div className="inner">
                <div className="subtotal">
                  <div>
                    <span>Subtotal</span>
                    <span className="ms-auto align-items-center">
                      ₹ {parseInt(cartFinalPrice).toFixed(2)}
                    </span>
                  </div>
                  {/* <div>
                    <span>Tax</span>
                    <span className="ms-auto align-items-center">₹ 0.00</span>
                  </div> */}
                  <div>
                    <span>Shipping Charges</span>
                    <span className="ms-auto align-items-center">Free</span>
                  </div>
                </div>
                <div className="total">
                  <span>Total Amount</span>
                  <span className="ms-auto align-items-center">
                    ₹ {parseInt(cartFinalPrice).toFixed(2)}
                  </span>
                </div>
              </div>
              <div className="btn o-btn w-100 p-0 mb-30" onClick={handlePlaceOrder}>
                PLace Order
              </div>
            </div>
          </div>
        </div>
      </section>

      <div
        className="modal fade"
        id="select-address"
        tabIndex="-1"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header">
              <h2 className="modal-title">Change Delivery Address</h2>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              {addresses.length > 0 &&
                addresses.map((address, index) => (
                  <div className="form-check" key={index}>
                    <input
                      className="form-check-input"
                      type="radio"
                      name="change-address"
                      id={"addr-" + index}
                      defaultChecked={address.flag === true ? "checked" : ""}
                      onChange={() => handleAddressDefault(address)}
                    />
                    <label
                      className="form-check-label"
                      htmlFor={"addr-" + index}
                    >
                      <span className="name">{address.addressName}</span>
                      <div>
                        {address.landmark}, {address.streetAddress1},{" "}
                        {address.streetAddress2}
                      </div>
                      <span>
                        {" "}
                        {address.city}, {address.state} -{" "}
                      </span>{" "}
                      <span>{address.zip}</span>
                    </label>
                  </div>
                ))}
            </div>
            <div className="modal-footer">
              <button
                className="btn change-add"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={(e) => setShowAddressModal(true)}
              >
                Add New Address
              </button>
            </div>
          </div>
        </div>
      </div>

      <Modal show={showAddressModal} className="custom-modal pin-modal modal-lg">
        <Modal.Body>
          <div className=" custom-width" >
            <div className="modal-content" style={{ height: " 672px" }}>
              <div className="modal-header">
                <h2 className="modal-title">Add Delivery Address</h2>
                <button
                  type="button"
                  className="btn-close"
                  onClick={(e) => setShowAddressModal(false)}
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <Formik
                  initialValues={address}
                  validationSchema={addressSchema}
                  enableReinitialize={true}
                  onSubmit={(values) => {
                    console.log("viewvalues", values);
                    handleFormSubmit(values);
                  }}
                >
                  {({ errors, touched, handleBlur }) => (
                    <>
                      <Form>
                        <div className="row">
                          <div className="row row-gap-3">
                            <div className="col-md-6 position-relative">
                              <label className="form-label">Name</label>
                              <input
                                type="text"
                                name="addressName"
                                value={address.addressName ?? ""}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                className="form-control"
                              />
                              {errors.addressName && touched.addressName ? (
                                <div className="invalid-feedback d-block">
                                  {errors.addressName}
                                </div>
                              ) : null}
                            </div>
                            <div className="col-md-6 position-relative">
                          <label className="form-label">Phone</label>
                          <input
                            type="text"
                            name="phone"
                            value={address.phone ?? ""}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className="form-control"
                          />
                          {errors.phone && touched.phone ? (
                            <div className="invalid-feedback d-block">
                              {errors.phone}
                            </div>
                          ) : null}
                        </div>
                            <div className="col-md-6 position-relative">
                              <label className="form-label">Address1</label>
                              <input
                                type="text"
                                name="streetAddress1"
                                value={address.streetAddress1 ?? ""}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                className="form-control"
                              />
                              {errors.streetAddress1 && touched.streetAddress1 ? (
                                <div className="invalid-feedback d-block">
                                  {errors.streetAddress1}
                                </div>
                              ) : null}
                            </div>
                            <div className="col-md-6 position-relative">
                              <label className="form-label">Address2</label>
                              <input
                                type="text"
                                name="streetAddress2"
                                value={address.streetAddress2 ?? ""}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                className="form-control"
                              />
                              {errors.streetAddress2 && touched.streetAddress2 ? (
                                <div className="invalid-feedback d-block">
                                  {errors.streetAddress2}
                                </div>
                              ) : null}
                            </div>
                            <div className="col-md-6 position-relative">
                              <label className="form-label">Country</label>
                              <select
                                className="form-select"
                                name="country"
                                value={address.country}
                                onChange={handleCountryChange}
                              >
                                <option value="">Select Country</option>
                                {countries &&
                                  countries.map((country) => (
                                    <option
                                      data-code={country.isoCode}
                                      value={country.name}
                                    >
                                      {country.name}
                                    </option>
                                  ))}
                              </select>

                            </div>
                            <div className="col-md-6 position-relative">
                              <label className="form-label">State</label>
                              <select className="form-select"
                                name="state"
                                value={address.state}
                                onChange={handleStateChange}
                              >
                                <option value="">Select State</option>
                                {states &&
                                  states.map((state) => (
                                    <option
                                      data-code={state.isoCode}
                                      value={state.name}
                                    >
                                      {state.name}
                                    </option>
                                  ))}
                              </select>
                              {errors.state && touched.state ? (
                                <div className="invalid-feedback d-block">
                                  {errors.state}
                                </div>
                              ) : null}
                            </div>
                            <div className="col-md-6 position-relative">
                              <label className="form-label">City</label>
                              <input
                                type="text"
                                name="city"
                                value={address.city ?? ""}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                className="form-control"
                              />
                              {errors.city && touched.city ? (
                                <div className="invalid-feedback d-block">
                                  {errors.city}
                                </div>
                              ) : null}
                            </div>
                            <div className="col-md-6 position-relative">
                              <label className="form-label">Zip</label>
                              <input
                                type="text"
                                name="zip"
                                value={address.zip ?? ""}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                className="form-control"
                              />
                              {errors.zip && touched.zip ? (
                                <div className="invalid-feedback d-block">
                                  {errors.zip}
                                </div>
                              ) : null}
                            </div>
                            <div className="col-md-6 position-relative">
                              <label className="form-label">Landmark</label>
                              <input
                                type="text"
                                name="landmark"
                                value={address.landmark ?? ""}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                className="form-control"
                              />
                              {errors.landmark && touched.landmark ? (
                                <div className="invalid-feedback d-block">
                                  {errors.landmark}
                                </div>
                              ) : null}
                            </div>
                            <div className="col-md-6 position-relative mt-1">
                              <label className="form-label">Save Address As</label>
                              <div className="save-address-as">
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="address-as"
                                    id="one"
                                    checked
                                  />
                                  <label className="form-check-label" htmlFor="one">
                                    Home
                                  </label>
                                </div>
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="address-as"
                                    id="two"
                                  />
                                  <label className="form-check-label" htmlFor="two">
                                    Work
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="text-start py-5">
                          <button type="submit" className="btn orange-btn">
                            Save
                          </button>{" "}
                        </div>
                      </Form>
                    </>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <ToastContainer />
    </DefaultLayout>
  );
};
