import { createSlice } from "@reduxjs/toolkit";
import {  getAllQueries, updateQuery, sendReplyMail} from "./action";


export const customerQuery = createSlice({
    name: "customerQuery",
    initialState: {
        loading: false,
        error: null,
        success: false,
        actionName: null,
        message: null,
        queryList: null,
        data:null,
    },

    reducers: {
        resetCustomerQuery: (state, action) => {
            state.success = false
            state.error = null
            state.actionName = null
        },
    },

    extraReducers: {
        [getAllQueries.pending]: (state) => {
            state.loading = true;
            state.success = false;
        },
        [getAllQueries.fulfilled]: (state, action) => {
            state.loading = false;
            state.actionName = "getAllQueries"
            state.queryList = action.payload.allQueries
            state.success = true;
        },
        [getAllQueries.rejected]: (state, action) => {
            state.loading = false;
            state.success = false;
            state.error = "Internal server error";
        },
        [updateQuery.pending]: (state) => {
            state.loading = true;
            state.success = false;
        },
        [updateQuery.fulfilled]: (state, action) => {
            state.loading = false;
            state.actionName = "updateQuery";
            state.success = true;
        },
        [sendReplyMail.rejected]: (state, action) => {
            state.loading = false;
            state.success = false;
            state.error = "Internal server error";
        },
        [sendReplyMail.pending]: (state) => {
            state.loading = true;
            state.success = false;
        },
        [sendReplyMail.fulfilled]: (state, action) => {
            state.loading = false;
            state.actionName = "sendReplyMail";
            state.success = true;
        },
        [sendReplyMail.rejected]: (state, action) => {
            state.loading = false;
            state.success = false;
            state.error = "Internal server error";
        },
    },
});

export default customerQuery.reducer;
export const { resetCustomerQuery } = customerQuery.actions;


