import * as React from "react";
import UserList from "./userList";
export const User = () => {
  return (
    <>
      <h1>User Management</h1>
      <UserList />
    </>
  );
};

export default User;
