import { createSlice } from "@reduxjs/toolkit";
import { addUpdateBlog , getBlogs,getCategoryById , getBlogById,addUpdateCategory, getAllBlogs, updateBlogStatus, deleteBlog} from "./action";

export const blogAdmin = createSlice({
    name: "blogadmin",
    initialState: {
        loading: false,
        error: null,
        success: false,
        actionName: null,
        message: null,
        blogs : null,
        blog : null,
        addCategory:null,
        editCategory:null,
        allBlogs: null
    },

    reducers: {
        resetBlogAdmin: (state, action) => {
            state.success = false
            state.error = null
        },
        resetCategoryAdmin: (state, action) => {
            state.success = false
            state.error = null
        },
    },

    extraReducers: {
        [addUpdateBlog.pending]: (state) => {
            state.loading = true;
            state.success = false;
        },
        [addUpdateBlog.fulfilled]: (state, action) => {
            state.loading = false;
            state.message = action.payload.message;
            state.actionName = "addUpdateBlog"
            state.success = true;
        },
        [addUpdateBlog.rejected]: (state, action) => {
            console.log("error", action)
            state.loading = false;
            state.success = false;
            state.error = "Internal server error";
        },
        [addUpdateCategory.pending]: (state) => {
            state.loading = true;
            state.success = false;
        },
        [addUpdateCategory.fulfilled]: (state, action) => {
            state.loading = false;
            state.actionName = "addUpdateCategory"
            state.addCategory = action.payload
            state.success = true;
        },
        [addUpdateCategory.rejected]: (state, action) => {
            console.log("error", action)
            state.loading = false;
            state.success = false;
            state.error = "Internal server error";
        },
        [getBlogs.pending]: (state) => {
            state.loading = true;
            state.success = false;
        },
        [getBlogs.fulfilled]: (state, action) => {
            state.loading = false;
            state.blogs = action.payload.blogs;
            state.actionName = "getBlogs"
            state.success = true;
        },
        [getBlogs.rejected]: (state, action) => {
            console.log("error", action)
            state.loading = false;
            state.success = false;
            state.error = "Internal server error";
        },
        [getAllBlogs.pending]: (state) => {
            state.loading = true;
            state.success = false;
        },
        [getAllBlogs.fulfilled]: (state, action) => {
            state.loading = false;
            state.allBlogs = action.payload.blogs;
            state.actionName = "getAllBlogs"
            state.success = true;
        },
        [getAllBlogs.rejected]: (state, action) => {
            console.log("error", action)
            state.loading = false;
            state.success = false;
            state.error = "Internal server error";
        },
        [getBlogById.pending]: (state) => {
            state.loading = true;
            state.success = false;
        },
        [getBlogById.fulfilled]: (state, action) => {
            state.loading = false;
            state.blog = action.payload.blog;
            state.actionName = "getBlogById"
            state.success = true;
        },
        [getBlogById.rejected]: (state, action) => {
            console.log("error", action)
            state.loading = false;
            state.success = false;
            state.error = "Internal server error";
        },
        [deleteBlog.pending]: (state) => {
            state.loading = true;
            state.success = false;
        },
        [deleteBlog.fulfilled]: (state, action) => {
            state.loading = false;
            state.actionName = "deleteBlog"
            state.success = true;
        },
        [deleteBlog.rejected]: (state, action) => {
            console.log("error", action)
            state.loading = false;
            state.success = false;
            state.error = "Internal server error";
        },
        [updateBlogStatus.pending]: (state) => {
            state.loading = true;
            state.success = false;
        },
        [updateBlogStatus.fulfilled]: (state, action) => {
            state.loading = false;
            state.actionName = "updateBlogStatus"
            state.success = true;
        },
        [updateBlogStatus.rejected]: (state, action) => {
            console.log("error", action)
            state.loading = false;
            state.success = false;
            state.error = "Internal server error";
        },

        [getCategoryById.pending]: (state) => {
            state.loading = true;
            state.success = false;
        },
        [getCategoryById.fulfilled]: (state, action) => {
            state.loading = false;
            state.editCategory = action.payload;
            state.actionName = "getCategoryById"
            state.success = true;
        },
        [getCategoryById.rejected]: (state, action) => {
            console.log("error", action)
            state.loading = false;
            state.success = false;
            state.error = "Internal server error";
        },


    },
});

export default blogAdmin.reducer;
export const { resetBlogAdmin,resetCategoryAdmin } = blogAdmin.actions;


