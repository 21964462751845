import { createSlice } from "@reduxjs/toolkit";
import { addUpdateBrand, getAllBrands } from "./action";


export const branding = createSlice({
    name: "branding",
    initialState: {
        loading: false,
        error: null,
        success: false,
        actionName: null,
        message: null,
        brandList: null
    },

    reducers: {
        resetBranding: (state, action) => {
            state.success = false
            state.error = null
            state.actionName = null
            state.message = null
        },
    },

    extraReducers: {
        [getAllBrands.pending]: (state) => {
            state.loading = true;
            state.success = false;
        },
        [getAllBrands.fulfilled]: (state, action) => {
            state.loading = false;
            state.actionName = "getAllBrands"
            state.brandList = action.payload.brands
            state.success = true;
        },
        [getAllBrands.rejected]: (state, action) => {
            state.loading = false;
            state.success = false;
            state.error = "Internal server error";
        },
        [addUpdateBrand.pending]: (state) => {
            state.loading = true;
            state.success = false;
        },
        [addUpdateBrand.fulfilled]: (state, action) => {
            state.loading = false;
            state.actionName = "addUpdateBrand";
            state.message = action.payload.message
            state.success = true;
        },
        [addUpdateBrand.rejected]: (state, action) => {
            state.loading = false;
            state.success = false;
            state.error = "Internal server error";
        },
    },
});

export default branding.reducer;
export const { resetBranding } = branding.actions;


