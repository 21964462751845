import React, { useEffect, useState } from "react";
import videoUrl2 from "../../../src/assets/videos/second.mp4";
import { DefaultLayout } from "../layouts/defaultLayout";
import { Link } from "react-router-dom";
import { Blogslider } from "./blogs/blogslider";
import { useDispatch, useSelector } from "react-redux";
import { contactUs, getBrandByName } from "../../features/user/action";
import { resetUser } from "../../features/user/slice";
import { notify } from "../../features/notification/notify";
import { getBlogs } from "../../features/accountAdmin/blog/action";
import { getEvents } from "../../features/accountAdmin/event/action";
import { BASE_URLS } from "../../utils/api.urls";
import { Loader } from "../layouts/loader";
import { Eventslider } from "./events/eventslider";
import ReactGA from "react-ga4";
export const Home = () => {
    const [contactForm, setContactForm] = useState({
        name: "",
        email: "",
        phone: "",
        comments: "",
    });
    const response = useSelector((state) => state.user);
    const dispatch = useDispatch();
    const [infoSaved, setInfoSaved] = useState(false);
    const [blogList, setBlogList] = useState([]);
    const [eventList, setEventList] = useState([]);
    const userResponse = useSelector((state) => state.user);
    const blogResponse = useSelector((state) => state.blogAdmin);
    const eventResponse = useSelector((state) => state.eventAdmin);
    const [brandDetails, setBrandDetails] = useState();
    const [loader, setLoader] = useState(true);

    let blogFilter = {
        page: 1,
        limit: 10,
    };
    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search, title: "Home Page" });
    })

    useEffect(() => {
        dispatch(getBlogs(blogFilter));
        dispatch(getEvents(blogFilter));
        dispatch(getBrandByName("groyyo")); // this brand name parameter must be changed later
    }, []);

    useEffect(() => {
        if (userResponse.actionName == "getBrandByName") {
            if (userResponse.success) setBrandDetails(userResponse.brandDetails);
            setLoader(false);
        }
    }, [userResponse]);

    useEffect(() => {
        blogResponse.blogs?.rows && setBlogList(blogResponse?.blogs?.rows);
    }, [blogResponse]);
    useEffect(() => {
        eventResponse.events?.rows && setEventList(eventResponse?.events?.rows);
    }, [eventResponse]);

    const isValidEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
    const handleChange = (e) => {
        if (e.target.name == "phone") {
            if (e.target.value.trim().length > 10) return;

            setContactForm((contactForm) => ({
                ...contactForm,
                phone: e.target.value.replace(/\D/g, ""),
            }));
        } else {
            setContactForm((contactForm) => ({
                ...contactForm,
                [e.target.name]: e.target.value,
            }));
        }
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        if (!contactForm.email.match(isValidEmail))
            notify("Please enter valid email addres!", "danger");
        else dispatch(contactUs(contactForm));
    };

    useEffect(() => {
        if (response.actionName == "contactUs") {
            if (response.success) setInfoSaved(true);

            dispatch(resetUser());
        }
    }, [response]);
    const videoUrl =
        brandDetails != null
            ? BASE_URLS.BASE_URL + "/upload/brand/" + brandDetails?.bannerVideo
            : "";
    return (
        <>
            <DefaultLayout>
                {loader ?
                    <div className="vh-100">
                        <Loader />
                    </div> :
                    <>
                        <section className="home-banner">
                            <div className="banner-video">
                                <video preload="auto" loop autoPlay muted playsInline>
                                    <source
                                        src="https://design.groyyo.com/api/upload/brand/BannerVideo.mp4"
                                        type="video/mp4"
                                    />
                                </video>
                                {/*{videoUrl != "" && (*/}
                                {/*    <video preload="auto" loop autoPlay muted playsInline>*/}
                                {/*        <source*/}
                                {/*            src={videoUrl}*/}
                                {/*            type="video/mp4"*/}
                                {/*            media="(max-device-pixel-ratio:1), (-webkit-max-device-pixel-ratio:1), (max--moz-device-pixel-ratio:1), (-o-max-device-pixel-ratio:1)"*/}
                                {/*        />*/}
                                {/*    </video>*/}
                                {/*)}*/}
                            </div>
                            <div className="banner-content">
                                <Link to="/designs/all">View Categories</Link>
                            </div>
                        </section>

                        <section className="section category-section">
                            <div className="container">
                                <h2>{brandDetails?.seasonText}</h2>
                                <div className="category-wrap">
                                    <ul>
                                        <li>
                                            <Link to="/designs/men">Men</Link>
                                        </li>
                                        <li>
                                            <Link to="/designs/women">Women</Link>
                                        </li>
                                        <li>
                                            <Link to="/designs/kids">Kids</Link>
                                        </li>
                                        <li>
                                            <Link to="/designs/home">Home</Link>
                                        </li>
                                        {/* <li>
                  <Link to="/designs">Accessories</Link>
                </li> */}
                                    </ul>

                                    <div className="category-card row m-0">
                                        <div className="col-sm-4 p-0">
                                            <div className="season-category">
                                                <Link
                                                    to={
                                                        "/designs/all/" +
                                                        brandDetails?.firstImageText.toLowerCase()
                                                    }
                                                >
                                                    <img
                                                        src={
                                                            BASE_URLS.BASE_URL +
                                                            "/upload/brand/" +
                                                            brandDetails?.firstImage
                                                        }
                                                        alt="Festive"
                                                    />
                                                    <span>{brandDetails?.firstImageText}</span>
                                                </Link>
                                            </div>
                                        </div>
                                        <div className="col-sm-4 p-0">
                                            <div className="season-category">
                                                <Link
                                                    to={
                                                        "/designs/all/" +
                                                        brandDetails?.secondImageText.toLowerCase()
                                                    }
                                                >
                                                    <img
                                                        src={
                                                            BASE_URLS.BASE_URL +
                                                            "/upload/brand/" +
                                                            brandDetails?.secondImage
                                                        }
                                                        alt="Party"
                                                    />
                                                    <span>{brandDetails?.secondImageText}</span>
                                                </Link>
                                            </div>
                                        </div>
                                        <div className="col-sm-4 p-0">
                                            <div className="season-category">
                                                <Link
                                                    to={
                                                        "/designs/all/" +
                                                        brandDetails?.thirdImageText.toLowerCase()
                                                    }
                                                >
                                                    <img
                                                        src={
                                                            BASE_URLS.BASE_URL +
                                                            "/upload/brand/" +
                                                            brandDetails?.thirdImage
                                                        }
                                                        alt="Winter"
                                                    />
                                                    <span>{brandDetails?.thirdImageText}</span>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="text-center mt-4">
                                    <Link to="/designs" className="orange-btn">
                                        <span className="align-middle">shop Now</span>
                                    </Link>
                                </div>
                            </div>
                        </section>

                        <section className="section latest-section">
                            <h2>Blogs</h2>

                            <ul className="nav nav-underline" id="myTab" role="tablist">
                                <li className="nav-item" role="presentation">
                                    <button
                                        className="nav-link active"
                                        id="blogs-tab"
                                        data-bs-toggle="tab"
                                        data-bs-target="#blogs-tab-pane"
                                        type="button"
                                        role="tab"
                                        aria-controls="blogs-tab-pane"
                                        aria-selected="true"
                                    >
                                        blogs
                                    </button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button
                                        className="nav-link"
                                        id="events-tab"
                                        data-bs-toggle="tab"
                                        data-bs-target="#events-tab-pane"
                                        type="button"
                                        role="tab"
                                        aria-controls="events-tab-pane"
                                        aria-selected="false"
                                    >
                                        events
                                    </button>
                                </li>
                            </ul>
                            <div className="tab-content" id="myTabContent">
                                <div
                                    className="tab-pane fade show active"
                                    id="blogs-tab-pane"
                                    role="tabpanel"
                                    aria-labelledby="blogs-tab"
                                    tabIndex="0"
                                >
                                    {blogList.length > 0 && <Blogslider blogs={blogList} />}
                                </div>
                                <div
                                    className="tab-pane fade"
                                    id="events-tab-pane"
                                    role="tabpanel"
                                    aria-labelledby="events-tab"
                                    tabIndex="0"
                                >
                                    {eventList.length > 0 && <Eventslider events={eventList} />}
                                </div>
                            </div>
                        </section>

                        {/* about us  */}

                        <section id="about-us">
                            <section className="section team">
                                <div className="container">
                                    <h2>{brandDetails?.aboutHeading}</h2>
                                    <p className="top-para">{brandDetails?.aboutText}</p>

                                    <div className="row justify-content-center mt-5 row-gap-4">
                                        <div className="col-lg-3 col-md-4 col-sm-6">
                                            <div className="inner">
                                                <h3>{brandDetails?.firstBoxText}</h3>
                                                <span>{brandDetails?.firstBoxValue}</span>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-4 col-sm-6">
                                            <div className="inner">
                                                <h3>{brandDetails?.secondBoxText}</h3>
                                                <span>{brandDetails?.secondBoxValue}</span>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-4 col-sm-6">
                                            <div className="inner">
                                                <h3>{brandDetails?.thirdBoxText}</h3>
                                                <span>{brandDetails?.thirdBoxValue}</span>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-4 col-sm-6">
                                            <div className="inner">
                                                <h3>{brandDetails?.fourthBoxText}</h3>
                                                <span>{brandDetails?.fourthBoxValue}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <section className="section">
                                <div className="container">
                                    <h2>In The News</h2>
                                    <div className="row mt-5 align-items-center row-gap-4 justify-content-center">
                                        <div className="col-lg-2 col-md-3 col-6">
                                            <a
                                                href="https://economictimes.indiatimes.com"
                                                target="_blank"
                                            >
                                                {" "}
                                                <img src="images/news/1.jpg" alt="news" />
                                            </a>
                                        </div>
                                        <div className="col-lg-2 col-md-3 col-6">
                                            <a
                                                href="https://yourstory.com/search?q=groyyo&page=1"
                                                target="_blank"
                                            >
                                                <img src="images/news/2.jpg" alt="news" />
                                            </a>
                                        </div>
                                        <div className="col-lg-2 col-md-3 col-6">
                                            <a
                                                href="https://www.business-standard.com/search?q=Groyyo"
                                                target="_blank"
                                            >
                                                {" "}
                                                <img src="images/news/3.jpg" alt="news" />
                                            </a>
                                        </div>
                                        <div className="col-lg-2 col-md-3 col-6">
                                            <a
                                                href="https://www.businessinsider.in/searchresult.cms?query=groyyo&sortorder=score"
                                                target="_blank"
                                            >
                                                <img src="images/news/4.jpg" alt="news" />
                                            </a>
                                        </div>
                                        <div className="col-lg-2 col-md-3 col-6">
                                            <a
                                                href="https://www.thehindubusinessline.com/search/?#gsc.tab=0&gsc.q=groyyo&gsc.sort="
                                                target="_blank"
                                            >
                                                <img src="images/news/5.jpg" alt="news" />
                                            </a>
                                        </div>
                                        <div className="col-lg-2 col-md-3 col-6">
                                            <a
                                                href="https://timesofindia.indiatimes.com/topic/groyyo"
                                                target="_blank"
                                            >
                                                <img src="images/news/6.jpg" alt="news" />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </section>

                        {/* contact us  */}

                        <section id="contact-us">
                            <section className="section form-section">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <h2 className="text-start">Get In Touch With Us</h2>
                                            <p>
                                                Need To Speak with Someone? Our Customer Support Team is
                                                just a click away!
                                            </p>
                                            <p>
                                                <a href={"mailto:" + brandDetails?.emailId}>
                                                    Email: {brandDetails?.emailId}
                                                </a>
                                            </p>
                                        </div>
                                        <div className="col-md-6">
                                            {!infoSaved && (
                                                <form>
                                                    <div className="mb-3">
                                                        <label className="form-label">
                                                            Your Name<span className="red-color">*</span>
                                                        </label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            name="name"
                                                            value={contactForm.name}
                                                            onChange={handleChange}
                                                        />
                                                    </div>
                                                    <div className="mb-3">
                                                        <label className="form-label">
                                                            Your Email<span className="red-color">*</span>
                                                        </label>
                                                        <input
                                                            type="email"
                                                            className="form-control"
                                                            name="email"
                                                            value={contactForm.email}
                                                            onChange={handleChange}
                                                        />
                                                    </div>
                                                    <div className="mb-3">
                                                        <label className="form-label">Your Phone</label>
                                                        <input
                                                            type="text"
                                                            min="0"
                                                            className="form-control"
                                                            name="phone"
                                                            value={contactForm.phone}
                                                            onChange={handleChange}
                                                        />
                                                    </div>

                                                    <div className="mb-3">
                                                        <label className="form-label">Your Comments</label>
                                                        <textarea
                                                            className="form-control"
                                                            placeholder="Leave a comment here"
                                                            rows={5}
                                                            name="comments"
                                                            value={contactForm.comments}
                                                            onChange={handleChange}
                                                        ></textarea>
                                                    </div>
                                                    <button
                                                        disabled={
                                                            !(
                                                                contactForm.name.trim().length > 0 &&
                                                                contactForm.email.trim().length > 0
                                                            )
                                                        }
                                                        type="submit"
                                                        className="btn"
                                                        onClick={(e) => handleSubmit(e)}
                                                    >
                                                        Submit
                                                    </button>
                                                </form>
                                            )}
                                            {infoSaved && (
                                                <label className="dv-info-saved">
                                                    <i className="fa fa-check-circle" aria-hidden="true"></i>{" "}
                                                    &nbsp;
                                                    <p>Your information is saved, we will get back to you.</p>
                                                </label>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <section className="section contact-info-section">
                                <div className="container">
                                    <div className="text-center">
                                        <h3>Interested in Ordering in bulk? Get in touch with us.</h3>
                                        <p>
                                            Email:
                                            <a href={"mailto:" + brandDetails?.emailId}> {brandDetails?.emailId}</a>
                                        </p>
                                    </div>
                                </div>
                            </section>
                        </section>
                    </>

                }
            </DefaultLayout>
        </>
    );
};
