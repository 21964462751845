import { createSlice } from "@reduxjs/toolkit";
import { createUserSignup, otpVerification } from "../signup/action";

export const userSignup = createSlice({
  name: "userSignup",
  initialState: {
    email: null,
    password: null,
    otp: null,
    loading: false,
    error: null,
    success: false,
    signupData: null,
    data: null,
    actionName: null
  },
  reducers: {
    resetSignup: (state, action) => {
      state.success = false
      state.error = null
      state.actionName = null
    },
  },

  extraReducers: {
    [createUserSignup.pending]: (state) => {
      state.loading = true;
      state.success = false;
    },
    [createUserSignup.fulfilled]: (state, action) => {
      state.loading = false;
      state.actionName = "emailsignup"
      state.success = true;
    },
    [createUserSignup.rejected]: (state, action) => {
      console.log("error", action)
      state.loading = false;
      state.error = "Email Already Exist!";
    },
    [otpVerification.pending]: (state) => {
      state.loading = true;
      state.success = false;
    },
    [otpVerification.fulfilled]: (state, action) => {
      state.loading = false;
      state.actionName = "otpVerification"
      state.success = true;
    },
    [otpVerification.rejected]: (state, action) => {
      console.log("error", action)
      state.loading = false;
      state.error = "Invalid otp";
    }
  },
});

export default userSignup.reducer;
export const { resetSignup } = userSignup.actions;


