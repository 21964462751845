import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { notify } from "../../../features/notification/notify";
import { createForgotPassword } from "../../../features/login/action";
import { resetForgotPassword } from "../../../features/forgotPassword/slice";

export const ForgotPassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [user, setUser] = useState({
    email: "",
    error: "",
  });

  const passwordResponse = useSelector((state) => state.forgotPassword);

  const handleResetPassword = (data) => {
    let newData = {
      email: data.email,
    };
    console.log(newData);
    dispatch(createForgotPassword(newData));
  };

  const handleChange = (e) => {
    setUser({
      ...user,
      [e.target.name]: e.target.value,
    });
  };

  const UserSchema = Yup.object().shape({
    email: Yup.string()
      .email("Email must be valid")
      .min(2, "Too Short!")
      .required("Required"),
  });

  useEffect(() => {
    console.log("passwordResponse", passwordResponse);
    dispatch(resetForgotPassword());

    if (passwordResponse.success === true) {
      notify(passwordResponse.message, "success");

      navigate("/");
    }
    if (passwordResponse.error) {
      notify(passwordResponse.error, "error");
    }
  }, [passwordResponse]);
  return (
    <>
      <main>
        <div className="sign-details">
          <div className="sign-wrap">
            <img src="images/logo.png" alt="Logo" className="logo" />
            <h2>Please enter your email to send forgot password link.</h2>
            <Formik
              initialValues={user}
              validationSchema={UserSchema}
              enableReinitialize={true}
              onSubmit={(values) => {
                console.log("viewvalues", values);
                handleResetPassword(values);
              }}
            >
              {({ errors, touched, isValid, handleBlur }) => (
                <>
                  <Form>
                    <div className="mb-3 position-relative">
                      <input
                        type="text"
                        value={user.email}
                        name="email"
                        className="form-control"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder="Email"
                      />
                      {errors.email && touched.email ? (
                        <div className="invalid-feedback d-block">
                          {errors.email}
                        </div>
                      ) : null}
                    </div>
                    {user.error ? (
                      <div className="invalid-feedback d-block">
                        {user.error}
                      </div>
                    ) : null}
                    <button type="submit" disabled={!isValid} className="btn">
                      Send
                    </button>
                  </Form>
                </>
              )}
            </Formik>
          </div>
        </div>
      </main>
    </>
  );
};
