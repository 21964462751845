import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { DefaultLayout } from "../../layouts/defaultLayout";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import {
  getEventById,
  getEvents,
} from "../../../features/accountAdmin/event/action";
import { Link } from "react-router-dom";
import { WhatsappShareButton, WhatsappIcon, FacebookShareButton, FacebookIcon, TelegramShareButton, TelegramIcon, TwitterShareButton, TwitterIcon } from "react-share";

export const Eventdetail = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const eventResponse = useSelector((state) => state.eventAdmin);
  const [event, setEvent] = useState([]);
  const [similarEvent, setSimilarEvent] = useState([]);
  const [socialShare, setSocialShare] = useState(false)

  let filter = {
    page: 1,
    limit: 7,
  };

  console.log(id)
  useEffect(() => {
    dispatch(getEventById(id));
    dispatch(getEvents(filter));
  }, [id]);

  useEffect(() => {
    eventResponse.event && setEvent(eventResponse.event);
    eventResponse.events?.rows && setSimilarEvent(eventResponse.events?.rows);
  }, [eventResponse]);

  const style = {
    root: {
      // background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
      // borderRadius: 3,
      // border: 0,
      // boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
      // color: 'white',
      // marginTop : '15px',
      // textAlign : 'center'
      padding: 0

    },
    copyContainer: {
      display: 'none'
    }
  };

  let shareLocation = window.location.href;

  const handleShareArticle = () => {
    setSocialShare(!socialShare)
  }

  return (
    <>
      <DefaultLayout>
        {event && (
          <>
            <div class="blog-detail-wrap mt-3">
              <h1>{event.title}</h1>
              {/* <p class="top-para">
            “I have never found my fragrance, which is why I wanted to create
            it. I’ve been wearing these for a long, long time.”
          </p> */}
              <address class="blog-detail">
                {/* <img
              src="https://hips.hearstapps.com/rover/profile_photos/a5b794e4-ba0e-4527-9e9d-e98142b9fbef_1526586861.jpg?fill=1:1&amp;resize=160:*"
              alt="Headshot of Jenna Rosenstein"
            />
            By
            <a href="#" class="css-1xx5y37 e1c1bym14">
              Jenna Rosenstein
            </a> */}
                <time class="css-w0ebia exadjwu6">
                  Published: {moment(event.createdDate).format("MMM DD, YYYY")}
                </time>
              </address>
              <div class="text-center mb-3">
                <button class="btn save-article" onClick={handleShareArticle}>
                  <svg fill="none">
                    <path
                      fill-rule="evenodd"
                      d="m12 17.408 7 4.4V3.5H5v18.308l7-4.4Zm-5.325 1.323L12 15.384l5.325 3.347V5.23H6.675v13.5Z"
                      clip-rule="evenodd"
                      fill="#fff"
                    ></path>
                  </svg>
                  <span>Share Article</span>
                </button>

                {
                  socialShare &&
                  <div className="row mt-2 justify-content-center share-buttons">
                    <FacebookShareButton
                      url={shareLocation}
                      quote={event.title}
                      hashtag={'#Blogs...'}
                    >
                      <FacebookIcon size={40} round={true} />
                    </FacebookShareButton>
                    <WhatsappShareButton
                      url={shareLocation}
                      quote={event.title}
                      hashtag={'#Blogs...'}
                    >
                      <WhatsappIcon size={40} round={true} />
                    </WhatsappShareButton>
                    <TwitterShareButton
                      url={shareLocation}
                      quote={event.title}
                      hashtag={'#Blogs...'}
                    >
                      <TwitterIcon size={40} round={true} />
                    </TwitterShareButton>
                    <TelegramShareButton
                      url={shareLocation}
                      quote={event.title}
                      hashtag={'#Blogs...'}
                    >
                      <TelegramIcon size={40} round={true} />
                    </TelegramShareButton>
                  </div>
                }
              </div>
              <div class="text-center mb-3">
                <img
                  src={process.env.REACT_APP_API_URL + event.imagePath}
                  alt={event.title}
                />
              </div>
              <p class="desc first-para">
                <div
                  dangerouslySetInnerHTML={{
                    __html: event.description,
                  }}
                ></div>
              </p>
            </div>
            <div class="other-post">
              <div class="container">
                <h2>Similar Events</h2>
                <div class="row row-gap-4">
                  {similarEvent &&
                    similarEvent.map((slEvent, index) => (
                      <>
                        {slEvent.id != id &&
                          <div class="col-md-4" key={index}>
                            <div className="blog-list">
                              <Link to={"/event/" + slEvent.id}>
                                <img
                                  src={
                                    process.env.REACT_APP_API_URL + slEvent.imagePath
                                  }
                                  alt="Event"
                                />
                                <div className="blog-list-desc">
                                  <h4>{slEvent.title}</h4>
                                  <span className="blog-status">
                                    {moment(slEvent.createdDate).format(
                                      "MMM DD, YYYY"
                                    )}
                                  </span>
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: slEvent.description,
                                    }}
                                  ></div>
                                </div>
                              </Link>
                            </div>
                          </div>
                        }
                      </>
                    ))}
                </div>
              </div>
            </div>
          </>
        )}
      </DefaultLayout>
    </>
  );
};
