import React, { useState, useRef, useEffect } from "react";
import Slider from "react-slick";

export const ImageSlider = (props) => {
    const { images } = props;
    const customeSlider = useRef();
    const [sliderSettings, setSliderSettings] = useState({
        infinite: true,
        autoplay: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        autoplaySpeed: 1500
    })
    const play = () => {
        customeSlider.current.slickPlay()
    }
    const pause = () => {
        customeSlider.current.slickPause()
    }     

    return (
        <Slider {...sliderSettings} ref={customeSlider}>
            {images.length > 0 && images.map((img, index) => (                
              (
                img.side != null &&    <div key={index} onMouseEnter={play} onMouseLeave={pause}>
                    <img src={process.env.REACT_APP_API_URL + '/'+ img.filePath} />
                </div>
              )  
            ))
            }
        </Slider>
    );
};
