
import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URLS } from "../../utils/api.urls";



let myHeaders = new Headers();
myHeaders.append('Content-Type', 'application/json');



export const createUserSignup = createAsyncThunk(
    'createUserSignup',
    async (post, { rejectWithValue }) => {
      try {
  
        const response = await fetch(
          API_URLS.SIGNUP,
           {
             method: "POST",
             headers: myHeaders,
             body: JSON.stringify(post),
           }
         );
        if (!response.ok) return rejectWithValue(response.statusText)
        const data = await response.json()
        return data
      } catch (err) {
        // You can choose to use the message attached to err or write a custom error
        return rejectWithValue(err)
      }
    }
  )

  export const otpVerification = createAsyncThunk(
    'otpVerification',
    async (post, { rejectWithValue }) => {
      try {
  
        const response = await fetch(
          API_URLS.OTPVERIFY,
           {
             method: "POST",
             headers: myHeaders,
             body: JSON.stringify(post),
           }
         );
        if (!response.ok) return rejectWithValue(response.statusText)
        const data = await response.json()
        return data
      } catch (err) {
        // You can choose to use the message attached to err or write a custom error
        return rejectWithValue(err)
      }
    }
  )


