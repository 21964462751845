import { configureStore } from "@reduxjs/toolkit";
import userLogin from "../features/login/slice";
import userSignup from "../features/signup/slice";
import forgotPassword from "../features/forgotPassword/slice"
import user from "../features/user/slice"
import product from "../features/product/slice"
import blogAdmin from "../features/accountAdmin/blog/slice"
import eventAdmin from "../features/accountAdmin/event/slice"
import customerQuery from "../features/accountAdmin/customerQuery/slice"
import branding from "../features/accountAdmin/brand/slice"

export const store = configureStore({
  reducer: {
    userLogin,
    userSignup,
    forgotPassword,
    user,
    product,
    blogAdmin,
    customerQuery,
    branding,
    eventAdmin
  },
});
