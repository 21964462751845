import React, { useEffect, useRef, useState } from "react";
import { AccountLayout } from "../accountLayout";
import { Link, useNavigate } from "react-router-dom";
import { deleteBlog, getAllBlogs, updateBlogStatus } from "../../../../features/accountAdmin/blog/action";
import { useDispatch, useSelector } from "react-redux";
import { CONSTANT } from "../../../../utils/constant";
import ReactPaginate from "react-paginate";
import { notify } from "../../../../features/notification/notify";
import { confirmAlert } from 'react-confirm-alert';

export const BlogList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [blogList, setBlogList] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [itemCount, setItemCount] = useState(CONSTANT.PAGE_LIMIT);
  const [currentPage, setcurrentPage] = useState(0);
  const isFirstRender = useRef(true);

  useEffect(() => {
    callBlogsData();
  }, []);

  useEffect(() => {
    if (!isFirstRender.current) {
      callBlogsData();
    }
    isFirstRender.current = false;
  }, [currentPage, itemCount]);

  const callBlogsData = () => {
    dispatch(
      getAllBlogs({
        page: currentPage + 1,
        limit: itemCount,
      })
    );
  };

  const blogAdminResponse = useSelector((state) => state.blogAdmin);
  useEffect(() => {
    blogAdminResponse?.allBlogs?.rows &&
      setBlogList(blogAdminResponse?.allBlogs?.rows);

    if (blogAdminResponse?.allBlogs?.count != null) {
      setPageCount(Math.ceil(blogAdminResponse?.allBlogs?.count / itemCount));
    }
  }, [blogAdminResponse.allBlogs]);

  const handlePageChange = (selectedObject) => {
    setcurrentPage(selectedObject.selected);
  };
  const handleItemsChange = (event) => {
    setItemCount(event.target.value);
    setcurrentPage(0);
  };

  const goToEditPage = (blog) => {
    navigate("/account/blog/edit", {
      replace: true,
      state: { blog: blog },
    });
  };

  const handleDeleteBlog = (id) => {
    confirmAlert({
      title: 'Confirm to delete',
      message: 'Are you sure you want to delete this blog?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            dispatch(deleteBlog({ id :id }))
          }
        },
        {
          label: 'No'
        }
      ]
    });
  }

  const handleUpdateBlogStatus = (id, status) => {
    let payload = {
      id : id,
      isPublished : status
    }

    console.log(payload)
    dispatch(updateBlogStatus(payload))
  }

  useEffect(() => {

    if (blogAdminResponse.success === true && blogAdminResponse.actionName == 'updateBlogStatus') {
      callBlogsData();
      notify('Blog status changed successfully', "success");
    }
    if (blogAdminResponse.success === true && blogAdminResponse.actionName == 'deleteBlog') {
      callBlogsData();
      notify('Blog deleted successfully', "success");
    }
  
  }, [blogAdminResponse]);
  return (
    <AccountLayout>
      <div className="right-column">
        <div className="edit-outer p-4">
          <div className="edit-inner-left">
            <div style={{ justifyContent: "space-between", display: "flex" }}>
              <h3>Blog Listing</h3>
              <div className="text-endtext-end pe-4">
                <Link to="/account/blog/add" className="btn orange-btn">
                  Add New
                </Link>
              </div>
            </div>
            {/* <div className="row row-gap-3 mt-3">
              {blogList &&
                blogList.map((blog, index) => (
                  <div
                    className="col-xxl-3 col-xl-4 col-lg-6 col-md-4 col-sm-6"
                    key={index}
                  >
                    <div className="blog-list">
                      <a href="#">
                        <img
                          src="http://design.groyyo.com/images/blogs/blog-2.jpg"
                          alt="Blog"
                        />
                        <div className="blog-list-desc">
                          <h4>{blog.title}</h4>
                          <span className="blog-status">Published</span>
                       
                            <div
                              dangerouslySetInnerHTML={{
                                __html: blog.description,
                              }}
                            ></div>
                        
                        </div>
                      </a>
                      <div className="blog-action">
                        <a href="#" className="unpublish">
                          Unpublished
                        </a>
                        <div className="right-icon">
                          <a
                            onClick={()=>goToEditPage(blog)}
                          >
                            <i className="fa-regular fa-pen-to-square"></i>
                          </a>
                          <a href="">
                            <i className="fa-regular fa-trash-can"></i>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div> */}
            <div className="table-responsive">
              <table className="table blog-table table-striped">
                <thead>
                  <tr>
                    <th>Title</th>
                    <th>Status</th>
                    <th colSpan="3">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {blogList &&
                    blogList.map((blog, index) => (
                      <tr key={index}>
                        <td>{blog.title}</td>
                        <td>{blog.isPublished ? "Published" : "Unpublished"}</td>
                        <td>
                          <a className="unpublish"onClick={()=>handleUpdateBlogStatus(blog.id,blog.isPublished ? false : true)}>
                          {blog.isPublished ? "Unpublish" : "Publish"}
                          </a>
                        </td>
                        <td>
                          <a onClick={() => goToEditPage(blog)}>
                            <i className="fa-regular fa-pen-to-square"></i>
                          </a>
                        </td>
                        <td>
                          <a onClick={()=>handleDeleteBlog(blog.id)}>
                            <i className="fa-regular fa-trash-can"></i>
                          </a>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
              <nav className="pagination-block">
                <p className="total">
                  {blogAdminResponse?.allBlogs?.count} items total
                </p>

                <ReactPaginate
                  previousLabel={"previous"}
                  nextLabel={"next"}
                  breakLabel={"..."}
                  pageCount={pageCount}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={3}
                  forcePage={currentPage}
                  onPageChange={handlePageChange}
                  containerClassName={"pagination justify-content-center mt-4"}
                  pageClassName={"page-item"}
                  pageLinkClassName={"page-link"}
                  previousClassName={"page-item"}
                  previousLinkClassName={"page-link"}
                  nextClassName={"page-item"}
                  nextLinkClassName={"page-link"}
                  breakClassName={"page-item"}
                  breakLinkClassName={"page-link"}
                  activeClassName={"active"}
                />
                <div className="customSelect">
                  <label htmlFor="">Items per page :</label>
                  <div className="selectBox">
                    <select name="" id="" onChange={handleItemsChange}>
                      <option value={CONSTANT.PAGE_LIMIT}>
                        {CONSTANT.PAGE_LIMIT}
                      </option>
                      <option value="15">15</option>
                      <option value="20">20</option>
                    </select>
                  </div>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </AccountLayout>
  );
};
