import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AccountLayout } from "../accountLayout";
import { addProduct, getCategories, getProductById } from "../../../../features/product/action";
import $ from "jquery";
import { notify } from "../../../../features/notification/notify";
import { resetProduct } from "../../../../features/product/slice";
import { useLocation } from "react-router";
import { v1 as uuidv1 } from 'uuid';

export const AddEditProduct = () => {
    const navigate = useNavigate();
    const [imgList, setImgList] = useState([])
    const [priceList, setPriceList] = useState([])
    const response = useSelector((state) => state.product);
    const [categories, setCategories] = useState([]);
    const dispatch = useDispatch();
    const [formData, setFormData] = useState({})
    const { state } = useLocation();

    const initialize = (data) => {
        if (data != null) {
            setFormData(formData => ({
                ...formData,
                productId: data.id
            }));
            setFormData(formData => ({
                ...formData,
                productName: data.name
            }));
            setFormData(formData => ({
                ...formData,
                styleName: data.StyleName
            }));
            setFormData(formData => ({
                ...formData,
                category: data.categoryId.toString()
            }));
            setFormData(formData => ({
                ...formData,
                otherCategory: data.OtherCategoryId
            }));
            setFormData(formData => ({
                ...formData,
                description: data.description
            }));
            setFormData(formData => ({
                ...formData,
                sizes: data.productSizes.map(x => x.sizeName).join(',')
            }));
            setFormData(formData => ({
                ...formData,
                colors: data.ProductColours.map(x => x.Color).join(',')
            }));
            setFormData(formData => ({
                ...formData,
                status: data.status
            }));
            setFormData(formData => ({
                ...formData,
                leftHeading1: data.leftHeading1
            }));

            setFormData(formData => ({
                ...formData,
                leftHeading1Content: data.leftHeading1Content
            }));

            setFormData(formData => ({
                ...formData,
                leftHeading2: data.leftHeading2
            }));

            setFormData(formData => ({
                ...formData,
                leftHeading2Content: data.leftHeading2Content
            }));
            var temp = [...imgList]
            data.ProductImages.map((item, index) => {
                temp[index] = { id: item.id, side: item.side, file: null, isCover: data.ProductImages.findIndex(x => x.filePath == data.CoverImageLink) === index ? true : false, fileName: item.fileName, isDeleted: false }
            })
            setImgList(temp)
            var price = [...priceList]
            data.ProductPricings.map((item, idx) => {
                price[idx] = {
                    qtyFrom: item.MinQuantity,
                    qtyTo: item.MaxQuantity,
                    price: item.Price
                }
            })
            setPriceList(price);
        } else {
            setFormData({
                productId: 0,
                productName: '',
                styleName: '',
                otherCategory: '',
                category: '',
                description: '',
                colors: '',
                sizes: '',
                status: true,
                leftHeading1: '',
                leftHeading1Content: '',
                leftHeading2: '',
                leftHeading2Content: ''
            })
            setImgList([{ id: 0, side: '', file: null, isCover: true, fileName: '', isDeleted: false }])
            setPriceList([{
                qtyFrom: 0,
                qtyTo: 0,
                price: 0
            }])
        }
    }
    useEffect(() => {
        dispatch(getCategories());
        state && dispatch(getProductById(state.product.id));
        initialize(null)
    }, []);

    useEffect(() => {
        if (response.actionName == 'getCategories')
            response.categories && setCategories(response.categories);

        if (response.actionName == 'addProduct') {
            dispatch(resetProduct())
            if (response.message != null && response.message == 'success') {
                notify('Product added/updated successfully.', 'success')
                $("#img-0").val(null);
                navigate("/account/product/list");
                initialize(null);
            } else {
                notify(response.message, 'danger')
            }
        }
        if (response.actionName == 'getProductById' && response.product) {
            dispatch(resetProduct())
            initialize(response.product)
        }
    }, [response]);


    const handleImgUpload = (e, index, img) => {
        const acceptedFormats = ["png", "jpeg", "jpg", "webp"];
        const fileExtension = e.target.files[0]?.name.split(".").pop().toLowerCase();
        if (!acceptedFormats.includes(fileExtension)) {
            $("#img-" + index).val(null);
            return;
        }
        const temp = [...imgList]
        var image = e.target.files[0];
        var blob = image.slice(0, image.size);
        const id = uuidv1();
        const newImageName = img.side +'-' + id + img.id + '.' + fileExtension
        temp[index].file = new File([blob], newImageName);
        temp[index].fileName =  newImageName;
        setImgList(temp);
    }

    const handleAddImage = () => {
        setImgList(imgList => [...imgList, { id: 0, side: '', file: null, isCover: false, fileName: '', isDeleted: false }]);
    }
    const handleRemoveImg = (index) => {
        $("#img-" + index).val(null);
        const temp = [...imgList];        
        temp[index].isDeleted = true;
        if (temp[index].isCover) {
            temp.find(x => x.isDeleted == false).isCover = true
        }
        setImgList(temp);
    }
    const handleSideInput = (e, index) => {
        const temp = [...imgList]
        temp[index].side = e.target.value;
        setImgList(temp);
    }
    const removePrice = (idx) => {
        const temp = [...priceList];
        temp.splice(idx, 1);
        setPriceList(temp);
    }
    const addPrice = (idx) => {
        setPriceList(priceList => [...priceList, {
            qtyFrom: 0,
            qtyTo: 0,
            price: 0
        }]);
    }
    const changeCoverImg = (e, idx) => {
        const temp = [...imgList];
        temp.map((item, i) => {
            item.isCover = false
        })
        temp[idx].isCover = true;
        setImgList(temp)
    }

    const handleChangeName = (e) => {
        const { value } = e.target;
        setFormData(formData => ({
            ...formData,
            productName: value
        }));
    }
    const handleChangeStyleName = (e) => {
        const { value } = e.target;
        setFormData(formData => ({
            ...formData,
            styleName: value
        }));
    }
    const handleOtherCatChange = (e) => {
        const { value } = e.target;
        setFormData(formData => ({
            ...formData,
            otherCategory: value
        }));
    }
    const handleCategoryChange = (e) => {
        const { value } = e.target;
        setFormData(formData => ({
            ...formData,
            category: value
        }));
    }
    const handleChangeColors = (e) => {
        const { value } = e.target;
        setFormData(formData => ({
            ...formData,
            colors: value
        }));
    }
    const handleChangeSizes = (e) => {
        const { value } = e.target;
        setFormData(formData => ({
            ...formData,
            sizes: value
        }));
    }
    const handleChangeDesc = (e) => {
        const { value } = e.target;
        setFormData(formData => ({
            ...formData,
            description: value
        }));
    }

    const handlePriceChange = (e, idx) => {
        const temp = [...priceList]
        temp[idx][e.target.name] = e.target.value
        setPriceList(temp);
    }

    const handleChangeleftHeading1 = (e) => {
        const { value } = e.target;
        setFormData(formData => ({
            ...formData,
            leftHeading1: value
        }));
    }

    const handleChangeleftHeading2 = (e) => {
        const { value } = e.target;
        setFormData(formData => ({
            ...formData,
            leftHeading2: value
        }));
    }

    const handleChangeContent1 = (e) => {
        const { value } = e.target;
        setFormData(formData => ({
            ...formData,
            leftHeading1Content: value
        }));
    }

    const handleChangeContent2 = (e) => {
        const { value } = e.target;
        setFormData(formData => ({
            ...formData,
            leftHeading2Content: value
        }));
    }


    const submitForm = () => {
        if (imgList.filter(x => (x.side.trim() == '' || (x.file == null && x.fileName == '') && x.isDeleted == false)).length > 0) {
            notify("Please enter product image and it's side!", "error");
        } else {
            if (priceList.filter(x => x.price == 0).length > 0) {
                notify("Please enter quantity range and price!", "error");
            } else {
                let payload = new FormData();
                imgList.map(item => {
                    payload.append("upload_file", item.file);
                })
                payload.append("content", JSON.stringify(formData));
                payload.append("imgList", JSON.stringify(imgList));
                payload.append("priceList", JSON.stringify(priceList));
                payload.append("userId", JSON.stringify(localStorage.getItem("userId")));
                dispatch(addProduct(payload));
            }
        }
    }

    const updateImage = (e, index) => {
        const temp = [...imgList]
        temp[index].fileName = '';
        setImgList(temp);
    }
    const handleClickStatus = () => {
        setFormData(formData => ({
            ...formData,
            status: formData.status ? false : true
        }));
    }
    return (
        <AccountLayout>
            <div className="right-column">
                <div className="edit-outer p-4">
                    <div className="edit-inner-left">
                        <h3>Add Product</h3>

                        {formData.productName != null && <div className="row row-gap-3">
                            <div className="col-md-9">
                                <div className="edit-inner-left">
                                    <h3>General</h3>
                                    <div className="row">
                                        <div className="row row-gap-3">
                                            <div className="col-sm-12">
                                                <label className="form-label">Name</label>
                                                <input type="text" value={formData.productName} className="form-control" onChange={handleChangeName} />
                                            </div>
                                            <div className="col-md-4">
                                                <label className="form-label">Style Name</label>
                                                <input type="text" value={formData.styleName} className="form-control" onChange={handleChangeStyleName} />
                                            </div>
                                            <div className="col-md-4">
                                                <label className="form-label">Category</label>
                                                <select value={formData.category} className="form-select" onChange={handleCategoryChange}>
                                                    <option value=''>--Select--</option>
                                                    {categories.filter(x => {return x.type == "Category" }) &&
                                                        categories.filter(x => {return x.type == "Category" }).map((cat, index) => (
                                                            <option key={index} value={cat.id} >
                                                                {cat.name}
                                                            </option>))}
                                                </select>
                                            </div>
                                            <div className="col-md-4">
                                                <label className="form-label">Other Category</label>
                                                <select value={formData.otherCategory} className="form-select" onChange={handleOtherCatChange}>
                                                    <option value=''>--Select--</option>
                                                    {categories.filter(x => {return x.type == "Season" }) &&
                                                        categories.filter(x => {return x.type == "Season" }).map((cat, index) => (
                                                            <option key={index} value={cat.id} >
                                                                {cat.name}
                                                            </option>))}
                                                </select>
                                            </div>
                                            <div className="col-sm-12">
                                                <label className="form-label">Description</label>
                                                <textarea value={formData.description} className="form-control" rows="5" onChange={handleChangeDesc}></textarea>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="edit-inner-left mt-3">
                                    <h3>Upload Images</h3>
                                    <div className="row">
                                        <div className="col-sm-4">
                                            <label className="form-label">Side Name</label>
                                        </div>
                                        <div className="col-sm-5">
                                            <label className="form-label">Upload File (Preffered size: 512 × 768 px)</label>
                                        </div>
                                        <div className="col-sm-3">
                                            <label className="form-label">Cover Image?</label>
                                        </div>
                                    </div>

                                    {imgList.map((img, index) =>
                                    (img.isDeleted == false ? (<div key={index} className="row mt-3">
                                        <div className="col-sm-4">
                                            <input onChange={(e) => handleSideInput(e, index)} value={img.side} type="text" className="form-control" />
                                        </div>
                                        <div className="col-sm-5">
                                            {img.fileName == '' && <input
                                                accept="image/*"
                                                type="file"
                                                onChange={(e) => handleImgUpload(e, index, img)}
                                                className="form-control"
                                                id={'img-' + index}
                                            />
                                            }
                                            {img.fileName != '' && <label onClick={(e) => updateImage(e, index)} >{img.fileName} <i className="fa fa-trash"></i></label>}
                                        </div>
                                        <div className="col-sm-2">
                                            {<input className="" type="checkbox" checked={img.isCover} onChange={(e) => changeCoverImg(e, index)} />}

                                        </div>
                                        <div className="col-sm-1">
                                            {imgList.filter(x => x.isDeleted == false).length > 1 && <a onClick={() => handleRemoveImg(index)} style={{ cursor: "pointer" }} className="rmvtrash"><i className="fa fa-trash"></i></a>} &nbsp;
                                            {imgList.findLastIndex(x => x.isDeleted == false) == index && <a onClick={handleAddImage} style={{ cursor: "pointer" }} className="add-more-list"><i style={{ fontSize: "16px" }} className="fa fa-square-plus"></i></a>}
                                        </div>
                                    </div>
                                    ) : null
                                    ))}
                                </div>
                                <div className="edit-inner-left mt-3">
                                    <h3>Attribute</h3>
                                    <div className="row row-gap-3">
                                        <div className="col-md-4">
                                            <label className="form-label">Size<small className="dv-comma">(comma separated)</small></label>
                                            <input type="text" value={formData.sizes} className="form-control" onChange={handleChangeSizes} />
                                            <br></br>
                                            <label className="form-label">Colors<small className="dv-comma">(comma separated)</small></label>
                                            <input type="text" value={formData.colors} className="form-control" onChange={handleChangeColors} />
                                        </div>
                                        <div className="col-md-8">
                                            <div className="row">
                                                <div className="col-md-4">
                                                    <label className="form-label">Min Quantity</label>
                                                </div>
                                                <div className="col-md-3">
                                                    <label className="form-label">Max Quantity</label>
                                                </div>
                                                <div className="col-md-3">
                                                    <label className="form-label">Price</label>
                                                </div>
                                                <div className="col-md-2">

                                                </div>
                                            </div>
                                            {priceList.map((item, index) =>
                                                <div key={index} className="dv-sizebx">
                                                    <div className="row mt-2">
                                                        <div className="col-md-4">
                                                            <input type="number" name="qtyFrom" key={index} min={0} value={item.qtyFrom} onChange={(e) => handlePriceChange(e, index)} className="form-control" />
                                                        </div>
                                                        <div className="col-md-3">
                                                            <input type="number" name="qtyTo" key={index} min={0} value={item.qtyTo} onChange={(e) => handlePriceChange(e, index)} className="form-control" />
                                                        </div>
                                                        <div className="col-md-3">
                                                            <input type="number" name="price" key={index} min={0} value={item.price} className="form-control" onChange={(e) => handlePriceChange(e, index)} />
                                                        </div>
                                                        <div className="col-md-2">
                                                            {priceList.length > 1 && <a onClick={() => removePrice(index)} style={{ cursor: "pointer" }} className="rmvtrash"><i className="fa fa-trash"></i></a>} &nbsp;
                                                            {priceList.length == (index + 1) && <a onClick={() => addPrice(index)} style={{ cursor: "pointer" }} className="add-more-list"><i style={{ fontSize: "16px" }} className="fa fa-square-plus"></i></a>}
                                                            {/* {priceList.length > 1 && <a><i className="fa fa-trash" onClick={(e) => removePrice(e, index)}></i></a>}&nbsp;
                                                            {priceList.length == index + 1 && <a><i className="fa fa-plus" onClick={(e) => addPrice(e, index)}></i></a>} */}
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                            }
                                        </div>
                                        {/* <div className="col-md-6">
                                    <label className="form-label">Material</label>
                                    <select className="form-select">
                                        <option value="">Men</option>
                                        <option value="">Men</option>
                                        <option value="">Men</option>
                                    </select>
                                </div>
                                <div className="col-md-6">
                                    <label className="form-label">Brand</label>
                                    <select className="form-select">
                                        <option value="">Men</option>
                                        <option value="">Men</option>
                                        <option value="">Men</option>
                                    </select>
                                </div> */}
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="edit-inner-right">
                                    <div>
                                        <h3>Product Status</h3>
                                        <label className="form-label">Status</label>
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio" name="status" id="Disabled" checked={!formData.status} onClick={handleClickStatus} />
                                            <label className="form-check-label" htmlFor="Disabled">
                                                Disabled
                                            </label>
                                        </div>
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio" name="status" id="Enabled" checked={formData.status} onClick={handleClickStatus} />
                                            <label className="form-check-label" htmlFor="Enabled">
                                                Enabled
                                            </label>
                                        </div>
                                    </div>
                                    <div>
                                        <h3>Left Top Section</h3>
                                        <label className="form-label">Heading</label>
                                        <input type="text" value={formData.leftHeading1} className="form-control" onChange={handleChangeleftHeading1} />

                                        <label className="form-label">Content</label>
                                        <textarea value={formData.leftHeading1Content} className="form-control" onChange={handleChangeContent1}></textarea>
                                    </div>
                                    <div>
                                        <h3>Left Bottom Section</h3>
                                        <label className="form-label">Heading</label>
                                        <input type="text" value={formData.leftHeading2} className="form-control" onChange={handleChangeleftHeading2} />

                                        <label className="form-label">Content</label>
                                        <textarea value={formData.leftHeading2Content} className="form-control" onChange={handleChangeContent2}></textarea>
                                    </div>
                                    {/* <div>
                                <label className="form-label">Status</label>
                                <div className="form-check">
                                    <input className="form-check-input" type="radio" name="status" id="Disabled" />
                                    <label className="form-check-label" for="Disabled">
                                        Disabled
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input" type="radio" name="status" id="Enabled" checked />
                                    <label className="form-check-label" for="Enabled">
                                        Enabled
                                    </label>
                                </div>
                            </div> */}
                                </div>
                                {/* <div className="edit-inner-right mt-3">
                            <div>
                                <h3>Inventory</h3>
                                <label className="form-label">Manage Stock</label>
                                <div className="form-check">
                                    <input className="form-check-input" type="radio" name=" manage-stock" id="no" />
                                    <label className="form-check-label" for="no">
                                        No
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input" type="radio" name=" manage-stock" id="yes" checked />
                                    <label className="form-check-label" for="yes">
                                        Yes
                                    </label>
                                </div>
                            </div>
                            <div>
                                <label className="form-label">Stock availability</label>
                                <div className="form-check">
                                    <input className="form-check-input" type="radio" name="stock-availability" id="no" />
                                    <label className="form-check-label" for="no">
                                        No
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input" type="radio" name="stock-availability" id="yes" checked />
                                    <label className="form-check-label" for="yes">
                                        yes
                                    </label>
                                </div>
                            </div>
                            <div>
                                <label className="form-label">Quantity</label>
                                <input type="text" className="form-control" />
                            </div>
                        </div> */}

                            </div>
                            <div className="col-md-9">
                                <div className="edit-btn-group">
                                    <button className="btn cancel" onClick={(e) => window.history.back()}>Cancel</button>
                                    <button disabled={!(formData.productName.length > 0 && formData.styleName?.length > 0 && formData.category.length > 0 && formData.sizes.length > 0)} onClick={submitForm} className="btn orange-btn">Save</button>
                                </div>
                            </div>
                        </div>
                        }
                    </div>
                </div>
            </div>
        </AccountLayout>
    )
}
