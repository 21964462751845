import React, { useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router";

export const Payment = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const formRef = useRef(null);

  useEffect(() => {
    if (state) {
        formRef.current.submit();
    }else {
        navigate("/bag");
    } 
  }, []);


  return (
    <>
        <div>
          <form
            id="nonseamless"
            method="post"
            name="redirect"
            ref={formRef}
            action={process.env.REACT_APP_PAYMENT_GATEWAY + '/transaction/transaction.do?command=initiateTransaction'}
          >
            <input
              type="hidden"
              id="encRequest"
              name="encRequest"
              value={state.payment.request}
            />
            <input
              type="hidden"
              name="access_code"
              id="access_code"
              value={state.payment.access}
            />
          </form>
        </div>
    </>
  );
};
