import "./assets/css/style.css";
import "./assets/css/development.css";
import "./assets/css/slick.css";
import "./assets/css/slick-theme.css";
import "./assets/css/media.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-confirm-alert/src/react-confirm-alert.css";
import Routing from "./routes";
import ReactGA from "react-ga4";

const App = () => {

  ReactGA.initialize("G-2MYQ26W7Q7")
  return (
    <>
      <ToastContainer />
      <Routing />
    </>
  );
};
export default App;
