export const BASE_URLS = {
    BASE_URL: process.env.REACT_APP_API_URL,
  };



  export const API_URLS = {

  
    LOGIN: BASE_URLS.BASE_URL + "/users/login",
    SIGNUP: BASE_URLS.BASE_URL + "/users/signup",
    OTPVERIFY: BASE_URLS.BASE_URL + "/users/otpVerification",
    GOOGLE_LOGIN: BASE_URLS.BASE_URL + "/users/googleLogin",
    FACEBOOK_LOGIN: BASE_URLS.BASE_URL + "/users/facebookLogin",
    FORGOT_PASSWORD: BASE_URLS.BASE_URL + "/users/forgotPassword",
    RESET_PASSWORD: BASE_URLS.BASE_URL + "/users/resetPassword",
    UPDATE_PASSWORD: BASE_URLS.BASE_URL + "/users/updatePassword",
    UPDATE_USER: BASE_URLS.BASE_URL + "/users/updateUser",
    PROFILE: BASE_URLS.BASE_URL + "/users/getProfileData",
    PROFILE_ADDRESS: BASE_URLS.BASE_URL + "/users/getAddressByUserId",
    ADDRESS_DATA: BASE_URLS.BASE_URL + "/users/getAddressByAddressId",
    ADD_UPDATE_ADDRESS: BASE_URLS.BASE_URL + "/users/addUpdateAddress",
    SET_DEFAULT_ADDRESS: BASE_URLS.BASE_URL + "/users/setDefaultAddress",
    DELETE_ADDRESS: BASE_URLS.BASE_URL + "/users/deleteAddress",
    CONTACT_US: BASE_URLS.BASE_URL + "/users/submitUserQuery",
    GET_WISHLIST: BASE_URLS.BASE_URL + "/users/fetchWishlist",
    GET_ORDERS: BASE_URLS.BASE_URL + "/users/fetchUserOrders",
    UPDATE_WISHLIST: BASE_URLS.BASE_URL + "/users/updateWishlist",
    GET_BRANDBY_NAME: BASE_URLS.BASE_URL + '/users/getBrandByName',
    UPDATE_ORDER_STATUS: BASE_URLS.BASE_URL + '/users/updateOrderStatus',
    CHANGE_PASSWORD: BASE_URLS.BASE_URL + "/users/changePassword",

    PRODUCT : {
      GET_LIST : BASE_URLS.BASE_URL + "/product/fetchProducts",
      GET_CATEGORIES: BASE_URLS.BASE_URL + "/product/getCategories",
      UPLOAD_PRODUCTS: BASE_URLS.BASE_URL + "/product/bulkProductUpload",
      ADD_TO_CART: BASE_URLS.BASE_URL + "/product/addToCart",
      GET_CART_BADGE: BASE_URLS.BASE_URL + "/product/getCartBadge",
      GET_ORDERS: BASE_URLS.BASE_URL + "/product/getOrders",
      ADD_PRODUCT: BASE_URLS.BASE_URL + "/product/addUpdateProduct",
      GET_PRODUCTBYID: BASE_URLS.BASE_URL + "/product/getProductById",
      UPDATE_CART_QTY: BASE_URLS.BASE_URL + "/product/updateCartQuantity",
      REMOVE_FROM_CART: BASE_URLS.BASE_URL + "/product/removeFromCart",
      SAVE_FOR_LATER: BASE_URLS.BASE_URL + "/product/saveForLater",
      CHANGE_PRODUCT_STATUS: BASE_URLS.BASE_URL + "/product/updateProductStatus",
      PROCESS_ORDER: BASE_URLS.BASE_URL + "/product/processOrder",

    },
    PAYMENT : {
      PROCESS_PAYMENT: BASE_URLS.BASE_URL + "/payment/processPayment",
    },

    ADMIN : {
      GET_ALL_LIST : BASE_URLS.BASE_URL + "/siteAdmin/fetchProducts",
      GET_ALL_BLOGS : BASE_URLS.BASE_URL +  '/siteAdmin/getAllBlogs',
      GET_BLOGS : BASE_URLS.BASE_URL +  '/siteAdmin/getBlogs',
      ADD_UPDATE_BLOG : BASE_URLS.BASE_URL +  '/siteAdmin/addUpdateBlogs',
      UPDATE_BLOG_STATUS : BASE_URLS.BASE_URL +  '/siteAdmin/updateBlogStatus',
      DELETE_BLOG : BASE_URLS.BASE_URL +  '/siteAdmin/deleteBlog',
      GET_BLOG_BY_ID : BASE_URLS.BASE_URL +  '/siteAdmin/getBlogById',
      GET_ALL_EVENTS : BASE_URLS.BASE_URL +  '/siteAdmin/getAllEvents',
      GET_EVENTS : BASE_URLS.BASE_URL +  '/siteAdmin/getEvents',
      ADD_UPDATE_EVENT : BASE_URLS.BASE_URL +  '/siteAdmin/addUpdateEvents',
      UPDATE_EVENT_STATUS : BASE_URLS.BASE_URL +  '/siteAdmin/updateEventStatus',
      DELETE_EVENT : BASE_URLS.BASE_URL +  '/siteAdmin/deleteEvent',
      GET_EVENT_BY_ID : BASE_URLS.BASE_URL +  '/siteAdmin/getEventById',
      GET_All_CATEGORIES : BASE_URLS.BASE_URL +'/siteAdmin/getAllCategories',
      ADD_UPDATE_Category : BASE_URLS.BASE_URL + '/siteAdmin/addUpdateCategory',
      GET_Category_BY_ID : BASE_URLS.BASE_URL + '/siteAdmin/getCategoryById',
      GET_ALL_QUERIES : BASE_URLS.BASE_URL +  '/siteAdmin/fetchAllQueries',
      UPDATE_QUERY: BASE_URLS.BASE_URL + '/siteAdmin/updateQueryById',
      ADD_UPDATE_BRAND: BASE_URLS.BASE_URL + '/siteAdmin/addUpdateBrand',
      GET_ALL_BRANDS: BASE_URLS.BASE_URL + '/siteAdmin/getAllBrands',
      SEND_REPLY_MAIL: BASE_URLS.BASE_URL + '/siteAdmin/sendReplyMail'
    }
  }