import React, { useEffect, useState } from "react";
import { AccountLayout } from "../accountLayout";
import { useDispatch, useSelector } from "react-redux";
import { getUserOrders, updateOrderStatus } from "../../../../features/user/action";
import moment from 'moment'
import { fontWeight } from "@mui/system";
import { notify } from "../../../../features/notification/notify";
import { resetUser } from "../../../../features/user/slice";
import { confirmAlert } from 'react-confirm-alert';
import { Modal } from "react-bootstrap";
import $ from 'jquery'
export const Orders = () => {
  const dispatch = useDispatch();
  const [orders, setOrders] = useState([]);
  const userId = localStorage.getItem("userId");
  const roleObject = localStorage.getItem('userRoles');
  const orderResponse = useSelector((state) => state.user);
  let role = roleObject != null ? JSON.parse(roleObject).role : "User";
  const [showCustDetails, setShowCustDetails] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [searchText, setSearchText] = useState('')
  useEffect(() => {
    dispatch(getUserOrders({ userId: userId, role: role, searchText: searchText }));
  }, []);

  const handleCancellation = (id) => {
    confirmAlert({
      title: 'Confirm to cancel',
      message: 'Are you sure you want to cancel this order?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            dispatch(updateOrderStatus({ userId: userId, orderId: id, statusText: "Cancelled" }))
          }
        },
        {
          label: 'No'
        }
      ]
    });
  };

  useEffect(() => {
    if (orderResponse.actionName == 'getUserOrders') {
      dispatch(resetUser())
      orderResponse.orders && setOrders(orderResponse.orders);
    }

    if (orderResponse.actionName == 'updateOrderStatus' && orderResponse.success) {
      dispatch(resetUser())
      dispatch(getUserOrders({ userId: userId, role: role, searchText: '' }));
     
      notify('Order status changed!', 'success')
    }
  }, [orderResponse]);


  const handleStatusChange = (e, order) => {
    if (e.target.value != '')
      dispatch(updateOrderStatus({ userId: userId, orderId: order.orderId, statusText: e.target.value }))
  }

  const displayCustomerInfo = (order) => {
    setSelectedOrder(order);
    setShowCustDetails(true);
  }

  const handleCloseModal = () => {
    setSelectedOrder(null);
    setShowCustDetails(false);
  }
  const handleSearchClicked = (action) => {
    if (action == 'search') {
      let search = $('#search-text').val();
      if (search.trim().length > 2) {
        setSearchText(search);
        dispatch(getUserOrders({ userId: userId, role: role, searchText: search }));
      }
    } else {
      dispatch(getUserOrders({ userId: userId, role: role, searchText: '' }));
      $('#search-text').val('');
      setSearchText('');
    }
  }

  return (
    <AccountLayout>
      <div className="right-column">
        <div className="edit-outer p-4">
          <div className="edit-inner-left">
            <h3>All Orders</h3>
            <div className="search-order">
              <input
                type="text"
                className="form-control"
                placeholder=" Search by product name/order status..."
                id='search-text'
              />
              {searchText.trim().length == '' && <button className="btn" onClick={(e) => handleSearchClicked('search')}>
                <i className="fa fa-search me-2"></i>
                Search
              </button>}
              {searchText.trim().length > 2 && <button className="btn" onClick={(e) => handleSearchClicked('reset')}>
                Reset
              </button>}
            </div>
            <div className="all-order-wrap">
              {orders.length == 0 && <p className="para-unavail">No record available</p>}
              {orders && orders.map((order, index) => (
                <div className="row all-order-list" key={index}>
                  <div className="order-status">
                    <div className="w-5">
                      <i className="fa-solid fa-bag-shopping"></i>
                    </div>
                    <div className="dv-ordr-stats">
                      <span className="status confirmed">{order.status} - {order.orderNumber}</span>
                      <p className="delivery-date">On  {moment(order.orderedDate).format(
                        "MMM DD, YYYY"
                      )}</p>

                    </div>
                    {order.address != null &&
                      <div className="dvcust">
                        <div className="dv-ordr-info"><span onClick={(e) => displayCustomerInfo(order)}>Customer Info </span></div>
                      </div>}
                    {order.address == null &&
                      <div className="w-50">
                      </div>}
                    <div className="w-10">
                      {role == "Admin" && <>
                        <select value={order.status} onChange={(e) => handleStatusChange(e, order)} className="form-control slct-ordr-stts float-end w-auto">
                          <option value=''>-Change Order Status-</option>
                          <option value='Ordered'>Received </option>
                          <option value='Confirmed'>Confirmed </option>
                          <option value='In-progress'>In-progress</option>
                          <option value='In-transit'>In-transit</option>
                          <option value='Delivered'>Delivered</option>
                          <option value='Cancelled'>Cancelled</option>
                          <option value='Refunded'>Refunded</option>
                          <option value='Exchange in Progress'>Exchange in Progress</option>
                        </select></>
                      }</div>
                    <div className="w-20">
                      <span className="status confirmed">Total</span>
                      <p className="delivery-date">₹{parseInt(order.totalAmount).toFixed(2)}</p>
                    </div>
                  </div>
                  {
                    order.products && order.products.map((product, index) => (
                      <div className="all-order-list-inner mt-2">
                        <div className="product-list-thumbnail">
                          <img
                            src={
                              process.env.REACT_APP_API_URL + "/" + product.productImage
                            }
                            alt=""
                          />
                          <div className="float-start">
                            <div className="brand-name">{product.productName}</div>
                            <div className="product-desc">
                              {product.description}
                            </div>
                            <div className="row">
                              <div className="col-sm-4" style={{ fontWeight: "bold" }}>Size</div>
                              {product.quantity && product.quantity.map((size, index) => (
                                <><div className="col-sm-2 text-center">{size.sizeName}</div></>))
                              }
                            </div>
                            <div className="row mt-1">
                              <div className="col-sm-4" style={{ fontWeight: "bold" }}>Quantity</div>
                              {product.quantity && product.quantity.map((size, index) => (
                                <><div className="col-sm-2 text-center">{size.quantity}</div></>))
                              }
                            </div>
                            <div className="row mt-1">
                              <div className="col-sm-4" style={{ fontWeight: "bold" }}>Price</div>
                              {product.quantity && product.quantity.map((size, index) => (
                                <><div className="col-sm-2 text-center">₹{parseInt(size.price)}</div></>))
                              }
                            </div>
                          </div>
                          {/* <div className="float-end">
                          <div className="row">
                            <div className="col-sm-4" style={{ fontWeight: "bold" }}>Price</div>
                            {product.quantity && product.quantity.map((size, index) => (
                              <><div className="col-sm-2">{size.quantity}</div></>))
                            }
                          </div>
                        </div> */}
                        </div>
                      </div>
                    ))
                  }
                  {role != "Admin" && order.status != "Cancelled" &&
                    <div className="row ordr-fttr">
                      <div className="col-md-4">
                        <div className="order-btns">
                          <button className="btn" onClick={() => {
                            handleCancellation(order.orderId);
                          }}>Cancel</button>
                        </div>
                      </div>
                      <div className="col-md-4">

                      </div>
                    </div>
                  }
                </div>
              ))}
              {/* <div className="all-order-list">
                <div className="order-status">
                  <i className="fa-solid fa-xmark"></i>
                  <div>
                    <span className="status cancelled">Cancelled</span>
                    <p className="delivery-date">On Wed, 11 Oct</p>
                  </div>
                </div>
                <div className="all-order-list-inner">
                  <div className="product-list-thumbnail">
                    <img
                      src="https://assets.myntassets.com/f_webp,dpr_1,q_10,w_200,c_limit,fl_progressive/assets/images/19834508/2022/9/8/2145ea88-70ea-4ce6-9195-ab98de88776a1662634859727SeeDesignsMenWhiteThreadWorkKurta1.jpg"
                      alt=""
                    />
                    <div>
                      <div className="brand-name">HERE&NOW</div>
                      <div className="product-desc">
                        Men White Pure Cotton Chikankari Embroidered Straight
                        Kurta
                      </div>
                      <div className="product-size">Size: L</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="all-order-list">
                <div className="order-status">
                  <i className="fa-solid fa-truck"></i>
                  <div>
                    <span className="status delivered">Delivered</span>
                    <p className="delivery-date">On Wed, 11 Oct</p>
                  </div>
                </div>
                <div className="all-order-list-inner">
                  <div className="product-list-thumbnail">
                    <img
                      src="https://assets.myntassets.com/f_webp,dpr_1,q_10,w_200,c_limit,fl_progressive/assets/images/19834508/2022/9/8/2145ea88-70ea-4ce6-9195-ab98de88776a1662634859727SeeDesignsMenWhiteThreadWorkKurta1.jpg"
                      alt=""
                    />
                    <div>
                      <div className="brand-name">HERE&NOW</div>
                      <div className="product-desc">
                        Men White Pure Cotton Chikankari Embroidered Straight
                        Kurta
                      </div>
                      <div className="product-size">Size: L</div>
                    </div>
                  </div>
                  <div className="order-footer">
                    <div className="order-btns">
                      <button className="btn">Exchange</button>
                      <button className="btn">Return</button>
                    </div>
                    <div className="rating">
                      <span>Rate Product</span>
                      <div className="rating-inner">
                        <i className="fa-solid fa-star"></i>
                        <i className="fa-regular fa-star"></i>
                        <i className="fa-regular fa-star"></i>
                        <i className="fa-regular fa-star"></i>
                        <i className="fa-regular fa-star"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
      <Modal show={showCustDetails} className="custom-modal pin-modal">
        <Modal.Body>
          <div>
            <h5 style={{ color: '#a3865b' }}>Customer Information</h5>
          </div>
          <hr />
          <div className="container dv-adrss-dtls">
            <div className="row">
              <div className="col-md-4" >
                <label className="form-label">Name & Email: </label>
              </div>
              <div className="col-md-8">
                <p>{selectedOrder?.address?.addressName} ,  {selectedOrder?.user?.emailId} </p>

              </div>
            </div>
            <div className="row">
              <div className="col-md-4">
                <label className="form-label">Delivery address:</label>
              </div>
              <div className="col-md-8">
                <p>{selectedOrder?.address?.streetAddress1}, {' '} {selectedOrder?.address?.streetAddress2},  {' '}
                  {selectedOrder?.address?.landmark},  {' '}
                  {selectedOrder?.address?.city},  {' '}
                  {selectedOrder?.address?.state}, {' '}
                  {selectedOrder?.address?.zip}
                </p>
              </div>
            </div>
          </div>
          <div className="dv-mdl-cls">
            <button
              type="button"
              className="btn cancel"
              onClick={handleCloseModal}
            >
              Close
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </AccountLayout >
  );
};
