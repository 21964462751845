import React, { useEffect,useRef, useState } from "react";
import { DefaultLayout } from "../../layouts/defaultLayout";
import { getEvents } from "../../../features/accountAdmin/event/action";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { Link } from "react-router-dom";
import { CONSTANT } from "../../../utils/constant";
import ReactPaginate from "react-paginate";
export const Events = () => {
  const dispatch = useDispatch();
  const [eventList, setEventList] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [itemCount, setItemCount] = useState(CONSTANT.PAGE_LIMIT);
  const [currentPage, setcurrentPage] = useState(0);
  const isFirstRender = useRef(true);
  
  useEffect(() => {
    if (!isFirstRender.current) {
        callEventsData();
    }
    isFirstRender.current = false;
  }, [currentPage, itemCount]);
  const callEventsData = () => {
    dispatch(
       
      getEvents({
        page: currentPage + 1,
        limit: itemCount,
      })
    );
  };
 

  const eventResponse = useSelector((state) => state.eventAdmin);
  useEffect(() => {
    eventResponse?.events?.rows &&
    setEventList(eventResponse?.events?.rows);

    if (eventResponse?.events?.count != null) {
      setPageCount(Math.ceil(eventResponse?.events?.count / itemCount));
    }
  }, [eventResponse.events]);

  const handlePageChange = (selectedObject) => {
    setcurrentPage(selectedObject.selected);
  };
  const handleItemsChange = (event) => {
    setItemCount(event.target.value);
    setcurrentPage(0);
  };

 

  return (
    <>
      <DefaultLayout>
        <section className="section latest-section latest-banner">
          <div className="container">
            <div className="top-content">
              <h1>Latest Events</h1>
              {/* <p>
              Lorem, ipsum dolor sit amet consectetur adipisicing elit. Animi
              sapiente aperiam alias corporis necessitatibus et non consequatur.
              Quam dicta id facere quasi corporis, nostrum ducimus tenetur, illo
              hic in vero!
            </p> */}
            </div>
            <div className="tab-content" id="myTabContent">
              <div className="row">
                {eventList &&
                  eventList.map((event, index) => (
                    <div className="col-md-3">
                      <Link to={"/event/" + event.id}>
                        <img
                          src={process.env.REACT_APP_API_URL + event.imagePath}
                          alt="Event"
                          className="w-100"
                        />
                      </Link>
                      <div className="content">
                        <div className="date-author">
                          {moment(event.createdDate).format("MMMM DD, YYYY")}
                        </div>
                        <h3>{event.title}</h3>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
            <nav className="pagination-block">
                <p className="total">
                  {eventResponse?.events?.count} items total
                </p>

                <ReactPaginate
                  previousLabel={"previous"}
                  nextLabel={"next"}
                  breakLabel={"..."}
                  pageCount={pageCount}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={3}
                  forcePage={currentPage}
                  onPageChange={handlePageChange}
                  containerClassName={"pagination justify-content-center mt-4"}
                  pageClassName={"page-item"}
                  pageLinkClassName={"page-link"}
                  previousClassName={"page-item"}
                  previousLinkClassName={"page-link"}
                  nextClassName={"page-item"}
                  nextLinkClassName={"page-link"}
                  breakClassName={"page-item"}
                  breakLinkClassName={"page-link"}
                  activeClassName={"active"}
                />
                <div className="customSelect">
                  <label htmlFor="">Items per page :</label>
                  <div className="selectBox">
                    <select name="" id="" onChange={handleItemsChange}>
                      <option value={CONSTANT.PAGE_LIMIT}>
                        {CONSTANT.PAGE_LIMIT}
                      </option>
                      <option value="15">15</option>
                      <option value="20">20</option>
                    </select>
                  </div>
                </div>
              </nav>
          </div>
        </section>
      </DefaultLayout>
    </>
  );
};
