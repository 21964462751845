import React, { Suspense } from 'react'
import ReactDOM from "react-dom/client";
import i18next from 'i18next'
import { initReactI18next } from 'react-i18next'
import HttpApi from 'i18next-http-backend'
import LanguageDetector from 'i18next-browser-languagedetector'
import { GoogleOAuthProvider } from '@react-oauth/google';
import App from "./app";
import reportWebVitals from "./reportWebVitals";
import { store } from "./app/store";
import { Provider } from "react-redux";
import { CartProvider } from "react-use-cart";


const root = ReactDOM.createRoot(document.getElementById("root"));
i18next
  .use(HttpApi)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    supportedLngs: ['en', 'ar', 'fr'],
    fallbackLng: 'en',
    debug: false,
    // Options for language detector
    detection: {
      order: ['path', 'cookie', 'htmlTag'],
      caches: ['cookie'],
    },
    react: { useSuspense: false },
    backend: {
      loadPath: '/locales/{{lng}}/translation.json',
    },
  })

const loadingMarkup = (
  <div className="py-4 text-center">
    <h3>Loading..</h3>
  </div>
)

root.render(
  <Suspense fallback={loadingMarkup}>
  <React.StrictMode>
    <Provider store={store}>
    <CartProvider>
      <App />
      </CartProvider>
    </Provider>
  </React.StrictMode>
  </Suspense>
);


reportWebVitals();