import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import DashboardLayout from "../components/dashboardLayout";
import Dashboard from "../modules/dashboard";
import User from "../modules/user";
import Auth from "./auth";
import EditProfile from "../modules/dashboard/editProfile";
import Home2 from "../modules/dashboard/home";
import { ContactUs } from "../modules/pages/contactUs";
import { Blogs } from "../modules/pages/blogs/blogs";
import { Events } from "../modules/pages/events/events";
import { WishList } from "../modules/pages/wishList";
import { Designs } from "../modules/pages/designs";
import { Bag } from "../modules/pages/checkout/bag";
import { AboutUs } from "../modules/pages/aboutUs";
import { Home } from "../modules/pages/home";
import { Blogdetail } from "../modules/pages/blogs/blogdetail";
import { Eventdetail } from "../modules/pages/events/eventdetail";
import { Login } from "../modules/pages/login/login";
import { Signup } from "../modules/pages/signup/signup";
import { ForgotPassword } from "../modules/pages/password/forgotPassword";
import { ResetPassword } from "../modules/pages/password/resetPassword";
import { NotFound404 } from "../modules/layouts/notFound404";
import { ProductList } from "../modules/pages/products/productList";
import { ProductDetails } from "../modules/pages/products/productDetails";
import { MyOrders } from "../modules/pages/order/myOrders";
import { AddEditAddress } from "../modules/pages/account/address/addEditAddress";
import { Address } from "../modules/pages/account/address/address";
import { AddEditProfile } from "../modules/pages/account/profile/addEditProfile";
import { Profile } from "../modules/pages/account/profile/profile";
import { Orders } from "../modules/pages/account/orders/orders";
import { BulkUpload } from "../modules/pages/account/product/bulkUpload";
import { AddEditProduct } from "../modules/pages/account/product/addEditProduct";
import { AddEditBlog } from "../modules/pages/account/blog/addEditBlog";
import { AddEditEvent } from "../modules/pages/account/event/addEditEvent";
import { Thankyou } from "../modules/pages/thankyou";
import { Sorry } from "../modules/pages/sorry";
import { BlogList } from "../modules/pages/account/blog/blogList";
import { EventList } from "../modules/pages/account/event/eventList";
import { AllProducts } from "../modules/pages/account/product/allProducts";
import { AllCategories } from "../modules/pages/categories/allCategories";
import { AddUpdateCategory } from "../modules/pages/categories/addUpdateCategory";
import AdminAuth from "./adminAuth";
import { QueryList } from "../modules/pages/account/customer/queryList";
import { BrandList } from "../modules/pages/account/branding/brandList";
import { AddUpdateBrand } from "../modules/pages/account/branding/addUpdateBrand";
import { Payment } from "../modules/pages/checkout/payment";
import {ChangeUserPassword} from "../modules/pages/account/profile/changeUserPassword";
import { OtpSignup } from "../modules/pages/signup/otpSignup";
const Router = (props) => {                                                                           
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/otpSignup" element={<OtpSignup /> } />
          <Route exact path="/home" element={<Home />} />
          <Route exact path="/" element={<Home />} />
          <Route exact path="/404" element={<NotFound404 />} />
          <Route exact path="/forgot-password" element={<ForgotPassword />} />
          <Route exact path="/reset-password" element={<ResetPassword />} />
          <Route exact path="/about-us" element={<AboutUs />} />
          <Route exact path="/contact-us" element={<ContactUs />} />
          <Route exact path="/blogs" element={<Blogs />} />
          <Route exact path="/blog/:id" element={<Blogdetail />} />
          <Route exact path="/events" element={<Events />} />
          <Route exact path="/event/:id" element={<Eventdetail />} />
          <Route exact path="/wishlist" element={<WishList />} />
          <Route
              path="/designs"
              element={<Navigate to="/designs/1" replace />}
          />
          <Route exact path="/designs/:name" element={<ProductList />} />
          <Route exact path="/designs/:name/:stype" element={<ProductList />} />
          <Route exact path="/bag" element={<Bag />} />
          <Route exact path="/product-details/:cat/:name" element={<ProductDetails />} />
          <Route exact path="/orders" element={<MyOrders />} />
          <Route exact path="/thankyou" element={<Thankyou />} />
          <Route exact path="/sorry" element={<Sorry />} />

                                       {/*User Authentication*/}

          {/* <Route exact path="/dashboard" element={<Auth><Dashboard {...props} /></Auth>} />
          <Route exact path="/editAddress/:id" element={<Auth><AddEditAddress {...props} /></Auth>} /> */}
          <Route exact path="/account/address" element={<Auth><Address {...props} /></Auth>} />
          <Route exact path="/account/address/add" element={<Auth><AddEditAddress {...props} /></Auth>} />
          <Route exact path="/account/address/edit" element={<Auth><AddEditAddress {...props} /></Auth>} />
          <Route exact path="/account/profile/add" element={<Auth><AddEditProfile {...props} /></Auth>} />
          <Route exact path="/account/profile/edit" element={<Auth><AddEditProfile {...props} /></Auth>} />
          <Route exact path="/account/profile" element={<Auth><Profile {...props} /></Auth>} />
          <Route exact path="/account/orders" element={<Auth><Orders {...props} /></Auth>} />
          <Route exact path="/account/category/list" element={<Auth><AllCategories {...props} /></Auth>} />
          <Route exact path="/account/category/add" element={<Auth><AddUpdateCategory {...props} /></Auth>} />
          <Route exact path="/account/category/edit" element={<Auth><AddUpdateCategory {...props} /></Auth>} />
          <Route exact path="/payment" element={<Auth><Payment {...props} /></Auth>} />
          <Route exact path="/account/change-password" element={<Auth><ChangeUserPassword {...props} /></Auth>} />

                                         {/*Admin Authentication*/}

          <Route exact path="/account/product-bulkupload" element={<AdminAuth><BulkUpload {...props} /></AdminAuth>} />
          <Route exact path="/account/product/add" element={<AdminAuth><AddEditProduct {...props} /></AdminAuth>} />
          <Route exact path="/account/product/edit" element={<AdminAuth><AddEditProduct {...props} /></AdminAuth>} />
          <Route exact path="/account/product/list" element={<AdminAuth><AllProducts {...props} /></AdminAuth>} />
          <Route exact path="/account/blog/add" element={<AdminAuth><AddEditBlog {...props} /></AdminAuth>} />
          <Route exact path="/account/blog" element={<AdminAuth><BlogList {...props} /></AdminAuth>} />
          <Route exact path="/account/blog/edit" element={<AdminAuth><AddEditBlog {...props} /></AdminAuth>} />
          <Route exact path="/account/event/add" element={<AdminAuth><AddEditEvent {...props} /></AdminAuth>} />
          <Route exact path="/account/event" element={<AdminAuth><EventList {...props} /></AdminAuth>} />
          <Route exact path="/account/event/edit" element={<AdminAuth><AddEditEvent {...props} /></AdminAuth>} />
          <Route exact path="/account/customer/query" element={<AdminAuth><QueryList {...props} /></AdminAuth>} />
          <Route exact path="/account/brand" element={<AdminAuth><BrandList {...props} /></AdminAuth>} />
          <Route exact path="/account/brand/add" element={<AdminAuth><AddUpdateBrand {...props} /></AdminAuth>} />
          <Route exact path="/account/brand/edit" element={<AdminAuth><AddUpdateBrand {...props} /></AdminAuth>} />

          <Route
            path="/"
            element={<DashboardLayout />}
          >
            <Route path="dashboard" element={<Dashboard />} />
            <Route path="users" element={<User />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </>
  );
};

export default Router;
