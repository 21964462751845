import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { getAllCategories } from "../../../features/product/action";
import { addUpdateCategory, getCategoryById } from "../../../features/accountAdmin/blog/action";
import { resetCategoryAdmin  } from "../../../features/accountAdmin/blog/slice";
import { notify } from "../../../features/notification/notify";
import { AccountLayout } from "../account/accountLayout";
import { CONSTANT } from "../../../utils/constant";
import ReactPaginate from "react-paginate";
import { Button } from "react-bootstrap";
import { confirmAlert } from 'react-confirm-alert';

export const AllCategories = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [categories, setCategories] = useState([]);
  const [itemCount, setItemCount] = useState(CONSTANT.PAGE_LIMIT);
  const pageNumber = localStorage.getItem("currentPage") ? localStorage.getItem("currentPage") : 0 ;
  const [currentPage, setcurrentPage] = useState(parseInt(pageNumber));
   
  const [pageCount, setPageCount] = useState(0);
  const isFirstRender = useRef(true);

  useEffect(() => {
    callCategoryData()
  }, []);

  useEffect(() => {
    if (!isFirstRender.current) {
      callCategoryData()    
    }
    isFirstRender.current = false;
  }, [currentPage, itemCount]);
  
  const callCategoryData = () => {
    localStorage.removeItem('currentPage');
    dispatch(getAllCategories({
      page: currentPage + 1,
      limit: itemCount,
    })
    ); 
  };
  const categoryResponse = useSelector((state) => state.product);
  useEffect(() => {
    categoryResponse?.allCategories?.rows &&
      setCategories(categoryResponse?.allCategories?.rows);
      if (categoryResponse?.allCategories?.count != null && pageCount === 0) {
        setPageCount(Math.ceil(categoryResponse?.allCategories?.count / itemCount));
      }
  }, [categoryResponse.allCategories]);

  function handlePageChange (selectedObject) {
    localStorage.removeItem('currentPage');
    setcurrentPage(selectedObject.selected);
    dispatch(getAllCategories({
      page: currentPage + 1,
      limit: itemCount,
    }))
    
  };
  const handleItemsChange = (event) => {
    setItemCount(event.target.value);
    setcurrentPage(0);
  };
   function handleAdd(){
    localStorage.setItem('currentPage',currentPage);
    navigate("/account/category/add")
   }

  function goToEditPage(item) {
    localStorage.setItem('currentPage',currentPage);
    navigate("/account/category/edit", { state: { item } })
  };
  ////////////////////-----------Delete the category-------------///////////////////////////////////
  const editDeleteResponse = useSelector((state) => state.blogAdmin);
  const [deltId, setDeleteId] = useState(-2);

  const onDelete = (id, value) => {
    confirmAlert({
      title: value ? 'Confirm to deactivate' : 'Confirm to reactivate',
      message: value ? 'Are you sure you want to deactivate this category?' : 'Are you sure you want to reactivate this category?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            setDeleteId(value);
            dispatch(getCategoryById(id));
          }
        },
        {
          label: 'No'
        }
      ]
    });
  }
  useEffect(() => {
    if (editDeleteResponse.actionName == 'getCategoryById' && editDeleteResponse.editCategory != null && editDeleteResponse.success==true) {
      ini(editDeleteResponse.editCategory.selectedCat, deltId);
    }
  }, [editDeleteResponse]);

  const ini = (ss, value) => {
    if (value != -2) {
      const data = { ...ss };
      data.isDelete = value;
      dispatch(addUpdateCategory({data}));
    }
  }
  useEffect(() => {
    if (
      editDeleteResponse.success === true && 
      editDeleteResponse.actionName === "addUpdateCategory"
    ) {
      dispatch(resetCategoryAdmin());
      let successMessage =
        editDeleteResponse.editCategory.selectedCat.id !== ""
          ? "Category updated successfully"
          : "Category created successfully";
      notify(successMessage, "success");
      dispatch(getAllCategories({
        page: currentPage + 1,
        limit: itemCount,
      })
      );
      
    }
    if (editDeleteResponse.error) {
      notify(editDeleteResponse.error, "error");
    }
  }, [editDeleteResponse]);


  ////////////////////----------Delete the category-------------///////////////////////////////////

  return (
    <AccountLayout>
      <div className="right-column">
        <div className="edit-outer p-4">
          <div className="edit-inner-left">
            <form>
            <div style={{ justifyContent: "space-between", display: "flex" }}>
              <h3>All Categories</h3>
              <div className="text-endtext-end pe-4">
                <Button onClick={handleAdd} className="btn orange-btn">
                  Add New
                </Button>
              </div>
            </div>
            <div className="table-responsive">
              <table className="table blog-table table-striped">
                <thead>
                  <tr>
                    <th colSpan="1">Name</th>
                    <th>Description</th>
                    <th>Type</th>
                    <th >Status</th>
                    <th >Action</th>
                  </tr>
                </thead>
                <tbody>
                  {categories &&
                    categories.map((item, index) => (
                      <tr key={index}>
                        <td>{item.name}</td>
                        <td>
                          {item.description}
                        </td>
                        <td>
                          {item.type}
                        </td>
                        <td>
                          {item.isDelete == false ? 'Active' : 'Inactive'}
                        </td>
                        <td>
                          <a onClick={() => goToEditPage(item)} title="Edit Category"><i className="fa-regular fa-pen-to-square mr-2"></i></a>
                          {item.isDelete == false ? <a className="ms-3" title="Deactivate" onClick={e => onDelete(item.id, true)}><i className="fa-regular fa-trash-can mr-2" value={item.id}></i></a>
                            : <a className="ms-3" title="Reactivate" onClick={e => onDelete(item.id, false)}><i className="fas fa-trash-restore"></i></a>}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
              <nav className="pagination-block">
                <p className="total">
                      {categoryResponse?.allCategories?.count} items total
                    </p>

                <ReactPaginate
                  previousLabel={"previous"}
                  nextLabel={"next"}
                  breakLabel={"..."}
                  pageCount={pageCount}
                  onPageChange={handlePageChange}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={3}
                  forcePage={currentPage}
                  containerClassName={"pagination justify-content-center mt-4"}
                  pageClassName={"page-item"}
                  pageLinkClassName={"page-link"}
                  previousClassName={"page-item"}
                  previousLinkClassName={"page-link"}
                  nextClassName={"page-item"}
                  nextLinkClassName={"page-link"}
                  breakClassName={"page-item"}
                  breakLinkClassName={"page-lin k"}
                  activeClassName={"active"}
                />
                <div className="customSelect">
                  <label htmlFor="">Items per page :</label>
                  <div className="selectBox">
                    <select name="" id="" onChange={handleItemsChange}>
                      <option value={CONSTANT.PAGE_LIMIT}>
                        {CONSTANT.PAGE_LIMIT}
                      </option>
                      <option value="15">15</option>
                      <option value="20">20</option>
                    </select>
                  </div>
                </div>
              </nav>
            </div>
            </form>
          </div>
        </div>
      </div>
    </AccountLayout>
  );
}