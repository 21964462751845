import React,{useEffect} from "react";
import { DefaultLayout } from "../layouts/defaultLayout";
import { Link, useSearchParams } from "react-router-dom";
import ReactGA from "react-ga4";
export const Sorry = () => {
    const [searchParams] = useSearchParams();
    const referenceNumber = searchParams.get("ref");
    useEffect(() => {
        ReactGA.event({
            category: "order",
            action: "order failed",
            lable:"order"
          });
      }, []);
    return (
        <DefaultLayout>
            <div className="thanku-wrap">
                <h2 style={{ color: "#d03939" }}><span className="fa-solid fa-circle-xmark error"></span>
                    <br />
                    Payment Failed!.
                </h2>
                <p style={{ color: "green" }}>Don't worry your money is safe.</p>
                <p>If money was debited from your account, it will be refunded automatically in 5-7 working days.
                </p>
                <span><b>Payment Reference Id :</b> {referenceNumber}</span>
                <Link to="/" className="orange-btn">
                    Go Home
                </Link>
            </div>
        </DefaultLayout>
    );
};
