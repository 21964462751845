import React, { useCallback, useEffect, useState } from "react";
import { AccountLayout } from "../accountLayout";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { addUpdateAddress } from "../../../../features/user/action";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { notify } from "../../../../features/notification/notify";
import $ from "jquery";
import { v1 as uuidv1 } from 'uuid';
import { resetBranding } from "../../../../features/accountAdmin/brand/slice";
import { addUpdateBrand } from "../../../../features/accountAdmin/brand/action";

export const AddUpdateBrand = () => {
  const dispatch = useDispatch();
  const { state } = useLocation();
  const navigate = useNavigate();
  const response = useSelector((state) => state.branding);
  const [formTitle, setFormTitle] = useState("Add");
  const [brand, setBrand] = useState({
    id: 0,
    companyName: "",
    companyLogo: { file: null, fileName: '' },
    bannerVideo: { file: null, fileName: '' },
    seasonText: "",
    firstImage: { file: null, fileName: '' },
    firstImageText: "",
    secondImage: { file: null, fileName: '' },
    secondImageText: "",
    thirdImage: { file: null, fileName: '' },
    thirdImageText: "",
    aboutHeading: "",
    aboutText: "",
    firstBoxText: "",
    firstBoxValue: "",
    secondBoxText: "",
    secondBoxValue: "",
    thirdBoxText: "",
    thirdBoxValue: "",
    fourthBoxText: "",
    fourthBoxValue: "",
  });



  useEffect(() => {
    if (state) {
      setFormTitle("Edit");
      setBrand({
        ...brand,
        id: state.brand.id,
        companyName: state.brand.companyName,
        companyLogo: { ...brand.companyLogo, file: null, fileName: state.brand.companyLogo},
        bannerVideo: { ...brand.bannerVideo, file: null, fileName: state.brand.bannerVideo},
        seasonText: state.brand.seasonText,
        firstImage: { ...brand.firstImage, file: null, fileName: state.brand.firstImage},
        firstImageText: state.brand.firstImageText,
        secondImage: { ...brand.secondImage, file: null, fileName: state.brand.secondImage},
        secondImageText: state.brand.secondImageText,
        thirdImage: { ...brand.thirdImage, file: null, fileName: state.brand.thirdImage},
        thirdImageText: state.brand.thirdImageText,
        aboutHeading: state.brand.aboutHeading,
        aboutText: state.brand.aboutText,
        firstBoxText: state.brand.firstBoxText,
        firstBoxValue: state.brand.firstBoxValue,
        secondBoxText: state.brand.secondBoxText,
        secondBoxValue: state.brand.secondBoxValue,
        thirdBoxText: state.brand.thirdBoxText,
        thirdBoxValue: state.brand.thirdBoxValue,
        fourthBoxText: state.brand.fourthBoxText,
        fourthBoxValue: state.brand.fourthBoxValue,
        email : state.brand.emailId
      });
    } else {
      navigate("/account/brand/add");
    }
  }, []);



  const handleChange = (e) => {
    if (e.target.name == 'companyLogo'
      || e.target.name == 'bannerVideo'
      || e.target.name == 'firstImage'
      || e.target.name == 'secondImage'
      || e.target.name == 'thirdImage') {
      const acceptedImgFormats = ["png", "jpeg", "jpg", "webp"];
      const fileExtension = e.target.files[0]?.name.split(".").pop().toLowerCase();
      if (e.target.name != 'bannerVideo' && !acceptedImgFormats.includes(fileExtension)) {
        $("#" + e.target.name).val(null);
        return;
      }
      if (e.target.name == 'bannerVideo' && !e.target.files[0].type.includes("video")) {
        $("#" + e.target.name).val(null);
        return;
      }
      var image = e.target.files[0];
      var blob = image.slice(0, image.size);
      const id = uuidv1();
      const newImageName = e.target.name + '-' + id + '.' + fileExtension;
      setBrand({
        ...brand,
        [e.target.name]: {
          ...brand[e.target.name],
          file: new File([blob], newImageName),
          fileName: newImageName
        },
      });
    } else {
      setBrand({
        ...brand,
        [e.target.name]: e.target.value,
      });
    }
  };



  const brandSchema = Yup.object().shape({
    companyName: Yup.string().required("Required").max(100),
    companyLogo: Yup.object().shape({
      fileName: Yup.string().required('Required')
    }),
    bannerVideo: Yup.object().shape({
      fileName: Yup.string().required('Required')
    }),
    seasonText: Yup.string().required("Required").max(100),
    firstImage: Yup.object().shape({
      fileName: Yup.string().required('Required')
    }),
    firstImageText: Yup.string().required("Required").max(100),
    secondImage: Yup.object().shape({
      fileName: Yup.string().required('Required')
    }),
    secondImageText: Yup.string().required("Required").max(100),
    thirdImage: Yup.object().shape({
      fileName: Yup.string().required('Required')
    }),
    thirdImageText: Yup.string().required("Required").max(100),
    aboutHeading: Yup.string().required("Required").max(100),
    aboutText: Yup.string().required("Required").max(2000),
    firstBoxText: Yup.string().required("Required").max(100),
    firstBoxValue: Yup.string().required("Required").max(100),
    secondBoxText: Yup.string().required("Required").max(100),
    secondBoxValue: Yup.string().required("Required").max(100),
    thirdBoxText: Yup.string().required("Required").max(100),
    thirdBoxValue: Yup.string().required("Required").max(100),
    fourthBoxText: Yup.string().required("Required").max(100),
    fourthBoxValue: Yup.string().required("Required").max(100)
  });

  const handleCancel = () => {
    navigate("/account/brand");
  };

  const removeFile = (fieldName) => {
    setBrand({
      ...brand,
      [fieldName]: {
        ...brand[fieldName],
        fileName: '',
        file: null
      },
    });
  }

  const handleFormSubmit = (data) => {

    let payload = new FormData();
    payload.append("content", JSON.stringify(brand));
    payload.append("userId", JSON.stringify(localStorage.getItem("userId")));
    if(brand.companyLogo.file != null)
    payload.append("upload_file", brand.companyLogo.file);
    if(brand.bannerVideo.file != null)
    payload.append("upload_file", brand.bannerVideo.file);
    if(brand.firstImage.file != null)
    payload.append("upload_file", brand.firstImage.file);
    if(brand.secondImage.file != null)
    payload.append("upload_file", brand.secondImage.file);
    if(brand.thirdImage.file != null)
    payload.append("upload_file", brand.thirdImage.file);
    dispatch(addUpdateBrand(payload));
  };

  useEffect(() => {
    if (response.actionName == "addUpdateBrand") {
      dispatch(resetBranding());
      if (response.success) {
        if (response.message == 'success') {
          notify("Added successfully!", "success");
          navigate("/account/brand");
        }
        else
          notify(response.message, "error");

      } else notify(response.error, "error");
    }
  }, [response]);

  return (
    <AccountLayout>
      <div className="right-column">
        <div className="edit-outer p-4">
          <div className="edit-inner-left">
            <Formik
              initialValues={brand}
              validationSchema={brandSchema}
              enableReinitialize={true}
              onSubmit={(values) => {
                handleFormSubmit(values);
              }}
            >
              {({ errors, touched, handleBlur }) => (
                <>
                  <Form>
                    <h3>{formTitle} Brand</h3>
                    <div className="row">
                      <div className="row row-gap-3">
                        <div className="col-md-4 position-relative">
                          <label className="form-label">Company Name</label>
                          <input
                            type="text"
                            name="companyName"
                            value={brand.companyName ?? ""}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className="form-control"
                          />
                          {errors.companyName && touched.companyName ? (
                            <div className="invalid-feedback d-block">
                              {errors.companyName}
                            </div>
                          ) : null}
                        </div>
                        <div className="col-md-4 position-relative">
                          <label className="form-label">Company Logo</label>
                          {brand.companyLogo.fileName == '' && <div> <input
                            type="file"
                            name="companyLogo"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className="form-control"
                            accept="image/*"
                            id='companyLogo'
                          />
                            {errors.companyLogo && touched.companyLogo ? (
                              <div className="invalid-feedback d-block">
                                {errors.companyLogo.fileName}
                              </div>
                            ) : null} </div>}
                          {brand.companyLogo.fileName != '' && <div className="dv-flenme"><p >{brand.companyLogo.fileName} </p><i onClick={(e) => removeFile('companyLogo')} className="fa fa-trash"></i></div>}

                        </div>
                        <div className="col-md-4 position-relative">
                          <label className="form-label">Banner Video</label>
                          {brand.bannerVideo.fileName == '' && <div><input
                            type="file"
                            name="bannerVideo"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className="form-control"
                            accept="video/*"
                            id='bannerVideo'
                          />

                            {errors.bannerVideo && touched.bannerVideo ? (
                              <div className="invalid-feedback d-block">
                                {errors.bannerVideo.fileName}
                              </div>
                            ) : null} </div>}
                          {brand.bannerVideo.fileName != '' && <div className="dv-flenme"><p >{brand.bannerVideo.fileName} </p><i onClick={(e) => removeFile('bannerVideo')} className="fa fa-trash"></i></div>}
                        </div>
                        <div className="col-md-4 position-relative">
                          <label className="form-label">Season Text</label>
                          <input
                            type="text"
                            name="seasonText"
                            value={brand.seasonText ?? ""}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className="form-control"
                          />
                          {errors.seasonText && touched.seasonText ? (
                            <div className="invalid-feedback d-block">
                              {errors.seasonText}
                            </div>
                          ) : null}
                        </div>
                        <div className="col-md-4 position-relative">
                          <label className="form-label">First Image</label>
                          {brand.firstImage.fileName == '' && <div> <input
                            type="file"
                            name="firstImage"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className="form-control"
                            accept="image/*"
                            id='firstImage'
                          />

                            {errors.firstImage && touched.firstImage ? (
                              <div className="invalid-feedback d-block">
                                {errors.firstImage.fileName}
                              </div>
                            ) : null} </div>}
                          {brand.firstImage.fileName != '' && <div className="dv-flenme"><p >{brand.firstImage.fileName} </p><i onClick={(e) => removeFile('firstImage')} className="fa fa-trash"></i></div>}

                        </div>
                        <div className="col-md-4 position-relative">
                          <label className="form-label">First Image Text</label>
                          <input
                            type="text"
                            name="firstImageText"
                            value={brand.firstImageText ?? ""}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className="form-control"
                          />
                          {errors.firstImageText && touched.firstImageText ? (
                            <div className="invalid-feedback d-block">
                              {errors.firstImageText}
                            </div>
                          ) : null}
                        </div>
                        <div className="col-md-4 position-relative">
                          <label className="form-label">Second Image</label>
                          {brand.secondImage.fileName == '' && <div> <input
                            type="file"
                            name="secondImage"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className="form-control"
                            accept="image/*"
                            id='secondImage'
                          />
                            {errors.secondImage && touched.secondImage ? (
                              <div className="invalid-feedback d-block">
                                {errors.secondImage.fileName}
                              </div>
                            ) : null}
                          </div>}
                          {brand.secondImage.fileName != '' && <div className="dv-flenme"><p >{brand.secondImage.fileName} </p><i onClick={(e) => removeFile('secondImage')} className="fa fa-trash"></i></div>}

                        </div>
                        <div className="col-md-4 position-relative">
                          <label className="form-label">Second Image Text</label>
                          <input
                            type="text"
                            name="secondImageText"
                            value={brand.secondImageText ?? ""}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className="form-control"
                          />
                          {errors.secondImageText && touched.secondImageText ? (
                            <div className="invalid-feedback d-block">
                              {errors.secondImageText}
                            </div>
                          ) : null}
                        </div>
                        <div className="col-md-4 position-relative">
                          <label className="form-label">Third Image</label>
                          {brand.thirdImage.fileName == '' && <div> <input
                            type="file"
                            name="thirdImage"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className="form-control"
                            accept="image/*"
                            id='thirdImage'
                          />
                            {errors.thirdImage && touched.thirdImage ? (
                              <div className="invalid-feedback d-block">
                                {errors.thirdImage.fileName}
                              </div>
                            ) : null} </div>}
                          {brand.thirdImage.fileName != '' && <div className="dv-flenme"><p >{brand.thirdImage.fileName} </p><i onClick={(e) => removeFile('thirdImage')} className="fa fa-trash"></i></div>}

                        </div>
                        <div className="col-md-4 position-relative">
                          <label className="form-label">Third Image Text</label>
                          <input
                            type="text"
                            name="thirdImageText"
                            value={brand.thirdImageText ?? ""}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className="form-control"
                          />
                          {errors.thirdImageText && touched.thirdImageText ? (
                            <div className="invalid-feedback d-block">
                              {errors.thirdImageText}
                            </div>
                          ) : null}
                        </div>
                        <div className="col-md-4 position-relative">
                          <label className="form-label">About Heading</label>
                          <input
                            type="text"
                            name="aboutHeading"
                            value={brand.aboutHeading ?? ""}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className="form-control"
                          />
                          {errors.aboutHeading && touched.aboutHeading ? (
                            <div className="invalid-feedback d-block">
                              {errors.aboutHeading}
                            </div>
                          ) : null}
                        </div>
                        <div className="col-md-4 position-relative">
                          <label className="form-label">About Text</label>
                          <input
                            type="text"
                            name="aboutText"
                            value={brand.aboutText ?? ""}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className="form-control"
                          />
                          {errors.aboutText && touched.aboutText ? (
                            <div className="invalid-feedback d-block">
                              {errors.aboutText}
                            </div>
                          ) : null}
                        </div>
                        <div className="col-md-4 position-relative">
                          <label className="form-label">First Box Text</label>
                          <input
                            type="text"
                            name="firstBoxText"
                            value={brand.firstBoxText ?? ""}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className="form-control"
                          />
                          {errors.firstBoxText && touched.firstBoxText ? (
                            <div className="invalid-feedback d-block">
                              {errors.firstBoxText}
                            </div>
                          ) : null}
                        </div>
                        <div className="col-md-4 position-relative">
                          <label className="form-label">First Box Value</label>
                          <input
                            type="text"
                            name="firstBoxValue"
                            value={brand.firstBoxValue ?? ""}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className="form-control"
                          />
                          {errors.firstBoxValue && touched.firstBoxValue ? (
                            <div className="invalid-feedback d-block">
                              {errors.firstBoxValue}
                            </div>
                          ) : null}
                        </div>
                        <div className="col-md-4 position-relative">
                          <label className="form-label">Second Box Text</label>
                          <input
                            type="text"
                            name="secondBoxText"
                            value={brand.secondBoxText ?? ""}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className="form-control"
                          />
                          {errors.secondBoxText && touched.secondBoxText ? (
                            <div className="invalid-feedback d-block">
                              {errors.secondBoxText}
                            </div>
                          ) : null}
                        </div>
                        <div className="col-md-4 position-relative">
                          <label className="form-label">Second Box Value</label>
                          <input
                            type="text"
                            name="secondBoxValue"
                            value={brand.secondBoxValue ?? ""}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className="form-control"
                          />
                          {errors.secondBoxValue && touched.secondBoxValue ? (
                            <div className="invalid-feedback d-block">
                              {errors.secondBoxValue}
                            </div>
                          ) : null}
                        </div>
                        <div className="col-md-4 position-relative">
                          <label className="form-label">Third Box Text</label>
                          <input
                            type="text"
                            name="thirdBoxText"
                            value={brand.thirdBoxText ?? ""}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className="form-control"
                          />
                          {errors.thirdBoxText && touched.thirdBoxText ? (
                            <div className="invalid-feedback d-block">
                              {errors.thirdBoxText}
                            </div>
                          ) : null}
                        </div>
                        <div className="col-md-4 position-relative">
                          <label className="form-label">Third Box Value</label>
                          <input
                            type="text"
                            name="thirdBoxValue"
                            value={brand.thirdBoxValue ?? ""}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className="form-control"
                          />
                          {errors.thirdBoxValue && touched.thirdBoxValue ? (
                            <div className="invalid-feedback d-block">
                              {errors.thirdBoxValue}
                            </div>
                          ) : null}
                        </div>
                        <div className="col-md-4 position-relative">
                          <label className="form-label">Fourth Box Text</label>
                          <input
                            type="text"
                            name="fourthBoxText"
                            value={brand.fourthBoxText ?? ""}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className="form-control"
                          />
                          {errors.fourthBoxText && touched.fourthBoxText ? (
                            <div className="invalid-feedback d-block">
                              {errors.fourthBoxText}
                            </div>
                          ) : null}
                        </div>
                        <div className="col-md-4 position-relative">
                          <label className="form-label">Fourth Box Value</label>
                          <input
                            type="text"
                            name="fourthBoxValue"
                            value={brand.fourthBoxValue ?? ""}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className="form-control"
                          />
                          {errors.fourthBoxValue && touched.fourthBoxValue ? (
                            <div className="invalid-feedback d-block">
                              {errors.fourthBoxValue}
                            </div>
                          ) : null}
                        </div>
                        <div className="col-md-4 position-relative">
                          <label className="form-label">Contact us email</label>
                          <input
                            type="text"
                            name="email"
                            value={brand.email ?? ""}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className="form-control"
                          />
                          {errors.email && touched.email ? (
                            <div className="invalid-feedback d-block">
                              {errors.email}
                            </div>
                          ) : null}
                        </div>

                      </div>
                      <div className="text-start py-5">
                        <button type="submit" className="btn orange-btn">
                          Save
                        </button>{" "}
                        &nbsp;
                        <button
                          className="btn orange-cancel-btn"
                          onClick={handleCancel}
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </Form>
                </>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </AccountLayout>
  );
};
