import { API_URLS } from "../../utils/api.urls";
import { createAsyncThunk } from "@reduxjs/toolkit";

let myHeaders = new Headers();
myHeaders.append('Content-Type', 'application/json');

 //read action
 export const getProducts = createAsyncThunk(
    "getProducts", async (data, { rejectWithValue }) => {
        try {
           let url = API_URLS.PRODUCT.GET_LIST;
           let category =  data.category ?  "/" + data.category  : "/"
           let page =  data.page ? "/" + data.page : "/1";
           let limit =  data.limit ? "/" + data.limit : "/10";
           let sort =  data.sort ? "/" + data.sort : "/new";
           let season =  data.season ? "/" + data.season : "/";
           url = url + category + page + limit + sort + season;
            const response = await fetch(url);
            if (!response.ok) return rejectWithValue(response.statusText)
            const result = await response.json();
            return result;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const getAllProducts = createAsyncThunk(
  "getAllProducts", async (data, { rejectWithValue }) => {
      try {
          let url = API_URLS.ADMIN.GET_ALL_LIST;
          let page =  data.page ? "/" + data.page : "/1";
          let limit =  data.limit ? "/" + data.limit : "/10";
          let status =  data.status ? "/" + data.status : "/All";
          url = url + page + limit + status;
          const response = await fetch(url);
          if (!response.ok) return rejectWithValue(response.statusText)
          const result = await response.json();
          return result;
      } catch (error) {
          return rejectWithValue(error);
      }
  }
);
export const getCategories = createAsyncThunk(
    "getCategories", async (data, { rejectWithValue }) => {
        try {
            let url = API_URLS.PRODUCT.GET_CATEGORIES

            const response = await fetch(url);
            if (!response.ok) return rejectWithValue(response.statusText)
            const result = await response.json();
            return result;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);
export const getAllCategories = createAsyncThunk(
  "getAllCategories", async (data, { rejectWithValue }) => {
      try {
          let url = API_URLS.ADMIN.GET_All_CATEGORIES + "/?";
          let page =  data.page ?  "page=" + data.page : "page=1";
          let limit =  data.limit ?  "&pageSize=" + data.limit : "&pageSize=100";
          const response = await fetch(url + page + limit);
          if (!response.ok) return rejectWithValue(response.statusText)
          const result = await response.json();
          return result;  
      } catch (error) {
          return rejectWithValue(error);
      }
  }
);

export const processOrder = createAsyncThunk(
  'processOrder',
  async (post, { rejectWithValue }) => {
    try {

      const response = await fetch(
        API_URLS.PRODUCT.PROCESS_ORDER,
         {
           method: "POST",
           headers: myHeaders,
           body: JSON.stringify(post),
         }
       );
      if (!response.ok) return rejectWithValue(response.statusText)
      const data = await response.json()
      return data
    } catch (err) {
      // You can choose to use the message attached to err or write a custom error
      return rejectWithValue(err)
    }
  }
)

export const processPayment = createAsyncThunk(
  'processPayment',
  async (post, { rejectWithValue }) => {
    try {

      const response = await fetch(
        API_URLS.PAYMENT.PROCESS_PAYMENT,
         {
           method: "POST",
           headers: myHeaders,
           body: JSON.stringify(post),
         }
       );
      if (!response.ok) return rejectWithValue(response.statusText)
      const data = await response.json()
      return data
    } catch (err) {
      // You can choose to use the message attached to err or write a custom error
      return rejectWithValue(err)
    }
  }
)

export const getCartBadge = createAsyncThunk(
    "getCartBadge", async (data, { rejectWithValue }) => {
        try {
            let url = API_URLS.PRODUCT.GET_CART_BADGE + "/?userid=" + data

            const response = await fetch(url);
            if (!response.ok) return rejectWithValue(response.statusText)
            const result = await response.json();
            return result;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const createCartData = createAsyncThunk(
    'createCartData',
    async (post, { rejectWithValue }) => {
      try {
  
        const response = await fetch(
          API_URLS.PRODUCT.ADD_TO_CART,
           {
             method: "POST",
             headers: myHeaders,
             body: JSON.stringify(post),
           }
         );
        if (!response.ok) return rejectWithValue(response.statusText)
        const data = await response.json()
        return data
      } catch (err) {
        // You can choose to use the message attached to err or write a custom error
        return rejectWithValue(err)
      }
    })

export const updateCartQuantity = createAsyncThunk(
    'updateCartQuantity',
    async (post, { rejectWithValue }) => {
      try {
  
        const response = await fetch(
          API_URLS.PRODUCT.UPDATE_CART_QTY,
           {
             method: "POST",
             headers: myHeaders,
             body: JSON.stringify(post),
           }
         );
        if (!response.ok) return rejectWithValue(response.statusText)
        const data = await response.json()
        return data
      } catch (err) {
        // You can choose to use the message attached to err or write a custom error
        return rejectWithValue(err)
      }
    })

export const removeProductFromCart = createAsyncThunk(
    'removeProductFromCart',
    async (post, { rejectWithValue }) => {
      try {
  
        const response = await fetch(
          API_URLS.PRODUCT.REMOVE_FROM_CART,
           {
             method: "POST",
             headers: myHeaders,
             body: JSON.stringify(post),
           }
         );
        if (!response.ok) return rejectWithValue(response.statusText)
        const data = await response.json()
        return data
      } catch (err) {
        // You can choose to use the message attached to err or write a custom error
        return rejectWithValue(err)
      }
    })

export const saveForLater = createAsyncThunk(
    'saveForLater',
    async (post, { rejectWithValue }) => {
      try {
  
        const response = await fetch(
          API_URLS.PRODUCT.SAVE_FOR_LATER,
           {
             method: "POST",
             headers: myHeaders,
             body: JSON.stringify(post),
           }
         );
        if (!response.ok) return rejectWithValue(response.statusText)
        const data = await response.json()
        return data
      } catch (err) {
        // You can choose to use the message attached to err or write a custom error
        return rejectWithValue(err)
      }
    })


     //read action
 export const getOrders = createAsyncThunk(
    "getOrders", async (data, { rejectWithValue }) => {
        try {
            let url = API_URLS.PRODUCT.GET_ORDERS 
            const response = await fetch(url);
            if (!response.ok) return rejectWithValue(response.statusText)
            const result = await response.json();
            return result;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);
export const addProduct = createAsyncThunk(
    'addProduct',
    async (payload, { rejectWithValue }) => {
      try {
  
        const response = await fetch(
          API_URLS.PRODUCT.ADD_PRODUCT,
           {
             method: "POST",
             body: payload,
           }
         );
        if (!response.ok) return rejectWithValue(response.statusText)
        const data = await response.json()
        return data
      } catch (err) {
        // You can choose to use the message attached to err or write a custom error
        return rejectWithValue(err)
      }
    })
    export const getProductById = createAsyncThunk(
      "getProductById", async (data, { rejectWithValue }) => {
          try {
              let url = API_URLS.PRODUCT.GET_PRODUCTBYID + "/" + data
  
              const response = await fetch(url);
              if (!response.ok) return rejectWithValue(response.statusText)
              const result = await response.json();
              return result;
          } catch (error) {
              return rejectWithValue(error);
          }
      }
  );

  export const changeProductStatus = createAsyncThunk(
    'changeProductStatus',
    async (payload, { rejectWithValue }) => {
      try {
  
        const response = await fetch(
          API_URLS.PRODUCT.CHANGE_PRODUCT_STATUS,
           {
             method: "POST",
             headers: myHeaders,
             body: JSON.stringify(payload),
           }
         );
        if (!response.ok) return rejectWithValue(response.statusText)
        const data = await response.json()
        return data
      } catch (err) {
        // You can choose to use the message attached to err or write a custom error
        return rejectWithValue(err)
      }
    })