import React, { useEffect, useRef, useState } from "react";
import { AccountLayout } from "../accountLayout";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { CONSTANT } from "../../../../utils/constant";
import ReactPaginate from "react-paginate";
import { notify } from "../../../../features/notification/notify";
import {
  getAllQueries,
  updateQuery,
  sendReplyMail,
} from "../../../../features/accountAdmin/customerQuery/action";
import { resetCustomerQuery } from "../../../../features/accountAdmin/customerQuery/slice";
import { Modal } from "react-bootstrap";
import { Form, Formik } from "formik";
import { ToastContainer, toast } from "react-toastify";

export const QueryList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { state } = useLocation();
  const [queryList, setQueryList] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [itemCount, setItemCount] = useState(CONSTANT.PAGE_LIMIT);
  const [currentPage, setcurrentPage] = useState(0);
  const isFirstRender = useRef(true);
  const [selectedQuery, setSelectedQuery] = useState({});
  const [viewQueryModal, setViewQueryModal] = useState(false);
  const [viewQueryReplyModal, setViewQueryReplyModal] = useState(false);
  const [reply, setReply] = useState({
    name: "",
    phone: "",
    email: "",
    comments: "",
    reply: "",
  });
  useEffect(() => {
    callQueryData();
  }, []);

  useEffect(() => {
    if (!isFirstRender.current) {
      callQueryData();
    }
    isFirstRender.current = false;
  }, [currentPage, itemCount]);

  const callQueryData = () => {
    dispatch(
      getAllQueries({
        page: currentPage + 1,
        limit: itemCount,
      })
    );
  };

  const queryResponse = useSelector((state) => state.customerQuery);
  useEffect(() => {
    if (queryResponse.actionName == "getAllQueries") {
      dispatch(resetCustomerQuery());
      queryResponse?.queryList?.rows &&
        setQueryList(queryResponse?.queryList?.rows);

      if (queryResponse?.queryList?.count !== null && pageCount === 0) {
        setPageCount(Math.ceil(queryResponse?.queryList?.count / itemCount));
      }
    }
    if (queryResponse.actionName == "updateQuery") {
      if (queryResponse.success) callQueryData();
    }
    if (
      queryResponse.success == true &&
      queryResponse.actionName == "sendReplyMail"
    ) {
      toast.success("Reply sent to customer.");
    }
  }, [queryResponse]);

  const handlePageChange = (selectedObject) => {
    setcurrentPage(selectedObject.selected);
  };
  const handleItemsChange = (event) => {
    setItemCount(event.target.value);
    setcurrentPage(0);
  };

  const handleChange = (e) => {
    setReply({
      ...reply,
      [e.target.name]: e.target.value,
    });
  };

  const handleFormSubmit = (data) => {
    const replyData = {
      name:reply.name,
      phone:reply.phone,
      email:reply.email,
      comments:reply.comments,
      reply:data.reply
    }
    dispatch(sendReplyMail(replyData));
    setViewQueryReplyModal(false);
  };

  const viewQuery = (props, index) => {
    setSelectedQuery(props);
    setViewQueryModal(true);
    dispatch(updateQuery(props.id));
  };
  const replyQuery = (props, index) => {
    setReply(props);
    setViewQueryReplyModal(true);
    dispatch(updateQuery(props.id));
  };
  const handleCloseModal = () => {
    setViewQueryModal(false);
  };
  const handleCloseReplyModal = () => {
    setViewQueryReplyModal(false);
  };

  return (
    <AccountLayout>
      <div className="right-column">
        <div className="edit-outer p-4">
          <div className="edit-inner-left">
            <div style={{ justifyContent: "space-between", display: "flex" }}>
              <h3>Customer Query</h3>
              <div className="text-endtext-end pe-4"></div>
            </div>
            <div className="table-responsive">
              <table className="table blog-table table-striped">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Phone</th>
                    <th colSpan="3">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {queryList &&
                    queryList.map((item, index) => (
                      <tr key={index}>
                        <td>{item.name}</td>
                        <td>{item.email}</td>
                        <td>{item.phone}</td>
                        <td>
                          <a>
                            <i
                              className="fa-regular fa-eye"
                              onClick={(e) => viewQuery(item, index)}
                            ></i>
                          </a>{" "}
                          &nbsp;
                          <a onClick={(e) => replyQuery(item, index)}>
                            <i class="fa-solid fa-reply"></i>
                          </a>{" "}
                          &nbsp;
                          {item.isNew && (
                            <small className="sml-new-qry">New*</small>
                          )}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
              <nav className="pagination-block">
                <p className="total">
                  {queryResponse?.queryList?.count} items total
                </p>

                <ReactPaginate
                  previousLabel={"previous"}
                  nextLabel={"next"}
                  breakLabel={"..."}
                  pageCount={pageCount}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={3}
                  forcePage={currentPage}
                  onPageChange={handlePageChange}
                  containerClassName={"pagination justify-content-center mt-4"}
                  pageClassName={"page-item"}
                  pageLinkClassName={"page-link"}
                  previousClassName={"page-item"}
                  previousLinkClassName={"page-link"}
                  nextClassName={"page-item"}
                  nextLinkClassName={"page-link"}
                  breakClassName={"page-item"}
                  breakLinkClassName={"page-link"}
                  activeClassName={"active"}
                />
                <div className="customSelect">
                  <label htmlFor="">Items per page :</label>
                  <div className="selectBox">
                    <select name="" id="" onChange={handleItemsChange}>
                      <option value={CONSTANT.PAGE_LIMIT}>
                        {CONSTANT.PAGE_LIMIT}
                      </option>
                      <option value="15">15</option>
                      <option value="20">20</option>
                    </select>
                  </div>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </div>
      <Modal show={viewQueryModal} className="custom-modal pin-modal">
        <Modal.Body>
          <div>
            <h5>Customer Query</h5>
          </div>
          <hr />
          <div className="container dv-qry-mdl">
            <div className="row">
              <div className="col-md-4">
                <label className="form-label">Name: &nbsp;</label>
              </div>
              <div className="col-md-8">
                <p>{selectedQuery.name} </p>
              </div>
            </div>

            <div className="row">
              <div className="col-md-4">
                <label className="form-label">Phone: &nbsp;</label>
              </div>
              <div className="col-md-8">
                <p>{selectedQuery.phone} </p>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4">
                <label className="form-label">Email: &nbsp;</label>&nbsp;
              </div>
              <div className="col-md-8">
                <p>{selectedQuery.email} </p>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4">
                <label className="form-label">Comments: </label> &nbsp;
              </div>
              <div className="col-md-8">
                <p>{selectedQuery.comments} </p>
              </div>
            </div>
          </div>
          <hr />
          <div className="dv-mdl-cls">
            <button
              type="button"
              className="btn cancel"
              onClick={handleCloseModal}
            >
              Close
            </button>
          </div>
        </Modal.Body>
      </Modal>
      <Modal show={viewQueryReplyModal} className="custom-modal pin-modal">
        <Modal.Body>
          <div>
            <h5>Customer Query Reply</h5>
          </div>
          <hr />
          <div className="container dv-qry-mdl">
           
                    <div className="row">
                      <div className="row row-gap-3">
                        <div className="col-md-3 position-relative">
                          <label className="form-label">Name:-</label>
                        </div>
                        <div className="col-md-8 position-relative">
                          <p>{reply.name}</p>
                        </div>
                      </div>
                      <div className="row row-gap-3">
                        <div className="col-md-3 position-relative">
                          <label className="form-label">Phone:-</label>
                        </div>
                        <div className="col-md-8 position-relative">
                        <p>{reply.phone}</p>
                        </div>
                      </div>
                      <div className="row row-gap-3">
                        <div className="col-md-3 position-relative">
                          <label className="form-label">Email:-</label>
                        </div>
                        <div className="col-md-8 position-relative">
                        <p>{reply.email}</p>
                        </div>
                      </div>
                      <div className="row row-gap-3">
                        <div className="col-md-3 position-relative">
                          <label className="form-label">Comments:-</label>
                        </div>
                        <div className="col-md-8 position-relative">
                        <p>{reply.comments}</p>
                        </div>
                      </div>
                      <Formik
              initialValues={reply}
              enableReinitialize={true}
              onSubmit={(values) => {
                console.log("viewvalues", values);
                handleFormSubmit(values);
              }}
            >
              {({ errors, touched, handleBlur }) => (
                <>
                  <Form>
                      <div className="row row-gap-3">
                        <div className="col-md-3 position-relative">
                          <label className="form-label">Reply:-</label>
                        </div>
                        <div className="col-md-8 position-relative">
                          <textarea
                            name="reply"
                            value={reply.reply ?? ""}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className="form-control"
                          />
                          {errors.comment && touched.comment ? (
                            <div className="invalid-feedback d-block">
                              {errors.comment}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <hr />
                    <div className="text-start py-8" style={{marginLeft:"65px"}}>
                      <button type="submit" className="btn orange-btn">
                        Send
                      </button>{" "}
                      <button
                      type="button"
                      className="btn orange-cancel-btn"
                      onClick={handleCloseReplyModal}
                    >
                      Cancel
                    </button>
                    </div>
                    </Form>
                     </>
                     )}
                   </Formik>
                    </div>
                 
                    
               
               
          </div>
        </Modal.Body>
      </Modal>
    </AccountLayout>
  );
};
