import { createSlice } from "@reduxjs/toolkit";
import {userLoginValidate, userGoogleLogin} from "../login/action";


export const userlogin = createSlice({
  name: "userlogin",
  initialState: {
    email : null,
    password : null,
    loading: false,
    error: null,
    success: false,
    data : null,
    actionName : null
  },

  reducers: {
    resetLogin: (state, action) => {
      state.success = false
      state.error = null
    },
  },

  extraReducers: {
    [userLoginValidate.pending]: (state) => {
        state.loading = true;
        state.success = false;
      },
      [userLoginValidate.fulfilled]: (state, action) => {
        state.loading = false;
        state.data = action.payload.user;
        localStorage.setItem("token", action.payload.token)
        localStorage.setItem("userData",JSON.stringify(action.payload.user))
        localStorage.setItem("userRoles",JSON.stringify(action.payload.roles))
        localStorage.setItem("userId",JSON.stringify(action.payload.user.id))
        state.actionName = "email login"
        state.success = true;
      },
      [userLoginValidate.rejected]: (state, action) => {
        console.log("error", action)
        state.loading = false;
        state.error = "Invalid user";
      } ,
      [userGoogleLogin.pending]: (state) => {
        state.loading = true;
        state.success = false;
      },
      [userGoogleLogin.fulfilled]: (state, action) => {
        state.loading = false;
        state.data = action.payload.user;
        localStorage.setItem("token", action.payload.token)
        localStorage.setItem("userData",JSON.stringify(action.payload.user))
        localStorage.setItem("userId",action.payload.user.id)
        state.actionName = "google login"
        state.success = true;
      },
      [userGoogleLogin.rejected]: (state, action) => {
        console.log("error", action)
        state.loading = false;
        state.error = "Invalid user";
      }     
  },
});

export default userlogin.reducer;
export const { resetLogin } = userlogin.actions;


