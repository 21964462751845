import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DefaultLayout } from "../../layouts/defaultLayout";
import { getOrders } from "../../../features/product/action";
export const MyOrders = () => {
    const orderResponse = useSelector((state) => state.orders);
    const [orderList, setOrderList] = useState([]);
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getOrders())
      }, []);

      useEffect(() => {
        if (orderResponse?.actionName == 'getOrders')
        orderResponse.orders && setOrderList(orderResponse.orders);
      }, [orderResponse]);

    return (
        <DefaultLayout>
            <div style={{marginTop:'250px', textAlign:'center', marginBottom:'250px'}}>
                This is order page
            </div>
            {orderList.length > 0 && orderList.map((item,index) => {
                 <div>{item.ordernumber}</div>
             })
               
            }
        </DefaultLayout>
    );
}