import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URLS } from "../../../utils/api.urls";

let myHeaders = new Headers();
myHeaders.append('Content-Type', 'application/json');

export const getAllBrands = createAsyncThunk(
    "getAllBrands", async (data, { rejectWithValue }) => {
        try {
            let url = API_URLS.ADMIN.GET_ALL_BRANDS + "/?";
            let page =  data.page ?  "page=" + data.page : "page=1";
            let limit =  data.limit ?  "&pageSize=" + data.limit : "&pageSize=10";
            const response = await fetch(url + page + limit);
            if (!response.ok) return rejectWithValue(response.statusText)
            const result = await response.json();
            return result;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

//read action
export const addUpdateBrand = createAsyncThunk(
    "addUpdateBrand", async (payload, { rejectWithValue }) => {
        try {
            const response = await fetch(
                API_URLS.ADMIN.ADD_UPDATE_BRAND,
                {
                    method: "POST",
                    body: payload,
                }
            );
            if (!response.ok) return rejectWithValue(response.statusText)
            const result = await response.json();
            return result;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);