
import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URLS } from "../../utils/api.urls";



let myHeaders = new Headers();
myHeaders.append('Content-Type', 'application/json');



export const updateUser = createAsyncThunk(
    'updateUser',
    async (post, { rejectWithValue }) => {
      try {
  
        const response = await fetch(
          API_URLS.UPDATE_USER,
           {
             method: "POST",
             headers: myHeaders,
             body: JSON.stringify(post),
           }
         );
        if (!response.ok) return rejectWithValue(response.statusText)
        const data = await response.json()
        return data
      } catch (err) {
        // You can choose to use the message attached to err or write a custom error
        return rejectWithValue(err)
      }
    }
  )

export const getProfileData = createAsyncThunk(
    'getProfileData',
    async (post, { rejectWithValue }) => {
      try {
  
        const response = await fetch(
          API_URLS.PROFILE,
           {
             method: "POST",
             headers: myHeaders,
             body: JSON.stringify(post),
           }
         );
        if (!response.ok) return rejectWithValue(response.statusText)
        const data = await response.json()
        return data
      } catch (err) {
        // You can choose to use the message attached to err or write a custom error
        return rejectWithValue(err)
      }
    }
  )

export const getProfileAddress = createAsyncThunk(
    'getProfileAddress',
    async (post, { rejectWithValue }) => {
      try {
  
        const response = await fetch(
          API_URLS.PROFILE_ADDRESS,
           {
             method: "POST",
             headers: myHeaders,
             body: JSON.stringify(post),
           }
         );
        if (!response.ok) return rejectWithValue(response.statusText)
        const data = await response.json()
        return data
      } catch (err) {
        // You can choose to use the message attached to err or write a custom error
        return rejectWithValue(err)
      }
    }
  )

export const getAddressById = createAsyncThunk(
    'getAddressById',
    async (post, { rejectWithValue }) => {
      try {
  
        const response = await fetch(
          API_URLS.ADDRESS_DATA,
           {
             method: "POST",
             headers: myHeaders,
             body: JSON.stringify(post),
           }
         );
        if (!response.ok) return rejectWithValue(response.statusText)
        const data = await response.json()
        return data
      } catch (err) {
        // You can choose to use the message attached to err or write a custom error
        return rejectWithValue(err)
      }
    }
  )

export const getWishList = createAsyncThunk(
    'getWishList',
    async (data, { rejectWithValue }) => {
      try {
        let url = API_URLS.GET_WISHLIST + "/" + data;
        const response = await fetch(url);
        if (!response.ok) return rejectWithValue(response.statusText)
        const result = await response.json();
        return result;
    } catch (error) {
        return rejectWithValue(error);
    }
    }
  )

export const getUserOrders = createAsyncThunk(
    'getUserOrders',
    async (data, { rejectWithValue }) => {
      try {
        let search = data.searchText == '' ? 'all': data.searchText
        let url = API_URLS.GET_ORDERS + "/" + data.userId + "/" + data.role + "/" + search;
        const response = await fetch(url);
        if (!response.ok) return rejectWithValue(response.statusText)
        const result = await response.json();
        return result;
    } catch (error) {
        return rejectWithValue(error);
    }
    }
  )

export const updateWishList = createAsyncThunk(
    'updateWishList',
    async (post, { rejectWithValue }) => {
      try {
  
        const response = await fetch(
          API_URLS.UPDATE_WISHLIST,
           {
             method: "POST",
             headers: myHeaders,
             body: JSON.stringify(post),
           }
         );
        if (!response.ok) return rejectWithValue(response.statusText)
        const data = await response.json()
        return data
      } catch (err) {
        // You can choose to use the message attached to err or write a custom error
        return rejectWithValue(err)
      }
    }
  )

export const addUpdateAddress = createAsyncThunk(
    'addUpdateAddress',
    async (post, { rejectWithValue }) => {
      try {
  
        const response = await fetch(
          API_URLS.ADD_UPDATE_ADDRESS,
           {
             method: "POST",
             headers: myHeaders,
             body: JSON.stringify(post),
           }
         );
        if (!response.ok) return rejectWithValue(response.statusText)
        const data = await response.json()
        return data
      } catch (err) {
        // You can choose to use the message attached to err or write a custom error
        return rejectWithValue(err)
      }
    }
  )
export const setDefaultAddress = createAsyncThunk(
    'setDefaultAddress',
    async (post, { rejectWithValue }) => {
      try {
  
        const response = await fetch(
          API_URLS.SET_DEFAULT_ADDRESS,
           {
             method: "POST",
             headers: myHeaders,
             body: JSON.stringify(post),
           }
         );
        if (!response.ok) return rejectWithValue(response.statusText)
        const data = await response.json()
        return data
      } catch (err) {
        // You can choose to use the message attached to err or write a custom error
        return rejectWithValue(err)
      }
    }
  )

  export const deleteAddress = createAsyncThunk(
    'deleteAddress',
    async (post, { rejectWithValue }) => {
      try {
  
        const response = await fetch(
          API_URLS.DELETE_ADDRESS,
           {
             method: "POST",
             headers: myHeaders,
             body: JSON.stringify(post),
           }
         );
        if (!response.ok) return rejectWithValue(response.statusText)
        const data = await response.json()
        return data
      } catch (err) {
        // You can choose to use the message attached to err or write a custom error
        return rejectWithValue(err)
      }
    }
  )
  export const contactUs = createAsyncThunk(
    'contactUs',
    async (post, { rejectWithValue }) => {
      try {
  
        const response = await fetch(
          API_URLS.CONTACT_US,
           {
             method: "POST",
             headers: myHeaders,
             body: JSON.stringify(post),
           }
         );
        if (!response.ok) return rejectWithValue(response.statusText)
        const data = await response.json()
        return data
      } catch (err) {
        // You can choose to use the message attached to err or write a custom error
        return rejectWithValue(err)
      }
    }
  )

  export const getBrandByName = createAsyncThunk(
    'getBrandByName',
    async (data, { rejectWithValue }) => {
      try {
        let url = API_URLS.GET_BRANDBY_NAME + "/" + data;
        const response = await fetch(url);
        if (!response.ok) return rejectWithValue(response.statusText)
        const result = await response.json();
        return result;
    } catch (error) {
        return rejectWithValue(error);
    }
    }
  )

  export const updateOrderStatus = createAsyncThunk(
    'updateOrderStatus',
    async (post, { rejectWithValue }) => {
      try {
  
        const response = await fetch(
          API_URLS.UPDATE_ORDER_STATUS,
           {
             method: "POST",
             headers: myHeaders,
             body: JSON.stringify(post),
           }
         );
        if (!response.ok) return rejectWithValue(response.statusText)
        const data = await response.json()
        return data
      } catch (err) {
        // You can choose to use the message attached to err or write a custom error
        return rejectWithValue(err)
      }
    }
  )