import React, { useEffect, useState } from "react";
import { DefaultLayout } from "../layouts/defaultLayout";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { ImageSlider } from "./products/imageSlider";
import { getWishList, updateWishList } from "../../features/user/action";

export const WishList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const wishlistResponse = useSelector((state) => state.user);

  const [wishlist, setWishlist] = useState([]);
  let userId = localStorage.getItem("userId") 
  useEffect(() => {
    dispatch(getWishList(userId));
  }, []);
  const handleProductClick = (wishlist) => {
    navigate("/product-details/" + wishlist.Product.Category.name.toLowerCase() + "/" + wishlist.Product.name, {
      state: { product: wishlist.Product },
    });
  };

  useEffect(() => {
    wishlistResponse.wishlist && setWishlist(wishlistResponse.wishlist);
    console.log(wishlistResponse, "wishlistResponse");

    if(wishlistResponse.success === true && wishlistResponse.actionName === 'updateWishList') {
      dispatch(getWishList(userId));
    }
  }, [wishlistResponse]);

  const handleRemoveWishList = (productId) => {
    let payload = {
      "userId": userId,
      "productId" : productId,
      "add" : false
    }
    dispatch(updateWishList(payload))
  }

  return (
    <DefaultLayout>
      <section className="section latest-section latest-banner">
        <div className="container">
          <div className="top-content">
            <h1>Wishlist</h1>
          </div>

          <section className="you-may-like">
            <div className="container-fluid">
              <div className="wishlist-row row row-gap-4">
                {wishlist &&
                  wishlist.map((wishlist, index) => (
                    <div className="col-sm-6 col-md-4 col-xl-3" key={index}>
                      <div className="product-col">
                        <div className="product-col-inner">
                          <a onClick={() => handleProductClick(wishlist)}>
                            {wishlist.Product.ProductImages.length > 0 && (
                              <ImageSlider images={wishlist.Product.ProductImages} />
                            )}
                          </a>
                          <div
                            id="dv-overlay"
                            style={{ pointerEvents: "none" }}
                            className="product-overlay dv-ovrly"
                          >
                            <a onClick={() => handleProductClick(wishlist.Product)}>
                              View
                            </a>
                          </div>
                        </div>
                        <a onClick={() => handleProductClick(wishlist.Product)}>
                          <h3>{wishlist.Product.name}</h3>
                        </a>
                        <span className="price">
                          ₹{parseInt(wishlist.Product.price).toFixed(2)}
                        </span>
                      </div>
                      <div class="itemcard-actionDiv">
                                <a onClick={()=>handleRemoveWishList(wishlist.Product.id)}>REMOVE</a>
                            </div>
                    </div>
                  ))}
              </div>
            </div>
          </section>
        </div>
      </section>
    </DefaultLayout>
  );
};
