import React, { useCallback, useEffect, useState } from "react";
import { AccountLayout } from "../accountLayout";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getAddressById,
  addUpdateAddress,
} from "../../../../features/user/action";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { notify } from "../../../../features/notification/notify";
import { resetUser } from "../../../../features/user/slice";
import { City, Country, State } from "country-state-city";
import $ from "jquery";

export const AddEditAddress = () => {
  const dispatch = useDispatch();
  const { state } = useLocation();
  const navigate = useNavigate();
  const addressResponse = useSelector((state) => state.user);
  const [formTitle, setFormTitle] = useState("Add");
  const [address, setAddress] = useState({
    addressId: "",
    addressName: "",
    streetAddress1: "",
    streetAddress2: "",
    city: "",
    state: "",
    country: "India",
    zip: "",
    landmark: "",
    addressType: "",
    userId: "",
  });
  
  const countries = Country.getAllCountries();
  const [countryCode, setCountryCode] = useState();
  const [states, setStates] = useState();
//   const [cities, setCities] = useState();

  useEffect(() => {
    if (addressResponse.actionName == "addUpdateAddress") {
      dispatch(resetUser());
      if (addressResponse.success) {
        notify("Address Added successfully!", "success");
        navigate("/account/address");
      } else notify(addressResponse.error, "error");
    }
  }, [addressResponse]);

  useEffect(() => {
    if (state) {
      setFormTitle("Edit");
      setAddress({
        ...address,
        addressId: state.address.id,
        addressName: state.address.addressName,
        streetAddress1: state.address.streetAddress1,
        streetAddress2: state.address.streetAddress2,
        phone:state.address.phone,
        city: state.address.city,
        state: state.address.state,
        country: state.address.country,
        zip: state.address.zip,
        landmark: state.address.landmark,
        addressType: state.address.addressType,
        userId: state.address.userId,
      });
    } else {
      navigate("/account/address/add");
    }
    // default state 
    getStates('IN');
  }, []);


  const handleCountryChange = (e) => {
    let code = $(e.target).find(":selected").attr("data-code");
    getStates(code);
    // setCountryCode(code)
    setAddress({
      ...address,
      country: e.target.value,
    });
  };

  const handleStateChange = (e) => {
    let stateCode = $(e.target).find(":selected").attr("data-code");
    // getCities(countryCode, stateCode);
    setAddress({
      ...address,
      state: e.target.value,
    });
  };

  const getStates = (countryCode) => {
    setStates(State.getStatesOfCountry(countryCode));
  };

//   const getCities = (countryCode, stateCode) => {
//     setCities(City.getCitiesOfState(countryCode, stateCode));
//   };

  const handleChange = (e) => {
    setAddress({
      ...address,
      [e.target.name]: e.target.value,
    });
  };

  const handleFormSubmit = (data) => {
    const userId = localStorage.getItem("userId");
    data.userId = userId;
    if (state) {
      const addId = state.address.id;
      data.addressId = addId;
    }
    dispatch(addUpdateAddress({ data }));
  };
  const addressSchema = Yup.object().shape({
    addressName: Yup.string().required("Required").max(100),
    streetAddress1: Yup.string().required("Required").max(100),
    streetAddress2: Yup.string().required("Required").max(100),
    phone: Yup.string().required("Required").max(10).min(10),
    city:Yup.string().required("Required"),
    state:Yup.string().required("Required"),
    zip: Yup.string().required("Required").min(6).max(6),
    landmark: Yup.string().required("Required").max(100),
  });

  const handleCancel = () => {
    navigate("/account/address");
  };

  return (
    <AccountLayout>
      <div className="right-column">
        <div className="edit-outer p-4">
          <div className="edit-inner-left">
            <Formik
              initialValues={address}
              validationSchema={addressSchema}
              enableReinitialize={true}
              onSubmit={(values) => {
                console.log("viewvalues", values);
                handleFormSubmit(values);
              }}
            >
              {({ errors, touched, handleBlur }) => (
                <>
                  <Form>
                    <h3>{formTitle} Address</h3>
                    <div className="row">
                      <div className="row row-gap-3">
                        <div className="col-md-6 position-relative">
                          <label className="form-label">Name</label>
                          <input
                            type="text"
                            name="addressName"
                            value={address.addressName ?? ""}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className="form-control"
                          />
                          {errors.addressName && touched.addressName ? (
                            <div className="invalid-feedback d-block">
                              {errors.addressName}
                            </div>
                          ) : null}
                        </div>
                        <div className="col-md-6 position-relative">
                          <label className="form-label">Phone</label>
                          <input
                            type="text"
                            name="phone"
                            value={address.phone ?? ""}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className="form-control"
                          />
                          {errors.phone && touched.phone ? (
                            <div className="invalid-feedback d-block">
                              {errors.phone}
                            </div>
                          ) : null}
                        </div>
                        <div className="col-md-6 position-relative">
                          <label className="form-label">Address1</label>
                          <input
                            type="text"
                            name="streetAddress1"
                            value={address.streetAddress1 ?? ""}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className="form-control"
                          />
                          {errors.streetAddress1 && touched.streetAddress1 ? (
                            <div className="invalid-feedback d-block">
                              {errors.streetAddress1}
                            </div>
                          ) : null}
                        </div>
                        <div className="col-md-6 position-relative">
                          <label className="form-label">Address2</label>
                          <input
                            type="text"
                            name="streetAddress2"
                            value={address.streetAddress2 ?? ""}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className="form-control"
                          />
                          {errors.streetAddress2 && touched.streetAddress2 ? (
                            <div className="invalid-feedback d-block">
                              {errors.streetAddress2}
                            </div>
                          ) : null}
                        </div>
                        <div className="col-md-6 position-relative">
                          <label className="form-label">Country</label>
                          <select
                            className="form-select"
                            name="country"
                            value={address.country}
                            onChange={handleCountryChange}
                          >
                            {countries &&
                              countries.map((country) => (
                                <option
                                  data-code={country.isoCode}
                                  value={country.name}
                                >
                                  {country.name}
                                </option>
                              ))}
                          </select>
                          
                        </div>
                        <div className="col-md-6 position-relative">
                          <label className="form-label">State</label>
                          <select className="form-select" 
                            name="state"
                            value={address.state}
                            onChange={handleStateChange}
                         >
                          <option value="">Select State</option>
                          {states &&
                              states.map((state) => (
                                <option
                                  data-code={state.isoCode}
                                  value={state.name}
                                >
                                  {state.name}
                                </option>
                              ))}
                          </select>
                          {errors.state && touched.state ? (
                            <div className="invalid-feedback d-block">
                              {errors.state}
                            </div>
                          ) : null}
                        </div>
                        <div className="col-md-6 position-relative">
                          <label className="form-label">City</label>
                          <input
                            type="text"
                            name="city"
                            value={address.city ?? ""}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className="form-control"
                          />
                          {errors.city && touched.city ? (
                            <div className="invalid-feedback d-block">
                              {errors.city}
                            </div>
                          ) : null}
                        </div>
                        <div className="col-md-6 position-relative">
                          <label className="form-label">Zip</label>
                          <input
                            type="text"
                            name="zip"
                            value={address.zip ?? ""}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className="form-control"
                          />
                          {errors.zip && touched.zip ? (
                            <div className="invalid-feedback d-block">
                              {errors.zip}
                            </div>
                          ) : null}
                        </div>
                        <div className="col-md-6 position-relative">
                          <label className="form-label">Landmark</label>
                          <input
                            type="text"
                            name="landmark"
                            value={address.landmark ?? ""}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className="form-control"
                          />
                          {errors.landmark && touched.landmark ? (
                            <div className="invalid-feedback d-block">
                              {errors.landmark}
                            </div>
                          ) : null}
                        </div>
                        <div className="col-md-12 position-relative">
                          <label className="form-label">Save Address As</label>
                          <div className="save-address-as">
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="address-as"
                                id="one"
                                checked
                              />
                              <label className="form-check-label" htmlFor="one">
                                Home
                              </label>
                            </div>
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="address-as"
                                id="two"
                              />
                              <label className="form-check-label" htmlFor="two">
                                Work
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="text-start py-5">
                        <button type="submit" className="btn orange-btn">
                          Save
                        </button>{" "}
                        &nbsp;
                        <button
                          className="btn orange-cancel-btn"
                          onClick={handleCancel}
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </Form>
                </>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </AccountLayout>
  );
};
