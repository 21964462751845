import React, { useEffect } from 'react'
import { NavLink } from 'react-router-dom';
import { Header } from '../../layouts/header';
import $ from "jquery";

export const AccountLayout = (props) => {
  const { children } = props;
  let role = "";
  let token = localStorage.getItem('token');
  let userRoles = localStorage.getItem('userRoles');
  if (token !== null && token !== "undefined") {
    userRoles = JSON.parse(userRoles);
    role = userRoles?.role && userRoles?.role;
  }

  useEffect(() => {
    $('header').addClass("position-static");

  }, [])

  return (
    <>
      <Header />
      <div className="dashboard-outer">
        <div className="dashboard-outer-inner">
          <div className="left-fixed-menu">
            <div className="options">
              <h3>overview</h3>
            </div>
            <div className="options">
              <h3>Accounts</h3>
              <NavLink to="/account/profile" className="profile">
                Profile
              </NavLink>
              <NavLink to="/account/address">Addresses</NavLink>
              <NavLink to="/account/change-password">Change Password</NavLink>
            </div>
            <div className="options">
              <h3>Orders</h3>
              <NavLink to="/account/orders">Orders & Returns</NavLink>
            </div>

            {/* //----------------admin menu start-------------- */}
            {
              role == 'Admin' && (
                <>
                  <div className="options">
                    <h3>Admin Section</h3>
                  </div>
                  <div className="options">
                    <h3>Product</h3>
                    <NavLink to="/account/product/add">Add Product</NavLink>
                    <NavLink to="/account/product-bulkupload">Bulk Upload</NavLink>
                    <NavLink to="/account/product/list">Product list</NavLink>
                  </div>
                  <div className="options">
                    <h3>Blog</h3>
                    <NavLink to="/account/blog">Blogs</NavLink>
                    <NavLink to="/account/blog/add">Add Blog</NavLink>
                  </div>
                  <div className="options">
                    <h3>Event</h3>
                    <NavLink to="/account/event">Events</NavLink>
                    <NavLink to="/account/event/add">Add Event</NavLink>
                  </div>
                  <div className="options">
                    <h3>Customer</h3>
                    <NavLink to="/account/customer/query">Queries</NavLink>
                  </div>
                  <div className="options">
                    <h3>Site Settings</h3>
                    <NavLink to="/account/brand">Brands</NavLink>
                    <NavLink to="/account/brand/add">Add Brand</NavLink>
                    <NavLink to="/account/category/list">Manage Categories</NavLink>
                  </div>
                </>
              )
            }
          </div>
          {children}
        </div>
      </div>
    </>

  )
}
