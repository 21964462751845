import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URLS } from "../../../utils/api.urls";

let myHeaders = new Headers();
myHeaders.append('Content-Type', 'application/json');

export const addUpdateEvent = createAsyncThunk(
    'addUpdateEvent',
    async ( post, { rejectWithValue }) => {
        try {

            const response = await fetch(
                API_URLS.ADMIN.ADD_UPDATE_EVENT,
                {
                    method: "POST",
                    body: post, //form form data
                }
            );
            if (!response.ok) return rejectWithValue(response.statusText)
            const data = await response.json()
            return data
        } catch (err) {
            // You can choose to use the message attached to err or write a custom error
            return rejectWithValue(err)
        }
    }
)



  
export const updateEventStatus = createAsyncThunk(
    'updateEventStatus',
    async (post, { rejectWithValue }) => {
      try {
  
        const response = await fetch(
            API_URLS.ADMIN.UPDATE_EVENT_STATUS,
           {
             method: "POST",
             headers: myHeaders,
             body: JSON.stringify(post),
           }
         );
        if (!response.ok) return rejectWithValue(response.statusText)
        const data = await response.json()
        return data
      } catch (err) {
        // You can choose to use the message attached to err or write a custom error
        return rejectWithValue(err)
      }
    }
  )

  export const deleteEvent = createAsyncThunk(
    'deleteEvent',
    async (post, { rejectWithValue }) => {
      try {
  
        const response = await fetch(
            API_URLS.ADMIN.DELETE_EVENT,
           {
             method: "POST",
             headers: myHeaders,
             body: JSON.stringify(post),
           }
         );
        if (!response.ok) return rejectWithValue(response.statusText)
        const data = await response.json()
        return data
      } catch (err) {
        // You can choose to use the message attached to err or write a custom error
        return rejectWithValue(err)
      }
    }
  )


//read action
export const getEvents = createAsyncThunk(
    "getEvents", async (data, { rejectWithValue }) => {
        try {
            let url = API_URLS.ADMIN.GET_EVENTS + "/?";
            let page =  data.page ?  "page=" + data.page : "page=1";
            let limit =  data.limit ?  "&pageSize=" + data.limit : "&pageSize=10";
            const response = await fetch(url + page + limit);
            if (!response.ok) return rejectWithValue(response.statusText)
            const result = await response.json();
            return result;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const getAllEvents = createAsyncThunk(
    "getAllEvents", async (data, { rejectWithValue }) => {
        try {
            let url = API_URLS.ADMIN.GET_ALL_EVENTS + "/?";
            let page =  data.page ?  "page=" + data.page : "page=1";
            let limit =  data.limit ?  "&pageSize=" + data.limit : "&pageSize=10";
            const response = await fetch(url + page + limit);
            if (!response.ok) return rejectWithValue(response.statusText)
            const result = await response.json();
            return result;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

//read action
export const getEventById = createAsyncThunk(
    "getEventById", async (data, { rejectWithValue }) => {
        try {
            let url = API_URLS.ADMIN.GET_EVENT_BY_ID + "/" + data;
            const response = await fetch(url);
            if (!response.ok) return rejectWithValue(response.statusText)
            const result = await response.json();
            return result;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

