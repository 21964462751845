import React from "react";
import { DefaultLayout } from "../layouts/defaultLayout";

export const AboutUs = () => {
  return (
    <>
      <DefaultLayout>
        <section className="about-banner">
          <div className="container">
            <h1>Elevating Fashion & Design to New Heights</h1>
          </div>
        </section>

        <section className="section team">
          <div className="container">
            <h2>About Groyyo Design Studio </h2>
            <p className="top-para">
              Groyyo Design Studio offers a one-stop solution for fashion and
              lifestyle design needs. We understand the importance of embracing
              the evolution of fashion and reviving classic designs with a
              modern twist. Our team combines timeless beauty with contemporary
              elements to create unique and innovative designs.{" "}
            </p>

            <div className="row justify-content-center mt-5 row-gap-4">
              <div className="col-lg-3 col-md-4 col-sm-6">
                <div className="inner">
                  <h3>Launched In</h3>
                  <span>2021</span>
                </div>
              </div>
              <div className="col-lg-3 col-md-4 col-sm-6">
                <div className="inner">
                  <h3>Team</h3>
                  <span>100+</span>
                </div>
              </div>
              <div className="col-lg-3 col-md-4 col-sm-6">
                <div className="inner">
                  <h3>Factories</h3>
                  <span>360+</span>
                </div>
              </div>
              <div className="col-lg-3 col-md-4 col-sm-6">
                <div className="inner">
                  <h3>Brands</h3>
                  <span>110+</span>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="section">
          <div className="container">
            <h2>In The News</h2>
            <div className="row mt-5 align-items-center row-gap-4 justify-content-center">
              <div className="col-lg-2 col-md-3 col-6">
                <img src="images/news/1.jpg" alt="news" />
              </div>
              <div className="col-lg-2 col-md-3 col-6">
                <img src="images/news/2.jpg" alt="news" />
              </div>
              <div className="col-lg-2 col-md-3 col-6">
                <img src="images/news/3.jpg" alt="news" />
              </div>
              <div className="col-lg-2 col-md-3 col-6">
                <img src="images/news/4.jpg" alt="news" />
              </div>
              <div className="col-lg-2 col-md-3 col-6">
                <img src="images/news/5.jpg" alt="news" />
              </div>
              <div className="col-lg-2 col-md-3 col-6">
                <img src="images/news/6.jpg" alt="news" />
              </div>
            </div>
          </div>
        </section>
      </DefaultLayout>
    </>
  );
};
