import React, { useCallback, useEffect, useState } from "react";
import { AccountLayout } from "../accountLayout";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { deleteAddress, getProfileAddress, setDefaultAddress } from "../../../../features/user/action";
import { resetUser } from "../../../../features/user/slice";
import { notify } from "../../../../features/notification/notify";
import { confirmAlert } from 'react-confirm-alert';

export const Address = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { state } = useLocation();
  const addressResponse = useSelector((state) => state.user);
  const [addresses, setAddresses] = useState({});
  const userId = localStorage.getItem("userId");
  console.log(userId);
  useEffect(() => {
    dispatch(getProfileAddress({ userId: userId }));
  }, []);

  useEffect(() => {
    dispatch(resetUser());
    addressResponse.address && setAddresses(addressResponse.address);

    console.log("addressResponse", addressResponse);
    if (addressResponse.success === true) {
      if (addressResponse.actionName == 'setDefaultAddress') {
        notify('Address set to default', 'success');
      }
      else if (addressResponse.actionName == 'deleteAddress') {
        notify('Address deleted successfully.', 'success');
      }
      dispatch(getProfileAddress({ userId: userId }));
    }
  }, [addressResponse]);

  const handelEdit = (address) => {
    navigate("/account/address/edit", { state: { address } })
  };

  const handleDefault = (address) => {
    let payload = {
      userId: userId,
      addressId: address.id
    }
    dispatch(setDefaultAddress(payload));
  };

  const handleDelete = (address) => {
    confirmAlert({
      title: 'Confirm to delete',
      message: 'Are you sure you want to delete this address?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            let payload = {
              addressId: address.id
            }
            dispatch(deleteAddress(payload));
          }
        },
        {
          label: 'No'
        }
      ]
    });
  };

  return (
    <AccountLayout>
      <div className="right-column">
        <div className="edit-outer p-4">
          <div className="edit-inner-left">
            <div style={{ justifyContent: "space-between", display: "flex" }}>
              <h3>All Addresses</h3>
              <div className="text-endtext-end pe-4 mb-3">
                <Link to="/account/address/add" className="btn orange-btn">
                  Add New
                </Link>
              </div>
            </div>
            {addresses.length > 0 &&
              addresses.map((address, index) => (
                <div className="all-order-wrap">
                  <div className="all-order-list">
                    <div className="order-status">
                      <i className="fa-solid fa-home"></i>
                      <div>
                        <span className="status delivered">
                          {address.addressType}
                        </span>
                      </div>
                    </div>
                    <div className="all-order-list-inner">
                      <div className="product-list-thumbnail">
                        <div>
                          <div className="brand-name">
                            {" "}
                            {address.addressName}
                          </div>
                          <div className="product-desc">
                            <span>{address.streetAddress1}</span>

                            <span>{address.streetAddress2}</span>

                            <span>{address.landmark}</span>

                            <span>{address.city}</span>

                            <span>{address.state}</span>

                            <span>{address.zip}</span>
                          </div>
                        </div>
                      </div>
                      <div className="order-footer">
                        <div className="order-btns">
                          <button
                            onClick={() => {
                              handelEdit(address);
                            }}
                            className="btn"
                          >
                            Edit
                          </button>
                          {
                            address.flag === true ?
                              <button
                                className="btn"
                                disabled='disabled'
                                style={{color:"green"}}
                              >
                                Default
                              </button>
                              : <button
                                onClick={() => {
                                  handleDefault(address);
                                }}
                                className="btn"
                              >
                                Set default
                              </button>
                          }
                          <button
                            onClick={() => {
                              handleDelete(address);
                            }}
                            className="btn"
                          >
                            Remove
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </AccountLayout>
  );
};
