import React, { useEffect, useState } from "react";
import { DefaultLayout } from "../layouts/defaultLayout";
import { useDispatch, useSelector } from "react-redux";
import { contactUs } from "../../features/user/action";
export const ContactUs = () => {

  const [contactForm, setContactForm] = useState({ name: '', email: '', phone: '', comments: '' })
  const response = useSelector((state) => state);
  const dispatch = useDispatch();
  const handleChange = (e) => {
    const temp = [...contactForm]
    temp[e.target.name] = e.target.value
    setContactForm(temp)
  }
  const handleSubmit =() => {
    dispatch(contactUs(contactForm))
  }

  useEffect(() => {
    if(response.actionName == "contactUs")
    {
      alert('hello')
    }else{
      alert('error')
    }
  },[response])
  return (
    <>
      <DefaultLayout>
        <section className="contact-banner">
          <div className="container">
            <h1>Contact Us</h1>
            <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit.</p>
          </div>
        </section>
        <section className="section form-section">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <h2 className="text-start">Get In Touch With Us</h2>
                <p>
                  Need To Speak with Someone? Our Customer Loyalty Team is just
                  a call or a click anyway!
                </p>

                <p>
                  <a href="">Phone: (022) 49434146</a>, Mondays to Friday,
                  between 10 am and 7 pm
                </p>
              </div>
              <div className="col-md-6">
                <form >
                  <div className="mb-3">
                    <label className="form-label">
                      Your Name<span className="red-color">*</span>
                    </label>
                    <input type="text" className="form-control" name="name" value={contactForm.name} onChange={handleChange} />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">
                      Your Email<span className="red-color">*</span>
                    </label>
                    <input type="email" className="form-control" name="email" value={contactForm.email} onChange={handleChange} />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Your Phone</label>
                    <input type="text" className="form-control" name="phone" value={contactForm.phone} onChange={handleChange} />
                  </div>

                  <div className="mb-3">
                    <label className="form-label">Your Comments</label>
                    <textarea
                      className="form-control"
                      placeholder="Leave a comment here"
                      style={{ "height": "100px" }}
                      name="comments" value={contactForm.comments} onChange={handleChange}
                    ></textarea>
                  </div>
                  <button type="submit" className="btn" onClick={handleSubmit}>
                    Submit
                  </button>
                </form>
              </div>
            </div>
          </div>
        </section>
        <section className="section contact-info-section">
          <div className="container">
            <div className="text-center">
              <h3>Interested in Ordering in bulk? Get in touch with us.</h3>
              <p>
                Email:<a href=""> marketing@groyyo.com</a>
              </p>
              <p>
                Phone: <a href="">+91 8098720447</a>
              </p>
            </div>
          </div>
        </section>
      </DefaultLayout>
    </>
  );
};
