import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addUpdateCategory, getCategoryById } from "../../../features/accountAdmin/blog/action";
import { getAllCategories } from "../../../features/product/action";
import { resetCategoryAdmin, resetBlogAdmin } from "../../../features/accountAdmin/blog/slice";
import { useLocation, useNavigate } from "react-router";
import { AccountLayout } from "../account/accountLayout";
import { notify } from "../../../features/notification/notify";
import { Form, Formik } from "formik";

export const AddUpdateCategory = () => {
  const dispatch = useDispatch();
  const { state } = useLocation();
  const navigate = useNavigate();
  const [formTitle, setFormTitle] = useState("Add");
  const [categories, setCategories] = useState([]);
  const categoryResponse = useSelector((state) => state.product);


  useEffect(() => {
    dispatch(getAllCategories(0, 100))
  }, []);

  useEffect(() => {
    if (categoryResponse.actionName == 'getAllCategories')
      categoryResponse.allCategories && setCategories(categoryResponse.allCategories);
  }, [categoryResponse]);


  //////////////// ---------------- From here for add Category----------------  /////////////////////////////////////////
  const updateResponse = useSelector((state) => state.blogAdmin);
  const [newCategoryData, newCategory] = useState({
    id: "",
    name: "",
    parentId: 0,
    description: "",
    isDelete: 0,
    type: 'Category'
  });

  useEffect(() => {
    if (state) {
      setFormTitle("Edit");
      newCategory({
        ...newCategoryData,
        id: state.item.id,
        parentId: state.item.parentId,
        name: state.item.name,
        description: state.item.description,
        isDelete: state.item.isDelete,
        state: state.item,
        type: state.item.type,
      });
    } else {
      navigate("/account/category/add");
    }
  }, []);

  const handleChange = (e) => {
    newCategory({
      ...newCategoryData,
      [e.target.name]: e.target.value,
    });
  };

  const handleFormSubmit = (data) => {
    dispatch(addUpdateCategory({ data }));
  };

  useEffect(() => {
    if (
      updateResponse.success === true &&
      updateResponse.actionName === "addUpdateCategory"
    ) {
      dispatch(resetCategoryAdmin());
      let successMessage =
        newCategoryData.id !== ""
          ? "Category updated successfully"
          : "Category created successfully";
      notify(successMessage, "success");

      navigate("/account/category/list");
    }
    if (updateResponse.error) {
      notify(updateResponse.error, "error");
    }
  }, [updateResponse]);
  //////////---------------- To here for add Category-----------------///////////////////////////

  const handleCancel = () => {
    navigate("/account/category/list");
  }
  return (
    <AccountLayout>
      <div className="right-column">
        <div className="edit-outer p-4">
          <div className="edit-inner-left">
            <Formik
              initialValues={newCategoryData}
              enableReinitialize={true}
              onSubmit={(values) => {
                console.log("viewvalues", values);
                handleFormSubmit(values);
              }}
            >
              {({ errors, touched, handleBlur }) => (
                <>
                  <Form>
                    <h3>{formTitle} Category</h3>
                    <div className="row">
                      <div className="col-sm-6"><label className="form-label">Name</label>
                        <input type="text" name="name" value={newCategoryData.name ?? ""} onChange={handleChange} onBlur={handleBlur} className="form-control" />
                        {errors.name && touched.name ? (
                          <div className="invalid-feedback d-block">
                            {errors.name}
                          </div>
                        ) : null}</div>
                      <div className="col-sm-6">
                        <label className="form-label">Description</label>
                        <input type="text" name="description" value={newCategoryData.description ?? ""} onChange={handleChange} onBlur={handleBlur} className="form-control" />
                        {errors.description && touched.description ? (
                          <div className="invalid-feedback d-block">
                            {errors.description}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className="row mt-3">
                      <div className="col-sm-6"><label className="form-label">Parent Category</label>
                        <select className="form-select" name="parentId" value={newCategoryData.parentId ?? ""} onChange={handleChange} >

                          if({newCategoryData.parentId} != null || {newCategoryData.parentId}!="" && {newCategoryData.parentId} != 0){
                            categories?.rows?.map((cat, index) => {
                              if (cat.id == newCategoryData.parentId) {
                                return <option value={cat.id} key={index}> {cat.name} </option>;
                              }

                            })
                          }
                          <option value='' >--Select--</option>
                          {categories?.rows?.map((cat, index) => (
                            <option value={cat.id} key={index} >
                              {cat.name}
                            </option>))}

                        </select></div>
                      <div className="col-sm-6"><label className="form-label">Type</label>
                        <select className="form-select" name="type" value={newCategoryData.type ?? ""} onChange={handleChange} >
                          <option value="Category" >Category</option>
                          <option value="Season" >Season</option>
                        </select></div>
                    </div>
                    {errors.description && touched.description ? (
                      <div className="invalid-feedback d-block">
                        {errors.description}
                      </div>
                    ) : null}
                    <div className="col-md-12">
                      <div className="edit-btn-group">
                        <button className="btn cancel" onClick={handleCancel}>Cancel</button>
                        <button type="submit" className="btn orange-btn">Save</button>
                      </div>
                    </div>
                  </Form>
                </>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </AccountLayout>
  );
}
