import React, { useState, useRef } from "react";
import { AccountLayout } from "../accountLayout";
import axios from "axios";
import { API_URLS } from "../../../../utils/api.urls";

export const BulkUpload = () => {
  const [file, setFile] = useState();
  const [uploadStatus, setUploadStatus] = useState("Uploading");
  const [uploaded, setUploaded] = useState(false);
  const [uploadPercentage, setUploadPercentage] = useState(0);
  const ref = useRef("");
  const removeFile = (e) => {
    ref.current.value = "";
    setFile(null);
  };
  const handleOnChange = (e) => {
    if (e.target.files.length) {
      const inputFile = e.target.files[0];
      setFile(inputFile);
    }
    setUploaded(false);
  };
  const uploadFile = () => {
    let data = new FormData();
    data.append("file", file);
    data.append("fileName", file.name);
    axios.post(API_URLS.PRODUCT.UPLOAD_PRODUCTS, data).then((res) => {
      if (res.status === 200 && res.data.message === "success") {
        removeFile();
        setUploaded(true);
      }
    });
  };

  return (
    <AccountLayout>
      <div className="right-column">
        <div className="edit-outer p-4">
          <div className="edit-inner-left">
            <h3>Bulk Upload Products</h3>

            <div className="row">
              <div className="row row-gap-3">
                <div className="col-md-6">
                  <label className="form-label">Select File</label>
                  <div className="bulk-upload-file">
                    <input
                      className="form-control form-control-lg"
                      type="file"
                      name="bulkProduct"
                      accept={".xlsx"}
                      onChange={handleOnChange}
                      ref={ref}
                    />
                    <a disabled={ref.current.value == ""}
                onClick={removeFile}
                type="button"><i class="fa-regular fa-circle-xmark"></i></a> 
                  </div>
                </div>


              </div>
              <div className="text-start py-5">
              <button
                  disabled={ref.current.value == ""}
                  onClick={uploadFile}
                  className="btn orange-btn"
                >
                  Upload
                </button>
              </div>
            </div>

            {uploaded && (
              <div style={{ textAlign: "center" }}>
                <p
                  style={{ textDecoration: "underline" }}
                  className="text-success"
                >
                  File uploaded!
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </AccountLayout>
  );
};
