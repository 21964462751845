import React, { useEffect, useState, useParams } from "react";
import { useDispatch, useSelector } from "react-redux";
import OTPInput, { ResendOTP } from "otp-input-react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { notify } from "../../../features/notification/notify";
import { otpVerification } from "../../../features/signup/action";

export const OtpSignup = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { state } = useLocation();
  const [otp, setOtp] = useState("");
  const signupResponse = useSelector((state) => state.userSignup);
  const [searchParams] = useSearchParams();

  const handleSubmit = (e) => {
    e.preventDefault();
    const email = searchParams.get("email");
    if (email && otp) {
      let sendData = {
        email: email,
        otp: otp,
      };
      dispatch(otpVerification(sendData));
    } else {
      toast.warn("Unable to fetch email...!!")
    }
  };

  useEffect(() => {
    if (signupResponse.success === true && signupResponse.actionName == "otpVerification") {
      toast.success("OTP Verified successfully");
      navigate("/login");
    }
    if (signupResponse.error) {
      notify(signupResponse.error, "error");
    }
  }, [signupResponse]);

  return (
    <>
      <main>
        <div className="sign-details">
          <div className="sign-wrap">
            <img src="images/logo.png" alt="Logo" className="logo" />
            <div className="form-wrap enter-otp-wrap">
              <h2>Enter OTP to continue</h2>
              {state?.type == "mobile" ? (
                <p className="note">
                  A 4 digit OTP has been sent to your mobile <br /> number{" "}
                  <span className="bold-600">{state?.emailOrPhone}</span>
                </p>
              ) : (
                <p className="note">
                  A 4 digit OTP has been sent to your <br /> email{" "}
                  <span className="bold-600">{state?.emailOrPhone}</span>
                </p>
              )}

              <form>
                <div className="enter-otp-input">
                  <OTPInput
                    className="otp-input-root"
                    value={otp}
                    onChange={setOtp}
                    autoFocus
                    OTPLength={4}
                    otpType="number"
                    disabled={false}
                    secure
                    renderInput={(props) => <input className="form-control" />}
                  />
                </div>
                <button
                  type="submit"
                  className="btn blue-btn"
                  onClick={handleSubmit}
                >
                  Submit
                </button>
              </form>
            </div>
          </div>
        </div>
      </main>
      <ToastContainer />
    </>
  );
};
