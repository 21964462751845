import React, { useEffect, useRef, useState } from "react";
import { AccountLayout } from "../accountLayout";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { CONSTANT } from "../../../../utils/constant";
import ReactPaginate from "react-paginate";
import { notify } from "../../../../features/notification/notify";
import { getAllBrands } from "../../../../features/accountAdmin/brand/action";
import { resetBranding } from "../../../../features/accountAdmin/brand/slice";
import { BASE_URLS } from "../../../../utils/api.urls";

export const BrandList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [brandList, setBrandList] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [itemCount, setItemCount] = useState(CONSTANT.PAGE_LIMIT);
  const [currentPage, setcurrentPage] = useState(0);
  const isFirstRender = useRef(true);

  useEffect(() => {
    callBrandData();
  }, []);

  useEffect(() => {
    if (!isFirstRender.current) {
      callBrandData();
    }
    isFirstRender.current = false;
  }, [currentPage, itemCount]);

  const callBrandData = () => {
    dispatch(
      getAllBrands({
        page: currentPage + 1,
        limit: itemCount,
      })
    );
  };

  const response = useSelector((state) => state.branding);
  useEffect(() => {
    if(response.actionName == 'getAllBrands'){
      dispatch(resetBranding());
    response?.brandList?.rows &&
      setBrandList(response?.brandList?.rows);

    if (response?.brandList?.count !== null && pageCount === 0) {
      setPageCount(Math.ceil(response?.brandList?.count / itemCount));
    }
  }
  }, [response]);

  const handlePageChange = (selectedObject) => {
    setcurrentPage(selectedObject.selected);
  };
  const handleItemsChange = (event) => {
    setItemCount(event.target.value);
    setcurrentPage(0);
  };

  const goToEditPage = (brand) => {
    navigate("/account/brand/edit", {
      replace: true,
      state: { brand: brand },
    });
  };

  // const handleDeleteBlog = (id) => {
  //   dispatch(deleteBlog({
  //     id :id
  //   }))
  // }

  // const handleUpdateBlogStatus = (id, status) => {
  //   let payload = {
  //     id : id,
  //     isPublished : status
  //   }

  //   console.log(payload)
  //   dispatch(updateBlogStatus(payload))
  // }

 
  return (
    <AccountLayout>
      <div className="right-column">
        <div className="edit-outer p-4">
          <div className="edit-inner-left">
            <div style={{ justifyContent: "space-between", display: "flex" }}>
              <h3>Brands</h3>
              <div className="text-endtext-end pe-4">
                <Link to="/account/brand/add" className="btn orange-btn">
                  Add New
                </Link>
              </div>
            </div>
            <div className="table-responsive">
              <table className="table blog-table table-striped">
                <thead>
                  <tr>
                    <th>Company Name</th>
                    <th>Logo</th>
                    <th>Season Text</th>
                    <th>About Heading</th>
                    <th colSpan="3">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {brandList &&
                    brandList.map((item, index) => (
                      <tr key={index}>
                        <td>{item.companyName}</td>
                        <td className="dv-td-img"><img src={ BASE_URLS.BASE_URL + '/upload/brand/' + item.companyLogo} /></td>
                        <td>{item.seasonText}</td>
                        <td>{item.aboutHeading}</td>
                        <td>
                          <a onClick={() => goToEditPage(item)}>
                            <i className="fa-regular fa-pen-to-square"></i>
                          </a>
                        </td>
                        {/* <td>
                          <a onClick={()=>handleDeleteBlog(blog.id)}>
                            <i className="fa-regular fa-trash-can"></i>
                          </a>
                        </td> */}
                      </tr>
                    ))}
                </tbody>
              </table>
              <nav className="pagination-block">
                <p className="total">
                  {response?.brandList?.count} items total
                </p>

                <ReactPaginate
                  previousLabel={"previous"}
                  nextLabel={"next"}
                  breakLabel={"..."}
                  pageCount={pageCount}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={3}
                  forcePage={currentPage}
                  onPageChange={handlePageChange}
                  containerClassName={"pagination justify-content-center mt-4"}
                  pageClassName={"page-item"}
                  pageLinkClassName={"page-link"}
                  previousClassName={"page-item"}
                  previousLinkClassName={"page-link"}
                  nextClassName={"page-item"}
                  nextLinkClassName={"page-link"}
                  breakClassName={"page-item"}
                  breakLinkClassName={"page-link"}
                  activeClassName={"active"}
                />
                <div className="customSelect">
                  <label htmlFor="">Items per page :</label>
                  <div className="selectBox">
                    <select name="" id="" onChange={handleItemsChange}>
                      <option value={CONSTANT.PAGE_LIMIT}>
                        {CONSTANT.PAGE_LIMIT}
                      </option>
                      <option value="15">15</option>
                      <option value="20">20</option>
                    </select>
                  </div>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </AccountLayout>
  );
};
