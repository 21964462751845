
import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URLS } from "../../utils/api.urls";

let myHeaders = new Headers();
myHeaders.append('Content-Type', 'application/json');

export const changePassword = createAsyncThunk(
  'changePassword',
  async (post, { rejectWithValue }) => {
    try {

      const response = await fetch(
        API_URLS.CHANGE_PASSWORD,
         {
           method: "POST",
           headers: myHeaders,
           body: JSON.stringify(post),
         }
       );
      if (!response.ok) return rejectWithValue(response.statusText)
      const data = await response.json()
      return data
    } catch (err) {
      // You can choose to use the message attached to err or write a custom error
      return rejectWithValue(err)
    }
  }
)

export const createForgotPassword = createAsyncThunk(
    'createForgotPassword',
    async (post, { rejectWithValue }) => {
      try {
  
        const response = await fetch(
          API_URLS.FORGOT_PASSWORD,
           {
             method: "POST",
             headers: myHeaders,
             body: JSON.stringify(post),
           }
         );
        if (!response.ok) return rejectWithValue(response.statusText)
        const data = await response.json()
        return data
      } catch (err) {
        // You can choose to use the message attached to err or write a custom error
        return rejectWithValue(err)
      }
    }
  )

export const checkResetPasswordToken = createAsyncThunk(
    'checkResetPasswordToken',
    async (post, { rejectWithValue }) => {
      try {
  
        const response = await fetch(
          API_URLS.RESET_PASSWORD,
           {
             method: "POST",
             headers: myHeaders,
             body: JSON.stringify(post),
           }
         );
        if (!response.ok) return rejectWithValue(response.statusText)
        const data = await response.json()
        return data
      } catch (err) {
        // You can choose to use the message attached to err or write a custom error
        return rejectWithValue(err)
      }
    }
  )

export const updatePassword = createAsyncThunk(
    'updatePassword',
    async (post, { rejectWithValue }) => {
      try {
  
        const response = await fetch(
          API_URLS.UPDATE_PASSWORD,
           {
             method: "POST",
             headers: myHeaders,
             body: JSON.stringify(post),
           }
         );
        if (!response.ok) return rejectWithValue(response.statusText)
        const data = await response.json()
        return data
      } catch (err) {
        // You can choose to use the message attached to err or write a custom error
        return rejectWithValue(err)
      }
    }
  )


