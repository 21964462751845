import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URLS } from "../../../utils/api.urls";

let myHeaders = new Headers();
myHeaders.append('Content-Type', 'application/json');

export const addUpdateBlog = createAsyncThunk(
    'addUpdateBlog',
    async ( post, { rejectWithValue }) => {
        try {

            const response = await fetch(
                API_URLS.ADMIN.ADD_UPDATE_BLOG,
                {
                    method: "POST",
                    body: post, //form form data
                }
            );
            if (!response.ok) return rejectWithValue(response.statusText)
            const data = await response.json()
            return data
        } catch (err) {
            // You can choose to use the message attached to err or write a custom error
            return rejectWithValue(err)
        }
    }
)

export const addUpdateCategory = createAsyncThunk(
    'addUpdateCategory',
    async (post, { rejectWithValue }) => {
      try {
  
        const response = await fetch(
            API_URLS.ADMIN.ADD_UPDATE_Category,
           {
             method: "POST",
             headers: myHeaders,
             body: JSON.stringify(post),
           }
         );
        if (!response.ok) return rejectWithValue(response.statusText)
        const data = await response.json()
        return data
      } catch (err) {
        // You can choose to use the message attached to err or write a custom error
        return rejectWithValue(err)
      }
    }
  )

  
export const updateBlogStatus = createAsyncThunk(
    'updateBlogStatus',
    async (post, { rejectWithValue }) => {
      try {
  
        const response = await fetch(
            API_URLS.ADMIN.UPDATE_BLOG_STATUS,
           {
             method: "POST",
             headers: myHeaders,
             body: JSON.stringify(post),
           }
         );
        if (!response.ok) return rejectWithValue(response.statusText)
        const data = await response.json()
        return data
      } catch (err) {
        // You can choose to use the message attached to err or write a custom error
        return rejectWithValue(err)
      }
    }
  )

  export const deleteBlog = createAsyncThunk(
    'deleteBlog',
    async (post, { rejectWithValue }) => {
      try {
  
        const response = await fetch(
            API_URLS.ADMIN.DELETE_BLOG,
           {
             method: "POST",
             headers: myHeaders,
             body: JSON.stringify(post),
           }
         );
        if (!response.ok) return rejectWithValue(response.statusText)
        const data = await response.json()
        return data
      } catch (err) {
        // You can choose to use the message attached to err or write a custom error
        return rejectWithValue(err)
      }
    }
  )


//read action
export const getBlogs = createAsyncThunk(
    "getBlogs", async (data, { rejectWithValue }) => {
        try {
            let url = API_URLS.ADMIN.GET_BLOGS + "/?";
            let page =  data.page ?  "page=" + data.page : "page=1";
            let limit =  data.limit ?  "&pageSize=" + data.limit : "&pageSize=10";
            const response = await fetch(url + page + limit);
            if (!response.ok) return rejectWithValue(response.statusText)
            const result = await response.json();
            return result;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const getAllBlogs = createAsyncThunk(
    "getAllBlogs", async (data, { rejectWithValue }) => {
        try {
            let url = API_URLS.ADMIN.GET_ALL_BLOGS + "/?";
            let page =  data.page ?  "page=" + data.page : "page=1";
            let limit =  data.limit ?  "&pageSize=" + data.limit : "&pageSize=10";
            const response = await fetch(url + page + limit);
            if (!response.ok) return rejectWithValue(response.statusText)
            const result = await response.json();
            return result;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

//read action
export const getBlogById = createAsyncThunk(
    "getBlogById", async (data, { rejectWithValue }) => {
        try {
            let url = API_URLS.ADMIN.GET_BLOG_BY_ID + "/" + data;
            const response = await fetch(url);
            if (!response.ok) return rejectWithValue(response.statusText)
            const result = await response.json();
            return result;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const getCategoryById = createAsyncThunk(
    "getCategoryById", async (data, { rejectWithValue }) => {
        try {
            let url = API_URLS.ADMIN.GET_Category_BY_ID + "/" + data;
            const response = await fetch(url);
            if (!response.ok) return rejectWithValue(response.statusText)
            const result = await response.json();
            return result;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);