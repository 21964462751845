import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { notify } from "../../../features/notification/notify";
import { useSearchParams } from "react-router-dom";
import { checkResetPasswordToken, updatePassword } from "../../../features/forgotPassword/action";

export const ResetPassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const token = searchParams.get("token");
  const email = searchParams.get("email");

  const [allowUpdatePassword, setAllowUpdatePassword] = useState(false);
  const [passwordSuccess, setPasswordSuccess] = useState(false);

  useEffect(() => {
    console.log(email, token)
    if (email && token) {
      // checkreset token
    let  resetPayload = {
        email: email,
        token: token,
      };

      dispatch(checkResetPasswordToken(resetPayload));
    }
  }, []);

  const [user, setUser] = useState({
    password: "",
    passwordConfirmation: "",
    error: "",
  });

  const resetPasswordResponse = useSelector((state) => state.forgotPassword);

  const handleResetPassword = (data) => {
    let newData = {
      token : token,
      email: email,
      password: data.password,
    };
    console.log("new data",newData);
    dispatch(updatePassword(newData));
  };

  const handleChange = (e) => {
    setUser({
      ...user,
      [e.target.name]: e.target.value,
    });
  };

  const UserSchema = Yup.object().shape({
    password: Yup.string().required("Password is required"),
    passwordConfirmation: Yup.string().oneOf(
      [Yup.ref("password"), null],
      "Passwords must match"
    ),
  });

  useEffect(() => {
    console.log("resetPasswordResponse", resetPasswordResponse);

    if (
      resetPasswordResponse.success === true &&
      resetPasswordResponse.actionName == "checkResetPasswordToken"
    ) {
      if (resetPasswordResponse.message == "ok") 
        setAllowUpdatePassword(true);
    }
    if (
      resetPasswordResponse.success === true &&
      resetPasswordResponse.actionName == "updatePassword"
    ) {
      setPasswordSuccess(true)
      setAllowUpdatePassword(false);
      notify(resetPasswordResponse.message, "success");
    }
    if (resetPasswordResponse.error) {
      notify(resetPasswordResponse.error, "error");
    }
  }, [resetPasswordResponse]);
  return (
    <>
      <main>
      {passwordSuccess && (
          <div className="sign-details">
            <div className="sign-wrap">
              <img src="images/logo.png" alt="Logo" className="logo" />
              <h2>Password updated successfully , please login with new credentials.</h2>
              <div className="sign-wrap">
              <Link to="/login" className="grey-btn"> 
                          <b>Login </b>
                        </Link>
                        </div>
            </div>
          </div>
        )}
        {allowUpdatePassword && (
          <div className="sign-details">
            <div className="sign-wrap">
              <img src="images/logo.png" alt="Logo" className="logo" />
              <h2>Password Reset</h2>
              <Formik
                initialValues={user}
                validationSchema={UserSchema}
                enableReinitialize={true}
                onSubmit={(values) => {
                  console.log("viewvalues", values);
                  handleResetPassword(values);
                }}
              >
                {({ errors, touched, isValid, handleBlur }) => (
                  <>
                    <Form>
                      <div className="mb-3 position-relative">
                        <input
                          type="password"
                          value={user.password}
                          name="password"
                          className="form-control"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          placeholder="Password"
                        />
                        {errors.password && touched.password ? (
                          <div className="invalid-feedback d-block">
                            {errors.password}
                          </div>
                        ) : null}
                      </div>
                      <div className="mb-3 position-relative">
                        <input
                          type="password"
                          value={user.passwordConfirmation}
                          name="passwordConfirmation"
                          className="form-control"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          placeholder="Re-enter Password"
                        />
                        {errors.passwordConfirmation &&
                        touched.passwordConfirmation ? (
                          <div className="invalid-feedback d-block">
                            {errors.passwordConfirmation}
                          </div>
                        ) : null}
                      </div>
                      {resetPasswordResponse.error ? (
                        <div className="invalid-feedback d-block">
                          {resetPasswordResponse.error}
                        </div>
                      ) : null}
                      {user.error ? (
                        <div className="invalid-feedback d-block">
                          {user.error}
                        </div>
                      ) : null}
                      <div className="member-links">
                        <span href="#">
                          Don’t have account?{" "}
                          <Link to="/signup">
                            <b>Signup </b>
                          </Link>
                        </span>
                      </div>

                      <button type="submit" disabled={!isValid} className="btn">
                        Reset
                      </button>
                    </Form>
                  </>
                )}
              </Formik>
            </div>
          </div>
        )}
      </main>
    </>
  );
};
