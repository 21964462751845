import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { DefaultLayout } from "../../../layouts/defaultLayout";
import { getCategories, getAllProducts, changeProductStatus } from "../../../../features/product/action";
import { ImageSlider } from "../../products/imageSlider";
import { AccountLayout } from "../accountLayout";
import { CONSTANT } from "../../../../utils/constant";
import ReactPaginate from "react-paginate";
import { Switch  } from '@mui/material';
export const AllProducts = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const productResponse = useSelector((state) => state.product);
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [itemCount, setItemCount] = useState(CONSTANT.PAGE_LIMIT);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const isFirstRender = useRef(true);

  const [filter, setFilter] = useState();

  useEffect(() => {
    
    dispatch(getCategories())
    callProductData()
  }, []);

  useEffect(() => {
    
    if (!isFirstRender.current) {
      callProductData()
    }
    isFirstRender.current = false;
  }, [currentPage, itemCount]);

  const callProductData =() =>{
    dispatch(getAllProducts(
      {page: currentPage+1, limit: itemCount, status : 'all'}
    ));
  }
  useEffect(() => {   
   
    if (productResponse.actionName == 'getCategories')
      productResponse.categories && setCategories(productResponse.categories);
      if (productResponse.actionName == 'changeProductStatus'){
        if(productResponse.success)
        callProductData(filter)
      }      
  }, [productResponse]);

  useEffect(() => {
    if(productResponse.actionName == 'getAllProducts'){
    productResponse?.allProducts?.rows &&
      setProducts(productResponse?.allProducts?.rows);

    if (productResponse?.allProducts?.count !== null ) {
      setPageCount(Math.ceil(productResponse?.allProducts?.count / itemCount));
    }
  }
  }, [productResponse.allProducts]);

  const handlePageChange = (selectedObject) => {
    setCurrentPage(selectedObject.selected);
  };
  const handleItemsChange = (event) => {
    setItemCount(event.target.value);
    setCurrentPage(0);
  };

  const goToEditPage = (product) => {
    navigate('/account/product/add',
      {
        state: { product: product }
      });
  };

  const handleStatusChange =(e,props) => {
    const payload = {
      id: props.id,
      status: props.status == true ? false : true
    }
    dispatch(changeProductStatus(payload))
  }

  const handleStatusFilter = (e) => {
    setFilter({
      ...filter, 
      status: e.target.value
    })
  }

  return (
    <AccountLayout>
      <div className="right-column">
        <div className="edit-outer p-4">
          <div className="edit-inner-left">
            <div style={{ justifyContent: "space-between", display: "flex" }}>
              <h3>All Products</h3>
              <div className="text-endtext-end pe-4">
                <Link to="/account/product/add" className="btn orange-btn">
                  Add New
                </Link>
              </div>
            </div>
            <div className="row mt-2">
            <div className="col-sm-6"></div>
            <div className="col-sm-6 text-sm-end">
                  <div className="sort d-inline-block me-4">
                    <select className="form-select" onChange={handleStatusFilter}>
                      <option value="all">All</option>
                      <option value="active">Active</option>
                      <option value="inactive">Inactive</option>
                    </select>
                  </div>
                </div>
            </div>
            {products.length == 0 && <div style={{textAlign: 'center'}}>No product available</div>}
           { products.length > 0 &&  <div className="table-responsive">
              <table className="table blog-table table-striped">
                <thead>
                  <tr>
                    <th colSpan="1">Name</th>
                    <th>Style Name</th>
                    <th>Description</th>
                    <th>Image</th>
                    <th >Action</th>
                  </tr>
                </thead>
                <tbody>
                  {products &&
                    products.map((item, index) => (
                      <tr key={index}>
                        <td>{item.name}</td>
                        <td>{item.StyleName}</td>
                        <td>
                          {item.description}
                        </td>
                        <td className="dv-td-img">
                          {item.ProductImages[0] != undefined ?
                            (<img src={process.env.REACT_APP_API_URL + '/'+ item.ProductImages[0].filePath} />)
                            : (<img src="/images/Pending-upload.png" alt="Upload Pending" title="Upload Pending" />)
                          }
                        </td>
                        <td>
                        <a onClick={() => goToEditPage(item)}>
                            <i className="fa-regular fa-pen-to-square" title="Edit product"></i>
                          </a>                         
                           <Switch title={ (item.status ? 'Disable' : 'Enable') + ' product'}  checked={item.status} onChange={(e) => handleStatusChange(e,item)}/>                          
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
              <nav className="pagination-block">
                <p className="total">
                  {productResponse?.allProducts?.count} items total
                </p>

                <ReactPaginate
                  previousLabel={"previous"}
                  nextLabel={"next"}
                  breakLabel={"..."}
                  pageCount={pageCount}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={3}
                  forcePage={currentPage}
                  onPageChange={handlePageChange}
                  containerClassName={"pagination justify-content-center mt-4"}
                  pageClassName={"page-item"}
                  pageLinkClassName={"page-link"}
                  previousClassName={"page-item"}
                  previousLinkClassName={"page-link"}
                  nextClassName={"page-item"}
                  nextLinkClassName={"page-link"}
                  breakClassName={"page-item"}
                  breakLinkClassName={"page-link"}
                  activeClassName={"active"}
                />
                <div className="customSelect">
                  <label htmlFor="">Items per page :</label>
                  <div className="selectBox">
                    <select name="" id="" onChange={handleItemsChange}>
                      <option value={CONSTANT.PAGE_LIMIT}>
                        {CONSTANT.PAGE_LIMIT}
                      </option>
                      <option value="15">15</option>
                      <option value="20">20</option>
                    </select>
                  </div>
                </div>
              </nav>
            </div> }
          </div>
        </div>
      </div>
    </AccountLayout>
  );
};
