import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
// import "./style.css";
// import "./style6.css";
import { useDispatch, useSelector } from "react-redux";
import { getProfileData } from "../../features/user/action";
import sample from './video_1.mp4';
import sample2 from './video_2.mp4';

const Dashboard = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [userProfileData , setUserProfileData] = useState([])

  useEffect(() => {
   dispatch(getProfileData({
    userId:localStorage.getItem("userId")
   }));
  

  }, [])
  const profileResponse = useSelector((state) => state.user);

  const handleLogOut = () => {
    localStorage.removeItem("token");
    navigate("/login");
  };

  const goToEditPage = () => {
    navigate("/editProfile", {
      replace: true,
      state: { profile: userProfileData },
    });
  }

  useEffect(() => {

        profileResponse.profile && setUserProfileData(profileResponse.profile)

      console.log("profileResponse", profileResponse)
  }, [profileResponse]);


  return (
    <>
      
      <div className="banner-top container-fluid" id="home">
        <header>
          <div className="row">
            <div className="col-md-3 top-info text-left mt-lg-4">
              <h6>Need Help</h6>
              <ul>
                <li>
                  <i className="fas fa-phone"></i> Call
                </li>
                <li className="number-phone mt-3">12345678099</li>
              </ul>
            </div>
            <div className="col-md-6 logo-w3layouts text-center">
              <h1 className="logo-w3layouts">
                <a className="navbar-brand" href="index.html">
                  Groyyo{" "}
                </a>
              </h1>
            </div>

            <div className="col-md-3 top-info-cart text-right mt-lg-4">
              <ul className="cart-inner-info">
                <li className="button-log">
                  <a className="btn-open" href="#">
                    <span className="fa fa-user" aria-hidden="true"></span>
                  </a>
                </li>
                <li className="galssescart galssescart2 cart cart box_1">
                  <form action="#" method="post" className="last">
                    <input type="hidden" name="cmd" value="_cart" />
                    <input type="hidden" name="display" value="1" />
                    <button className="btn btn-primary" onClick={handleLogOut}>
                      Logout
                      <i className="fas fa-cart-arrow-down"></i>
                    </button>
                  </form>
                </li>
              </ul>
            </div>
          </div>
          <div className="search">
            <div className="mobile-nav-button">
              <button id="trigger-overlay" type="button">
                <i className="fas fa-search"></i>
              </button>
            </div>

            <div className="overlay overlay-door">
              <button type="button" className="overlay-close">
                <i className="fa fa-times" aria-hidden="true"></i>
              </button>
              <form action="#" method="post" className="d-flex">
                <input
                  className="form-control"
                  type="search"
                  placeholder="Search here..."
                  required=""
                />
                <button type="submit" className="btn btn-primary submit">
                  <i className="fas fa-search"></i>
                </button>
              </form>
            </div>
          </div>
          <label className="top-log mx-auto"></label>
          <nav className="navbar navbar-expand-lg navbar-light bg-light top-header mb-2">
            <button
              className="navbar-toggler mx-auto"
              type="button"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav nav-mega mx-auto">
                <li className="nav-item">
                  <Link className="nav-link ml-lg-0" to="/dashboard">
                    My Profile
                  </Link>
                </li>
                <li className="nav-item">
                <Link className="nav-link ml-lg-0" to="/addresses">
                    My Address
                  </Link>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="#">
                    Orders
                  </a>
                </li>
                <li className="nav-item">
                <Link className="nav-link ml-lg-0" to="/resetpassword">
                  Reset Password
                  </Link>
                </li>
              </ul>
            </div>
          </nav>
        </header>
      </div>
      <div className="banner_inner">
          <video id="background-video" className='videoTag' autoPlay loop muted>
              <source src={sample} type='video/mp4' />
          </video>
          <span data-begin="1" data-end="6">
            Welcome to Groyyo Design Studio!
          </span>          
      </div>
      

      <section className="banner-bottom-wthreelayouts py-lg-5 py-3">
        <div className="container">
          <div className="inner-sec-shop px-lg-4 px-3">
            {/* // add form or do things here  */}

          {userProfileData &&   <form>
              <div className="row mb-3">
                <div className="col-sm-6">
                  <label className="col-sm-6 col-form-label fw-bold">First Name:</label>
                  <label className="col-sm-6 col-form-label">{userProfileData.firstName}</label>
                </div>
                <div className="col-sm-6">
                  <label className="col-sm-6 col-form-label fw-bold">Last Name:</label>
                  <label className="col-sm-6 col-form-label">{userProfileData.lastName}</label>
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-sm-6">
                  <label className="col-sm-6 col-form-label fw-bold">Email:</label>
                  <label className="col-sm-6 col-form-label">{userProfileData.emailId} </label>
                </div>
                <div className="col-sm-6">
                  <label className="col-sm-6 col-form-label fw-bold">Mobile:</label>
                  <label className="col-sm-6 col-form-label">{userProfileData.mobileNo} </label>
                </div>
              </div>
              <a className="btn btn-primary" to="/editProfile" onClick={goToEditPage}>Edit Profile</a>
            </form> }
          </div>
        </div>
      </section>

      <footer className="py-lg-5 py-3">
        <div className="container-fluid px-lg-5 px-3">
          <div className="row footer-top-w3layouts">
            <div className="col-lg-3 footer-grid-w3ls">
              <div className="footer-title">
                <h3>About Us</h3>
              </div>
              <div className="footer-text">
                <p>
                  Curabitur non nulla sit amet nislinit tempus convallis quis ac
                  lectus. lac inia eget consectetur sed, convallis at tellus.
                  Nulla porttitor accumsana tincidunt.
                </p>
                <ul className="footer-social text-left mt-lg-4 mt-3">
                  <li className="mx-2">
                    <a href="#">
                      <span className="fab fa-facebook-f"></span>
                    </a>
                  </li>
                  <li className="mx-2">
                    <a href="#">
                      <span className="fab fa-twitter"></span>
                    </a>
                  </li>
                  <li className="mx-2">
                    <a href="#">
                      <span className="fab fa-google-plus-g"></span>
                    </a>
                  </li>
                  <li className="mx-2">
                    <a href="#">
                      <span className="fab fa-linkedin-in"></span>
                    </a>
                  </li>
                  <li className="mx-2">
                    <a href="#">
                      <span className="fas fa-rss"></span>
                    </a>
                  </li>
                  <li className="mx-2">
                    <a href="#">
                      <span className="fab fa-vk"></span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-3 footer-grid-w3ls">
              <div className="footer-title">
                <h3>Get in touch</h3>
              </div>
              <div className="contact-info">
                <h4>Location :</h4>
                <p>0926k 4th block building, king Avenue, New York City.</p>
                <div className="phone">
                  <h4>Contact :</h4>
                  <p>Phone : +121 098 8907 9987</p>
                  <p>
                    Email :
                    <a href="mailto:info@example.com">info@example.com</a>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 footer-grid-w3ls">
              <div className="footer-title">
                <h3>Quick Links</h3>
              </div>
              <ul className="links">
                <li>
                  <a href="index.html">Home</a>
                </li>
                <li>
                  <a href="about.html">About</a>
                </li>
                <li>
                  <a href="404.html">Error</a>
                </li>
                <li>
                  <a href="shop.html">Shop</a>
                </li>
                <li>
                  <a href="contact.html">Contact Us</a>
                </li>
              </ul>
            </div>
            <div className="col-lg-3 footer-grid-w3ls">
              <div className="footer-title">
                <h3>Sign up for your offers</h3>
              </div>
              <div className="footer-text">
                <p>
                  By subscribing to our mailing list you will always get latest
                  news and updates from us.
                </p>
                <form action="#" method="post">
                  <input
                    className="form-control"
                    type="email"
                    name="Email"
                    placeholder="Enter your email..."
                    required=""
                  />
                  <button className="btn1">
                    <i className="far fa-envelope" aria-hidden="true"></i>
                  </button>
                  <div className="clearfix"> </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Dashboard;
