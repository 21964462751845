import React, { useCallback, useEffect, useState } from "react";
import { AccountLayout } from "../accountLayout";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getProfileData } from "../../../../features/user/action";
import { resetUser } from "../../../../features/user/slice";

export const Profile = () => {
  const dispatch = useDispatch();
  const { state } = useLocation();
  const profileResponse = useSelector((state) => state.user);
  const [profile, setProfile] = useState();
  const userId = localStorage.getItem("userId");
  console.log(userId);
  useEffect(() => {
    dispatch(getProfileData({ userId: userId }));
  }, []);

  useEffect(() => {
    dispatch(resetUser());
    profileResponse.profile && setProfile(profileResponse.profile);

    console.log("profileResponse", profileResponse);
  }, [profileResponse]);

  return (
    <AccountLayout>
      {profile && 
          <div className="right-column">
            <div className="dashboard-wrap">
              <div className="heading-wrap">
                <h1>
                  Welcome back, <span className="name">{profile.firstName}</span>
                </h1>

                <h2>Profile Details</h2>
              </div>
              <div className="text-end pt-3 pe-4">
                <Link to="/account/profile/edit" className="btn orange-btn">
                  Edit
                </Link>
              </div>
              <form action="">
                <div>
                  <div className="row">
                    <div className="col-sm-4">
                      <label className="form-label">Customer Name</label>
                    </div>
                    <div className="col-sm-8">
                      <input
                        type="text"
                        className="form-control"
                        readOnly
                        value={profile.firstName + " " + profile.lastName}
                      />
                    </div>
                  </div>
                </div>
                <div>
                  <div className="row">
                    <div className="col-sm-4">
                      <label className="form-label">Contact Number</label>
                    </div>
                    <div className="col-sm-8">
                      <input
                        type="text"
                        className="form-control"
                        readOnly
                        value={profile.mobileNo}
                      />
                    </div>
                  </div>
                </div>
                <div>
                  <div className="row">
                    <div className="col-sm-4">
                      <label className="form-label">Email Id</label>
                    </div>
                    <div className="col-sm-8">
                      <input
                        type="email"
                        className="form-control"
                        readOnly
                        value={profile.emailId}
                      />
                    </div>
                  </div>
                </div>
                <div>
                  <div className="row">
                    <div className="col-sm-4">
                      <label className="form-label">Gender</label>
                    </div>
                    <div className="col-sm-8">
                      <input
                        type="text"
                        className="form-control"
                        readOnly
                        value={profile.gender}
                      />
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
      }
    </AccountLayout>
  );
};
