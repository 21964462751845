import * as React from "react";
import { Navigate } from "react-router-dom";


const Auth = ({children }) => {
  let isLoggedIn = false;
//   let role = "";
 let userData =localStorage.getItem('userData'); 
 let token = localStorage.getItem('token'); 

  if (token !== null && token !== "undefined") {
    // userData = JSON.parse(userData);
    // role = userData?.roles && userData?.roles[0];
    isLoggedIn = true;
  }

  if(isLoggedIn == true ){
    return (

        <div>{children}</div>
    );
  }else{
    return(<Navigate to="/404" />)
  }
};
export default Auth;
