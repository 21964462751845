import * as React from "react";
import { Navigate } from "react-router-dom";


const AdminAuth = ({children }) => {
  let isLoggedIn = false;
  let role = "";
 let token = localStorage.getItem('token'); 
 let userRoles = localStorage.getItem('userRoles'); 

  if (token !== null && token !== "undefined") {
    userRoles = JSON.parse(userRoles);
    role = userRoles?.role && userRoles?.role;
    isLoggedIn = true;
  }

  if(isLoggedIn == true && role == 'Admin' ){
    return (

        <div>{children}</div>
    );
  }else{
    return(<Navigate to="/404" />)
  }
};
export default AdminAuth;
