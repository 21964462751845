import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { createUserSignup } from "../../../features/signup/action";
import { notify } from "../../../features/notification/notify";
import { resetSignup } from "../../../features/signup/slice";
import ReactGA from "react-ga4";

export const Signup = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [user, setUser] = useState({
    email: "",
    password: "",
    passwordConfirmation: "",
    error: "",
  });

  const signupResponse = useSelector((state) => state.userSignup);

  const handleSignup = (data) => {
    let newData = {
      email: data.email,
      password: data.password,
    };
    console.log(newData);
      dispatch(createUserSignup(newData));
  };

  const handleChange = (e) => {
    setUser({
      ...user,
      [e.target.name]: e.target.value,
    });
  };

  const UserSchema = Yup.object().shape({
    email: Yup.string()
      .email("Email must be valid")
      .min(2, "Too Short!")
      .required("Required"),
    password: Yup.string().required("Password is required")
    .min(8, "Password must be at least 8 characters long")
    .matches(
      /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]+$/,
      "Password must contain at least one uppercase letter, one lowercase letter, one digit, and one special character"
    ),
    passwordConfirmation: Yup.string().oneOf(
      [Yup.ref("password"), null],
      "Passwords must match"
    ),
  });
  useEffect(()=>{
    ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search, title: "Signup Page" });
  })
  useEffect(() => {
    console.log("signupResponse", signupResponse);
    if (signupResponse.success === true && signupResponse.actionName == "emailsignup") {
      notify("An OTP has been sent to your email. Please verify it to complete the account creation process.", "success");
      dispatch(resetSignup());
      ReactGA.event({
        category: "sigup",
        action: "signup success",
        lable:"signup"
      });
      navigate("/login")
    } else {
    }
    if (signupResponse.error) {
      notify(signupResponse.error, "error");
    }
  }, [signupResponse]);
  return (
    <>
      <main>
        <div className="sign-details">
          <div className="sign-wrap">
            <img src="images/logo.png" alt="Logo" className="logo" />
            <h2>Welcome</h2>

            <Formik
              initialValues={user}
              validationSchema={UserSchema}
              enableReinitialize={true}
              onSubmit={(values) => {
                console.log("viewvalues", values);
                handleSignup(values);
              }}
            >
              {({ errors, touched, isValid, handleBlur }) => (
                <>
                  <Form>
                    <div className="mb-3 position-relative">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Email"
                        value={user.email}
                        name="email"
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {errors.email && touched.email ? (
                        <div className="invalid-feedback d-block">
                          {errors.email}
                        </div>
                      ) : null}
                    </div>
                    <div className="mb-3 position-relative">
                      <input
                        type="password"
                        className="form-control"
                        placeholder="Password"
                        value={user.password}
                        name="password"
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {errors.password && touched.password ? (
                        <div className="invalid-feedback d-block">
                          {errors.password}
                        </div>
                      ) : null}
                    </div>
                    <div className="mb-3 position-relative">
                      <input
                        type="password"
                        className="form-control"
                        placeholder="Confirm Password"
                        value={user.passwordConfirmation}
                        name="passwordConfirmation"
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {errors.passwordConfirmation &&
                      touched.passwordConfirmation ? (
                        <div className="invalid-feedback d-block">
                          {errors.passwordConfirmation}
                        </div>
                      ) : null}
                    </div>
                    {signupResponse.error ? (
                      <div className="invalid-feedback d-block">
                        {signupResponse.error}
                      </div>
                    ) : null}
                    {user.error ? (
                      <div className="invalid-feedback d-block">
                        {user.error}
                      </div>
                    ) : null}
                    <div className="member-links">
                      <span>
                        Already a member ?
                        <Link to="/login"> {" "}
                          <b>Login </b>
                        </Link>
                      </span>
                    </div>
                    <button type="submit" disabled={!isValid} className="btn">
                      Signup
                    </button>
                  </Form>
                </>
              )}
            </Formik>
          </div>
        </div>
      </main>
    </>
  );
};
