import React from "react";
import { Footer } from "./footer";
import { Header } from "./header";
import ScrollToTop from "./ScrollToTop";

export const DefaultLayout = (props) => {
  const { children } = props;

  return (
    <>
    <ScrollToTop />
    <Header/>
          <div className="body">{children}</div>
      <Footer/>
    </>
  );
};
