import React, { useEffect } from "react";
import { DefaultLayout } from "../layouts/defaultLayout";
import { Link } from "react-router-dom";

import ReactGA from "react-ga4";
 
export const Thankyou = () => {
    useEffect(() => {
        ReactGA.event({
            category: "order",
            action: "order success",
            lable:"order"
          });
      }, []);

    return (
        <DefaultLayout>
            <div className="thanku-wrap">
                <h2><span className="fa fa-circle-check"></span>
                    <br />
                    Thank You.
                </h2>
                <p>You order has been placed successfully.</p>
                <p>Need To Speak with Someone? Our Customer Support Team is just a click away!
                    <br />
                    <a href="mailto:marketing@groyyo.com">Email: marketing@groyyo.com</a>
                </p>
                <Link to="/" className="orange-btn">
                    Go Home
                </Link>
            </div>
        </DefaultLayout>
    );
};
