import React, { useEffect, useState } from "react";
import { DefaultLayout } from "../../layouts/defaultLayout";
import { useDispatch, useSelector } from "react-redux";
import { getCategories, getProducts } from "../../../features/product/action";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { ImageSlider } from "./imageSlider";
import { updateWishList } from "../../../features/user/action";
import $ from "jquery";
import { Loader } from "../../layouts/loader";

export const ProductList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const productResponse = useSelector((state) => state.product);
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [loader, setLoader] = useState(true)
  const { name, stype } = useParams();
  let userId = localStorage.getItem("userId")

  let category = name !== null ? name : 'men';
  let season = stype == undefined ? '' : stype
  let page = searchParams.get('page') !== null ? searchParams.get('page') : 1;


  const [filter, setFilter] = useState({ page: page, limit: 10, category: category, sort: 'new', season: season });

  console.log( category, season)
  useEffect(() => {
    setFilter({
      ...filter,
      category: category,
      season : season
    })
    dispatch(getCategories())
  }, [name, stype]);

  useEffect(() => {
    dispatch(
      getProducts(filter)
    );
  }, [filter]);


  useEffect(() => {
    if (productResponse.actionName == 'getProducts')
      productResponse.products && setLoader(false);
    productResponse.products && setProducts(productResponse.products);

    if (productResponse.actionName == 'getCategories')
      productResponse.categories && setCategories(productResponse.categories);
  }, [productResponse]);

  const handleProductClick = (product) => {
    navigate('/product-details/' + product.Category.name.toLowerCase() + '/' + product.name.toLowerCase().replaceAll(' ', '_'),
      {
        state: { product: product }
      });
    hanldeRecentLocalStorage(product);
  }
  const handleCategoryClick = (cat) => {
    if (cat.type == "Category") {
      let categoryName = filter.category == cat.name.toLowerCase() ? 'all' : cat.name.toLowerCase();
      navigate('/designs/' + categoryName);
    }
    else {
      let newSeason = filter.season == cat.name.toLowerCase() ? '' : cat.name.toLowerCase();
      newSeason = newSeason !== '' ? '/' + newSeason : ''
      navigate('/designs/' + category + newSeason);
    }
  }

  const hanldeRecentLocalStorage = (productArr) => {
    let updatedRecentProduct = [];
    if (localStorage.getItem('recentview') !== null) {
      let recentProductData = JSON.parse(localStorage.getItem('recentview'))
      recentProductData.length > 3 && recentProductData.splice(-1, 1)

      updatedRecentProduct = recentProductData
    }

    let newData = [...[productArr], ...updatedRecentProduct];
    // console.log("newData", newData)
    // console.log("productArr", [productArr])
    localStorage.setItem('recentview', JSON.stringify(newData));
  }

  const handleSortingChange = (e) => {
    setFilter({
      ...filter,
      sort: e.target.value
    })
  }

  const handleUpdateWishlist = (e, product) => {
    let addWishlist = $(e.target).hasClass('active') ? false : true
    if ($(e.target).hasClass('active')) {
      addWishlist = false;
      $(e.target).removeClass('active')
    } else {
      addWishlist = true;
      $(e.target).addClass('active')
    }
    let payload = {
      "userId": userId,
      "productId": product.id,
      "add": addWishlist
    }
    dispatch(updateWishList(payload))
  }


  return (
    <DefaultLayout>
      <section className="product-list-wrap">
        <img
          src="/images/product-list.jpeg"
          alt="product-list"
          className="w-100"
        />
        <div className="container">
          <div className="flex-column-reverse flex-lg-row row">
            <div className="col-lg-3 d-lg-block product-list-filter">
              <div>
                <div className="product-category">
                  <ul>
                    <li>
                      <span className="inner-sub-heading">
                        Clothing
                      </span>
                      <ul className="product-inner-sub-category">
                        {categories.filter(x => { return x.type == "Category" }) &&
                          categories.filter(x => { return x.type == "Category" }).map((cat, index) => (
                            <li key={index}>
                              <a value={cat.id} onClick={() => handleCategoryClick(cat)} className={filter.category.toLowerCase() == cat.name.toLowerCase() ? 'bold selected-cat' : 'bold'}>
                                {cat.name}
                              </a>
                            </li>))}
                      </ul>
                    </li>
                    <li>
                      <span className="inner-sub-heading">
                        Collections
                      </span>
                      <ul className="product-inner-sub-category">
                        {categories.filter(x => { return x.type == "Season" }) &&
                          categories.filter(x => { return x.type == "Season" }).map((cat, index) => (
                            <li key={index}>
                              <a value={cat.id} onClick={() => handleCategoryClick(cat)} className={filter.season.toLowerCase() == cat.name.toLowerCase() ? 'bold selected-cat' : 'bold'}>
                                {cat.name}
                              </a>
                            </li>))}
                      </ul>
                    </li>
                  </ul>
                </div>

              </div>
            </div>
            <div className="col-lg-9 ">
              <h2>
                <strong>Shop</strong>
                <br />
                {categories.length > 0 && <span style={{ "text-transform": "capitalize" }}>{name}</span>}
              </h2>
              <div className="row mb-4 align-items-end">
                <div className="col-sm-6">
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <a href="#">Home</a>
                      </li>
                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                        {categories.length > 0 && <span style={{ "text-transform": "capitalize" }}>{name}</span>}
                      </li>
                      {
                        stype !== undefined && 
                      
                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                        {categories.length > 0 && <span style={{ "text-transform": "capitalize" }}>{stype}</span>}
                      </li>
                      }
                    </ol>
                  </nav>
                </div>
                <div className="col-sm-6 text-sm-end">
                  <div className="sort d-inline-block">
                    <select className="form-select" onChange={handleSortingChange}>
                      <option value="new">Newest First</option>
                      <option value="lowhigh">Price Low to High</option>
                      <option value="highlow">Price High to Low</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="product-row row row-gap-4">

                {
                  loader && <Loader />
                }
                {products &&
                  products.map((product, index) => (
                    <div className="col-6 col-md-4" key={index}>
                      <div className="product-col">
                        <div className="product-col-inner">
                          <a onClick={() => handleProductClick(product)}>
                            {product.ProductImages.length > 0 && <ImageSlider images={product.ProductImages} />}
                          </a>
                          <div style={{ pointerEvents: 'none' }} id="dv-overlay" className="product-overlay dv-ovrly" >
                            <div className="add-wishlist">
                              <a style={{ pointerEvents: 'auto' }} onClick={(e) => handleUpdateWishlist(e, product)}>
                                <i className={(product.WishLists?.length > 0 && product.WishLists[0].userId == userId) ? 'fa-solid fa-heart active' : 'fa-solid fa-heart'}></i>
                              </a>
                            </div>
                            <a style={{ pointerEvents: 'auto' }} onClick={() => handleProductClick(product)}>
                              View
                            </a>
                          </div>
                        </div>
                        <a onClick={() => handleProductClick(product)}>
                          <h3>{product.name}</h3>
                        </a>
                        <span className="price">
                          ₹{parseInt(product.price).toFixed(2)}
                        </span>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </section>
    </DefaultLayout>
  );
};
