import React, { useEffect, useRef, useState } from "react";
import { AccountLayout } from "../accountLayout";
import JoditEditor from "jodit-react";
import { useDispatch, useSelector } from "react-redux";
import { addUpdateEvent } from "../../../../features/accountAdmin/event/action";
import { useLocation, useNavigate } from "react-router";
import { notify } from "../../../../features/notification/notify";
import { resetEventAdmin } from "../../../../features/accountAdmin/event/slice";
import { BASE_URLS } from "../../../../utils/api.urls";
import { v1 as uuidv1 } from 'uuid';
export const AddEditEvent = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const editor = useRef(null);
  const [file, setFile] = useState(null);
  const [formTitle, setFormTitle] = useState("Add");
  const { state } = useLocation();

  const [eventData, setEventData] = useState({
    id: 0,
    title: "",
    description: "",
    isPublished: 1,
    fileName: '',
  });
  const eventAdminResponse = useSelector((state) => state.eventAdmin);
  useEffect(() => {
    if (state) {
      setFormTitle('Edit')
      setEventData({
        ...eventData,
        id : state.event.id,
        title : state.event.title,
        description : state.event.description.replace(/(<([^>]+)>)/g, ""),
        isPublished : state.event.isPublished,
        fileName : state.event.imagePath,
      })
    } else {
      navigate("/account/event/add");
    }
  }, []);

  const config = {
    zIndex: 0,
    uploader: {
      insertImageAsBase64URI: true,
    },
    askBeforePasteFromWord: false,
    askBeforePasteHTML: false,
    placeholder: "Start typings...",
    showXPathInStatusbar: false,
    readonly: false,
    activeButtonsInReadOnly: ["source", "fullsize", "print", "about", "dots"],
    toolbarButtonSize: "middle",
    theme: "default",
    saveModeInCookie: false,
    spellcheck: true,
    editorCssClass: false,
    triggerChangeEvent: true,
    width: "auto",
    height: "300",
    minHeight: 100,
    direction: "",
    language: "auto",
    debugLanguage: false,
    i18n: "en",
    tabIndex: -1,
    toolbar: true,
    enter: "P",
    //defaultMode: Jodit.MODE_WYSIWYG,
    useSplitMode: false,
    colors: {
      greyscale: [
        "#000000",
        "#434343",
        "#666666",
        "#999999",
        "#B7B7B7",
        "#CCCCCC",
        "#D9D9D9",
        "#EFEFEF",
        "#F3F3F3",
        "#FFFFFF",
      ],
      palette: [
        "#980000",
        "#FF0000",
        "#FF9900",
        "#FFFF00",
        "#00F0F0",
        "#00FFFF",
        "#4A86E8",
        "#0000FF",
        "#9900FF",
        "#FF00FF",
      ],
      full: [
        "#E6B8AF",
        "#F4CCCC",
        "#FCE5CD",
        "#FFF2CC",
        "#D9EAD3",
        "#D0E0E3",
        "#C9DAF8",
        "#CFE2F3",
        "#D9D2E9",
        "#EAD1DC",
        "#DD7E6B",
        "#EA9999",
        "#F9CB9C",
        "#FFE599",
        "#B6D7A8",
        "#A2C4C9",
        "#A4C2F4",
        "#9FC5E8",
        "#B4A7D6",
        "#D5A6BD",
        "#CC4125",
        "#E06666",
        "#F6B26B",
        "#FFD966",
        "#93C47D",
        "#76A5AF",
        "#6D9EEB",
        "#6FA8DC",
        "#8E7CC3",
        "#C27BA0",
        "#A61C00",
        "#CC0000",
        "#E69138",
        "#F1C232",
        "#6AA84F",
        "#45818E",
        "#3C78D8",
        "#3D85C6",
        "#674EA7",
        "#A64D79",
        "#85200C",
        "#990000",
        "#B45F06",
        "#BF9000",
        "#38761D",
        "#134F5C",
        "#1155CC",
        "#0B5394",
        "#351C75",
        "#733554",
        "#5B0F00",
        "#660000",
        "#783F04",
        "#7F6000",
        "#274E13",
        "#0C343D",
        "#1C4587",
        "#073763",
        "#20124D",
        "#4C1130",
      ],
    },
    colorPickerDefaultTab: "background",
    imageDefaultWidth: 300,
    removeButtons: [],
    disablePlugins: [],
    extraButtons: [],
    sizeLG: 900,
    sizeMD: 700,
    sizeSM: 400,
    sizeSM: 400,
    buttons: [
      "source",
      "|",
      "bold",
      "strikethrough",
      "underline",
      "italic",
      "|",
      "ul",
      "ol",
      "|",
      "outdent",
      "indent",
      "|",
      "font",
      "fontsize",
      "brush",
      "paragraph",
      "|",
      "image",
      "video",
      "table",
      "link",
      "|",
      "align",
      "undo",
      "redo",
      "|",
      "hr",
      "eraser",
      "copyformat",
      "|",
      "symbol",
      "fullsize",
      "print",
      "about",
    ],
    buttonsXS: [
      "bold",
      "image",
      "|",
      "brush",
      "paragraph",
      "|",
      "align",
      "|",
      "undo",
      "redo",
      "|",
      "eraser",
      "dots",
    ],
    events: {},
    textIcons: false,
  };

  const handleTitleChange = (e) => {
    setEventData({
      ...eventData,
      title: e.target.value,
    });
  };

  const handleContentChange = (content) => {
    setEventData({ ...eventData, description: content });
  };

  const handleRemoveImg =() => {
    setEventData({
      ...eventData,
      fileName: '',
    });
    setFile(null)
  }
  const handleImageChange = (e) => {   
    if (e.target.files.length) {
      const acceptedFormats = ["png", "jpeg", "jpg", "webp"];
      const fileExtension = e.target.files[0]?.name.split(".").pop().toLowerCase();
      if (!acceptedFormats.includes(fileExtension)) {
          return;
      }
      const id = uuidv1();
      var image = e.target.files[0];
        var blob = image.slice(0, image.size);
      const imgName = id + '.' + fileExtension
      const imgFile = new File([blob], imgName);
      setFile(imgFile);
      setEventData({
        ...eventData,
        fileName: imgName,
      });
    }
  };

  const handleSubmit = () => {

    if(eventData.fileName == '' && file == null){
      notify('Please add an Image first!','warn')
    }else{
    let formData = new FormData();
    formData.append("file", file);
    formData.append("id", eventData.id);
    formData.append("title", eventData.title);
    formData.append("description", eventData.description);
    formData.append("fileName", eventData.fileName);
    formData.append("isPublished", eventData.isPublished);

    dispatch(addUpdateEvent(formData));
    }
  };

  const handleCancel = () => {
    navigate("/account/event");
  }

  useEffect(() => {
    if (
      eventAdminResponse.success === true &&
      eventAdminResponse.actionName === "addUpdateEvent"
    ) {
      dispatch(resetEventAdmin());
      let successMessage =
        eventData.id !== ""
          ? "Event updated successfully"
          : "Event created successfully";
      notify(successMessage, "success");
      setFile(null);
      navigate("/account/event");
    }
    if (eventAdminResponse.error) {
      notify(eventAdminResponse.error, "error");
    }
  }, [eventAdminResponse]);

  return (
    <AccountLayout>
      <div className="right-column">
        <div className="edit-outer p-4">
          <div className="edit-inner-left">
            <h3>{formTitle} Event</h3>
            <div className="row">
              <div className="row row-gap-3">
                <div className="col-md-12">
                  <label className="form-label">Title</label>
                  <input
                    type="text"
                    value={eventData.title}
                    onChange={handleTitleChange}
                    className="form-control"
                  />
                </div>
                <div className="col-md-12">
                  <label className="form-label">Image</label>
                  {eventData.fileName == '' && file == null && <input
                    className="form-control form-control-lg"
                    type="file"
                    name="bulkProduct"
                    accept="image/*"
                    onChange={handleImageChange}
                    // ref={ref}
                    // value={title}
                  /> }
                  {eventData.fileName != '' && file == null && <div className="blg-edit-img"> <br /><span><img alt="Image not available" src={BASE_URLS.BASE_URL + eventData.fileName} /> <i className="fa fa-times-circle" onClick={handleRemoveImg}></i></span></div>}
                  {eventData.fileName != '' && file != null && <div className=""> <br /><span> {eventData.fileName} &nbsp;<i className="fa fa-trash" onClick={handleRemoveImg}></i></span></div>}
                </div>
                <div className="col-md-12">
                  <label className="form-label">Content</label>
                  <JoditEditor
                    ref={editor}
                    value={eventData.description}
                    config={config}
                    tabIndex={1} // tabIndex of textarea
                    onBlur={(newContent) => handleContentChange(newContent)} // preferred to use only this option to update the content for performance reasons
                    // onChange={newContent => {}}
                  />
                </div>
              </div>
              <div className="text-start py-5">
                <button className="btn orange-btn" onClick={handleSubmit}>
                  Save
                </button>{" "}
                &nbsp;
                <button className="btn orange-cancel-btn" onClick={handleCancel}>
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </AccountLayout>
  );
};
