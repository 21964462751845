import React, { useEffect, useState } from "react";
import {useSelector} from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import $ from "jquery";

export const Footer = () => {

    const navigate = useNavigate();
    const userResponse = useSelector((state) => state.user);
    const [brandDetails, setBrandDetails] = useState();
    useEffect(() => {
        if (userResponse.actionName == "getBrandByName") {
          if (userResponse.success) setBrandDetails(userResponse.brandDetails);
        }
      }, [userResponse]);

    const handleSectionClick = (e) => {
        e.preventDefault();
        var aid = e.target.getAttribute('href');
        if(window.location.pathname == '/') {
        $('html,body').animate({scrollTop: $(aid).offset().top - 55},'smooth');

        }else{
            navigate("/" + aid)
        }
      }
      
  return (
    <>
      <footer>
        <div className="container">
            <h2>Let's talk <a href={"mailto:"+brandDetails?.emailId}>{brandDetails?.emailId}</a></h2>
            <div className="row row-gap-5">
                <div className="col-md-4 col-6">
                    {/* <p>Give us a call</p> */}
                    <div>Global</div>
                    <a href={"mailto:"+brandDetails?.emailId}>{brandDetails?.emailId}</a>
                </div>
                <div className="col-md-4 col-6">
                    <div>For latest Blogs, events</div>
                    <Link to="/blogs">Latest <i className="fa-solid fa-arrow-right"></i></Link>

                    <p className="mt-3">Stay Connected</p>
                    <ul className="footer-social-media">
                    <li><a target="_blank" href="https://www.instagram.com/groyyostudio/"><i className="fa-brands fa-instagram"></i></a></li>
                <li><a target="_blank" href="https://www.linkedin.com/company/groyyo/?originalSubdomain=in"><i className="fa-brands fa-linkedin"></i></a></li>
                {/* <li><a href="#"><i className="fa-brands fa-facebook"></i></a></li> */}
                <li><a target="_blank" href="https://groyyo.com/"><i className="fa-solid fa-globe"></i></a></li>
                    </ul>
                </div>
                <div className="col-md-4 col-12">
                    <p><i className="fa-solid fa-globe me-3"></i><span>Select Language</span></p>
                    <select className="form-select">
                        <option value="">English</option>
                        {/* <option value="">Hindi</option>
                        <option value="">Turkish</option>
                        <option value="">Arabic</option> */}
                    </select>

                    <span>Any issues? <a href="#contact-us" onClick={handleSectionClick}>Chat with us</a> </span>
                </div>
            </div>
        </div>
    </footer>

{/* <footer>
<div className="container">
    <div className="row row-gap-5">
        <div className="col-md-12 p-5">
            <h2>Let's talk <a href="mailto:design@groyyo.com">design@groyyo.com</a></h2>
        </div>
        <div className="col-md-4 col-6">
            <div>For latest Blogs, events</div>
            <Link to="/blogs">Latest <i className="fa-solid fa-arrow-right"></i></Link>

            <p className="mt-3">Stay Connected</p>
            <ul className="footer-social-media">
                <li><a target="_blank" href="https://www.instagram.com/groyyostudio/"><i className="fa-brands fa-instagram"></i></a></li>
                <li><a target="_blank" href="https://www.linkedin.com/company/groyyo/?originalSubdomain=in"><i className="fa-brands fa-linkedin"></i></a></li>
                <li><a target="_blank" href="https://groyyo.com/"><i className="fa-solid fa-globe"></i></a></li>
            </ul>
        </div>
        <div className="col-md-4 col-12">
            <p><i className="fa-solid fa-globe me-3"></i><span>Select Language</span></p>
            <select className="form-select">
                <option value="">English</option>
                <option value="">Hindi</option>
                <option value="">Turkish</option>
                <option value="">Arabic</option>
            </select>

            <span>Any issues? <a href="#">Chat with us</a> </span>
        </div>
    </div>
</div>
</footer> */}
    </>
 
  );
};
