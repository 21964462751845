import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import { Form, Formik } from "formik";
import { changePassword } from "../../../../features/forgotPassword/action";
import { resetForgotPassword } from "../../../../features/forgotPassword/slice";
import * as Yup from "yup";
import { AccountLayout } from "../accountLayout";
import { notify } from "../../../../features/notification/notify";

export const ChangeUserPassword = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const changePasswordResponse = useSelector((state) => state.forgotPassword);
    const [user, setUser] = useState({
        currentPassword: "",
        password: "",
        passwordConfirmation: "",
        email: "",
        error: "",
    });

    let obj = localStorage.getItem('userData');
    let value = JSON.parse(obj);

    const handleResetPassword = (newData) => {

        if(newData.currentPassword.trim() === newData.passwordConfirmation.trim()){
            notify('Current password and new password should not be the same!','warning')
        }else{
        let data = {
            curentPassword: newData.currentPassword,
            newpassword: newData.passwordConfirmation,
            email: value.emailId,
        };
        dispatch(changePassword({ data }));
    }
    };

    const handleChange = (e) => {
        setUser({
            ...user,
            [e.target.name]: e.target.value,
        });
    };

    const UserSchema = Yup.object().shape({
        currentPassword: Yup.string().required("Required"),
        password: Yup.string().required("Required"),
        passwordConfirmation: Yup.string().oneOf(
            [Yup.ref("password"), null],
            "Passwords must match"
        ).required("Required"),
    });

    useEffect(() => {
        if (changePasswordResponse.success === true && changePasswordResponse.actionName === "changePassword" && changePasswordResponse.loading == false) {          
           if( changePasswordResponse.message == 'success'){
            notify("password changed successfully.", "success");
            setUser({
                currentPassword: "",
                password: "",
                passwordConfirmation: "",
                email: "",
                error: "",
            })
        }else{
            notify(changePasswordResponse.message, "warning");
        }
        } else if (changePasswordResponse.loading == false && changePasswordResponse.success == false && changePasswordResponse.actionName === "changePassword") {            
            notify("Wrong password .", "error");
        } 
    }, [changePasswordResponse]);
    return (
        <AccountLayout>
            <div className="right-column">
                <div className="edit-outer p-4">
                    <div className="edit-inner-left">
                        <Formik
                            initialValues={user}
                            validationSchema={UserSchema}
                            enableReinitialize={true}
                            onSubmit={(values) => {
                                handleResetPassword(values);
                            }}
                        >
                            {({ errors, touched, isValid, handleBlur }) => (
                                <>
                                    <Form>
                                        <h3>Change Password</h3>
                                        <div className="row">
                                            <div className="row row-gap-3">
                                                <div className="col-md-6 mb-3 position-relative">
                                                    <input
                                                        type="password"
                                                        value={user.currentPassword}
                                                        name="currentPassword"
                                                        className="form-control"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        placeholder="Current Password"
                                                    />
                                                    {errors.currentPassword && touched.currentPassword ? (
                                                        <div className="invalid-feedback d-block">
                                                            {errors.currentPassword}
                                                        </div>
                                                    ) : null}
                                                </div>
                                                <div className="col-md-6 mb-3 position-relative">
                                                    <input
                                                        type="password"
                                                        value={user.password}
                                                        name="password"
                                                        className="form-control"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        placeholder="New Password"
                                                    />
                                                    {errors.password && touched.password ? (
                                                        <div className="invalid-feedback d-block">
                                                            {errors.password}
                                                        </div>
                                                    ) : null}
                                                </div>
                                                <div className="col-md-6 mb-3 position-relative">
                                                    <input
                                                        type="password"
                                                        value={user.passwordConfirmation}
                                                        name="passwordConfirmation"
                                                        className="form-control"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        placeholder="Re-Enter New Password"
                                                    />
                                                    {errors.passwordConfirmation &&
                                                        touched.passwordConfirmation ? (
                                                        <div className="invalid-feedback d-block">
                                                            {errors.passwordConfirmation}
                                                        </div>
                                                    ) : null}
                                                </div>
                                                <div className="col-sm-4"></div>
                                                <div className="col-sm-4">
                                                <button disabled={!isValid} type="submit" className="btn orange-btn">
                                                    Reset
                                                </button>
                                                </div>
                                                <div className="col-sm-4"></div>
                                            </div>
                                        </div>
                                    </Form>
                                </>
                            )}
                        </Formik>

                    </div>
                </div>
            </div>

        </AccountLayout>
    );
};
