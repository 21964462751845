import { createSlice } from "@reduxjs/toolkit";
import { getCategories, getProducts, getAllCategories, createCartData, getCartBadge, getOrders, addProduct, getProductById, getAllProducts, updateCartQuantity, removeProductFromCart, saveForLater, changeProductStatus, processOrder, processPayment } from "./action";


export const product = createSlice({
  name: "product",
  initialState: {
    loading: false,
    error: null,
    success: false,
    actionName: null,
    products: null,
    categories: null,
    cart : null,
    badge : null,
    orders: null,
    message: null,
    product: null,
    allCategories: null,
    allProducts: null,
    orderNumber : null,
    payment : null
  },

  reducers: {
    updateBadge: (state, action) => {
      state.badge = action.payload
    },
    resetProduct: (state, action) => {
      state.success = false;
      state.actionName = "";
      state.message = null
    },
  },
  
  extraReducers: {
    [getProducts.pending]: (state) => {
      state.loading = true;
      state.success = false;
    },
    [getProducts.fulfilled]: (state, action) => {
      state.loading = false;
      state.actionName = "getProducts"
      state.products = action.payload.products
      state.success = true;
    },
    [getProducts.rejected]: (state, action) => {
      console.log("error", action)
      state.loading = false;
      state.error = "Internal server error";
    },
    [getAllProducts.pending]: (state) => {
      state.loading = true;
      state.success = false;
    },
    [getAllProducts.fulfilled]: (state, action) => {
      state.loading = false;
      state.actionName = "getAllProducts"
      state.allProducts = action.payload.products
      state.success = true;
    },
    [getAllProducts.rejected]: (state, action) => {
      console.log("error", action)
      state.loading = false;
      state.error = "Internal server error";
    },
    [getCartBadge.pending]: (state) => {
      state.loading = true;
      state.success = false;
    },
    [getCartBadge.fulfilled]: (state, action) => {
      state.loading = false;
      state.actionName = "getCartBadge"
      state.badge = action.payload.cart.badge
      state.cart = action.payload.cart
      state.success = true;
    },
    [getCartBadge.rejected]: (state, action) => {
      console.log("error", action)
      state.loading = false;
      state.error = "Internal server error";
    },
    [createCartData.pending]: (state) => {
      state.loading = true;
      state.success = false;
    },
    [createCartData.fulfilled]: (state, action) => {
      state.loading = false;
      state.actionName = "createCartData"
      state.success = true;
    },
    [createCartData.rejected]: (state, action) => {
      console.log("error", action)
      state.loading = false;
      state.error = "Internal server error";
    },
    [updateCartQuantity.pending]: (state) => {
      state.loading = true;
      state.success = false;
    },
    [updateCartQuantity.fulfilled]: (state, action) => {
      state.loading = false;
      state.actionName = "updateCartQuantity"
      state.success = true;
    },
    [updateCartQuantity.rejected]: (state, action) => {
      console.log("error", action)
      state.loading = false;
      state.error = "Internal server error";
    },
    [removeProductFromCart.pending]: (state) => {
      state.loading = true;
      state.success = false;
    },
    [removeProductFromCart.fulfilled]: (state, action) => {
      state.loading = false;
      state.actionName = "removeProductFromCart"
      state.success = true;
    },
    [removeProductFromCart.rejected]: (state, action) => {
      console.log("error", action)
      state.loading = false;
      state.error = "Internal server error";
    },
    [saveForLater.pending]: (state) => {
      state.loading = true;
      state.success = false;
    },
    [saveForLater.fulfilled]: (state, action) => {
      state.loading = false;
      state.actionName = "saveForLater"
      state.success = true;
    },
    [saveForLater.rejected]: (state, action) => {
      console.log("error", action)
      state.loading = false;
      state.error = "Internal server error";
    },
    [getCategories.pending]: (state) => {
      state.loading = true;
      state.success = false;
    },
    [getCategories.fulfilled]: (state, action) => {
      state.loading = false;
      state.actionName = "getCategories"
      state.categories = action.payload
      state.success = true;
    },
    [getCategories.rejected]: (state, action) => {
      console.log("error", action)
      state.loading = false;
      state.error = "Internal server error";
    },
    [getAllCategories.pending]: (state) => {
      state.loading = true;
      state.success = false;
    },
    [getAllCategories.fulfilled]: (state, action) => {
      state.loading = false;
      state.actionName = "getAllCategories"
      state.allCategories = action.payload.allcateg
      state.success = true;
    },
    [getAllCategories.rejected]: (state, action) => {
      console.log("error", action)
      state.loading = false;
      state.error = "Internal server error";
    },
    [getOrders.pending]: (state) => {
      state.loading = true;
      state.success = false;
    },
    [getOrders.fulfilled]: (state, action) => {
      state.loading = false;
      state.actionName = "getOrders"
      state.orders = action.payload
      state.success = true;
    },
    [getOrders.rejected]: (state, action) => {
      state.loading = false;
      state.error = "Internal server error";
    },
    [addProduct.pending]: (state) => {
      state.loading = true;
      state.success = false;
    },
    [addProduct.fulfilled]: (state, action) => {
      state.loading = false;
      state.actionName = "addProduct"
      state.message = action.payload.message
      state.success = true;
    },
    [addProduct.rejected]: (state, action) => {
      state.loading = false;
      state.error = "Internal server error";
    },
    [getProductById.pending]: (state) => {
      state.loading = true;
      state.success = false;
    },
    [getProductById.fulfilled]: (state, action) => {
      state.loading = false;
      state.actionName = "getProductById"
      state.product = action.payload
      state.success = true;
    },
    [getProductById.rejected]: (state, action) => {
      state.loading = false;
      state.error = "Internal server error";
    },
    
    [changeProductStatus.pending]: (state) => {
      state.loading = true;
      state.success = false;
    },
    [changeProductStatus.fulfilled]: (state, action) => {
      state.loading = false;
      state.actionName = "changeProductStatus"
      state.success = true;
    },
    [changeProductStatus.rejected]: (state, action) => {
      state.loading = false;
      state.error = "Internal server error";
    },
    [processOrder.pending]: (state) => {
      state.loading = true;
      state.success = false;
    },
    [processOrder.fulfilled]: (state, action) => {
      state.loading = false;
      state.actionName = "processOrder"
      state.orderNumber = action.payload.orderNumber
      state.success = true;
    },
    [processOrder.rejected]: (state, action) => {
      console.log("error", action)
      state.loading = false;
      state.error = "Internal server error";
      state.success = false
    },
    [processPayment.pending]: (state) => {
      state.loading = true;
      state.success = false;
    },
    [processPayment.fulfilled]: (state, action) => {
      state.loading = false;
      state.actionName = "processPayment"
      state.payment = action.payload.payment
      state.success = true;
    },
    [processPayment.rejected]: (state, action) => {
      console.log("error", action)
      state.loading = false;
      state.error = "Internal server error";
      state.success = false
    }
  },
});

export default product.reducer;
export const { updateBadge, resetProduct } = product.actions;



