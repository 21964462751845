import React, { useEffect,useRef, useState } from "react";
import { DefaultLayout } from "../../layouts/defaultLayout";
import { Blogslider } from "./blogslider";
import { getBlogs } from "../../../features/accountAdmin/blog/action";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { Link } from "react-router-dom";
import { CONSTANT } from "../../../utils/constant";
import ReactPaginate from "react-paginate";

export const Blogs = () => {
  const dispatch = useDispatch();
  const [blogList, setBlogList] = useState([]);

  const [pageCount, setPageCount] = useState(0);
  const [itemCount, setItemCount] = useState(CONSTANT.PAGE_LIMIT);
  const [currentPage, setcurrentPage] = useState(0);
  const isFirstRender = useRef(true);
  
  useEffect(() => {
    if (!isFirstRender.current) {
        callBlogsData();
    }
    isFirstRender.current = false;
  }, [currentPage, itemCount]);
  
  const callBlogsData = () => {
    dispatch(
       
      getBlogs({
        page: currentPage + 1,
        limit: itemCount,
      })
    );
  };
 

  const blogResponse = useSelector((state) => state.blogAdmin);
  useEffect(() => {
    blogResponse?.blogs?.rows &&
    setBlogList(blogResponse?.blogs?.rows);

    if (blogResponse?.blogs?.count != null) {
      setPageCount(Math.ceil(blogResponse?.blogs?.count / itemCount));
    }
  }, [blogResponse.blogs]);

  const handlePageChange = (selectedObject) => {
    setcurrentPage(selectedObject.selected);
  };
  const handleItemsChange = (blog) => {
    setItemCount(blog.target.value);
    setcurrentPage(0);
  };


  return (
    <>
      <DefaultLayout>
        <section className="section latest-section latest-banner">
          <div className="container">
            <div className="top-content">
              <h1>Latest Blogs</h1>
              {/* <p>
              Lorem, ipsum dolor sit amet consectetur adipisicing elit. Animi
              sapiente aperiam alias corporis necessitatibus et non consequatur.
              Quam dicta id facere quasi corporis, nostrum ducimus tenetur, illo
              hic in vero!
            </p> */}
            </div>
            <div className="tab-content" id="myTabContent">
            <div className="row">
                {blogList &&
                  blogList.map((blog, index) => (
                    <div className="col-md-3">
                      <Link to={"/blog/" + blog.id}>
                        <img
                          src={process.env.REACT_APP_API_URL + blog.imagePath}
                          alt="Event"
                          className="w-100"
                        />
                      </Link>
                      <div className="content">
                        <div className="date-author">
                          {moment(blog.createdDate).format("MMMM DD, YYYY")}
                        </div>
                        <h3>{blog.title}</h3>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
            <nav className="pagination-block">
                <p className="total">
                  {blogResponse?.blogs?.count} items total
                </p>

                <ReactPaginate
                  previousLabel={"previous"}
                  nextLabel={"next"}
                  breakLabel={"..."}
                  pageCount={pageCount}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={3}
                  forcePage={currentPage}
                  onPageChange={handlePageChange}
                  containerClassName={"pagination justify-content-center mt-4"}
                  pageClassName={"page-item"}
                  pageLinkClassName={"page-link"}
                  previousClassName={"page-item"}
                  previousLinkClassName={"page-link"}
                  nextClassName={"page-item"}
                  nextLinkClassName={"page-link"}
                  breakClassName={"page-item"}
                  breakLinkClassName={"page-link"}
                  activeClassName={"active"}
                />
                <div className="customSelect">
                  <label htmlFor="">Items per page :</label>
                  <div className="selectBox">
                    <select name="" id="" onChange={handleItemsChange}>
                      <option value={CONSTANT.PAGE_LIMIT}>
                        {CONSTANT.PAGE_LIMIT}
                      </option>
                      <option value="15">15</option>
                      <option value="20">20</option>
                    </select>
                  </div>
                </div>
              </nav>
          </div>
        </section>
      </DefaultLayout>
    </> 
  );
};
